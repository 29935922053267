import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PropertyConfig } from '../../../../shared/model/schema-config/property-config.model';
import { DialogConfigData } from './../../../../shared/model/dialog-config.model';
import { SchemaField, SchemaProperties, ValidationMessages } from './../../../../shared/model/schema-config/shema.model';
import { TemplateConfig } from './../../../../shared/model/schema-config/template-config.model';
import { SchemaFieldService } from './../../../../shared/services/schema-data/schema-field.service';

@Component({
  selector: 'modal-add-schema-field',
  templateUrl: './modal-add-schema-field.component.html',
  styleUrls: ['./modal-add-schema-field.component.scss'],
})
export class ModalAddSchemaFieldComponent implements OnInit, AfterViewChecked {
  // * Variables
  public editingField: SchemaField;
  public schemaFieldObj = SchemaProperties;

  // * Form Group
  public fgPropsOfSchemaField: FormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<ModalAddSchemaFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfigData,
    private _schemaFieldService: SchemaFieldService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // *--------------------------------------
    // * Variables initialization

    // * Configure: FIXED PROPERTIES
    SchemaProperties.FIXED_PROPERTIES.setContextList(SchemaProperties);

    // * Configure: DYNAMIC PROPERTIES
    SchemaProperties.DYNAMIC_PROPERTIES.setDropdownOptions(SchemaProperties);

    // * Configure: VALIDATION MESSAGES
    SchemaProperties.VALIDATION_MESSAGES.setDropdownOptions(new ValidationMessages());

    // * Configure: ADVANCED PROPERTIES
    SchemaProperties.ADVANCED_PROPERTIES.setValue(SchemaProperties);

    // *--------------------------------------

    // * Configure: Fixed FormControl

    this.appendFormControls(SchemaProperties.FIXED_PROPERTIES.context.list);

    // *--------------------------------------

    //#region  // * Edit mode
    this.editingField = this.data['editingField'];

    for (let propName in this.editingField) {
      //
      // * Data for editing FIXED_PROPERTIES
      let fixedField = SchemaProperties.FIXED_PROPERTIES.context.list.find((x) => x.name.includes(propName));
      if (fixedField) this.setValueFormControl(fixedField.name, this.editingField[propName]);

      // * Data for editing VALIDATION_MESSAGES
      if (propName.includes('validation') && this.editingField[propName]) {
        for (let validationPropName in this.editingField[propName]) {
          let validationField = SchemaProperties.VALIDATION_MESSAGES.getDropdownOptions.list.find((x) =>
            x.name.includes(validationPropName),
          );

          if (validationField) {
            validationField.disabled = 'inactive';
            validationField.buttonAction = 'remove';

            let data = SchemaProperties.VALIDATION_MESSAGES.context.data;
            data.dropdownValues.push(validationField);

            data.dropdownOptions.totalProperties = data.dropdownOptions.list.length;

            SchemaProperties.VALIDATION_MESSAGES.context.items.push(1);

            this.addFormControl(validationField);
            this.setValueFormControl(validationField.name, this.editingField[propName][validationPropName]);
          }
        }
      }

      // * Data for editing DYNAMIC_PROPERTIES
      let dynamicField = SchemaProperties.DYNAMIC_PROPERTIES.getDropdownOptions.list.find((x) => x.name.includes(propName));
      if (dynamicField && this.editingField[propName]) {
        dynamicField.disabled = 'inactive';
        dynamicField.buttonAction = 'remove';

        let data = SchemaProperties.DYNAMIC_PROPERTIES.context.data;
        data.dropdownValues.push(dynamicField);

        data.dropdownOptions.totalProperties = data.dropdownOptions.list.length;

        SchemaProperties.DYNAMIC_PROPERTIES.context.items.push(1);

        this.addFormControl(dynamicField);
        this.setValueFormControl(dynamicField.name, this.editingField[propName]);
      }

      // * Data for editing ARRAYS
      if (!propName.includes('triggers') && Array.isArray(this.editingField[propName]) && this.editingField[propName].length > 0) {
        let property = propName.replace(/(?=[A-Z])/g, '_').toUpperCase();

        SchemaProperties[property].context.items = this.editingField[propName];
        this.onChangeMinMax({ value: this.editingField[propName].length }, property);

        this.editingField[propName].forEach((item, index) => {
          this.setValueFormControl(`${property}-item${index}`, item);

          if (propName.includes('title')) {
            this.setValueFormControl(`${property}-name${index}`, item?.name);
            this.setValueFormControl(`${property}-value${index}`, item?.value);
          }
        });
      }

      if (propName.includes('triggers') && this.editingField[propName]) {
        this.editingField[propName].forEach((trigger, i) => {
          const triggersContext = SchemaProperties.TRIGGERS.context;
          let functionName = `TRIGGERS-function${i}`;
          let newItem = {
            function: functionName,
            parameters: `TRIGGERS-parameters${i}`,
            subItems: [],
          };
          triggersContext.items.push(newItem);
          this.addFormControl(newItem);
          this.setValueFormControl(functionName, this.editingField[propName][i].function);

          trigger.parameters.forEach((param, paramIndex) => {
            let subItemName = `TRIGGERS-parameters${i}-item${paramIndex}`;
            let newSubItem = { name: subItemName };
            triggersContext.items[i]?.subItems.push(newSubItem);
            this.addFormControl(newSubItem);
            this.setValueFormControl(subItemName, param);
          });
          this.appendFormControls(triggersContext.items);
        });
      }
    }

    //#endregion

    // *--------------------------------------
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  //#region // * Templates

  // ! FAZER UM SWITCH PARA CADA CASO DA CLASSE
  public setPlaceholder(item: PropertyConfig, controlName: string = '[sem nome]') {
    let placeholder = '';

    if (item?.label && item?.name?.includes('FIXED')) {
      placeholder = `${item?.label} do campo...`;
    }

    if (typeof this.getValueFormControl(item?.name) === 'boolean') {
      if (this.getValueFormControl(item?.name)) {
        placeholder = 'Campo obrigatório!';
      } else {
        placeholder = 'Campo opcional!';
      }
    }

    if (item?.name?.includes('item')) {
      placeholder = `Informe o valor do ${item?.name}`;
    }

    !placeholder ? (placeholder = `Informe o valor da propriedade.. ${this.getPropertyName(item?.name) ?? controlName}`) : '';
    return placeholder;
  }

  public showTemplate() {
    console.log('\n\nthis.fg.value :>> ', this.fgPropsOfSchemaField.value);
    console.log('\n\nObjConverter :>> ', this._schemaFieldService.objToSchemaField(this.fgPropsOfSchemaField.value));
    console.log('\n\nSchemaField :>> ', SchemaProperties.DYNAMIC_PROPERTIES.context);
  }

  public formGroupValue() {
    return JSON.stringify(this.fgPropsOfSchemaField.value, undefined, 2);
  }

  public getIcon(actionType: string) {
    if (actionType == 'remove') return `pi pi-times-circle`;
    return `pi pi-plus-circle`;
  }

  public getClass(actionType: string) {
    if (actionType == 'remove') return `p-button-danger`;
    return `p-button-success`;
  }

  public setAction(template: string, property: PropertyConfig) {
    const obj = <TemplateConfig>SchemaProperties[template].context;
    let lastItem = obj.items.length;
    let totalProperties = obj.data.dropdownOptions.totalProperties;

    if (property?.buttonAction == 'add' && lastItem < totalProperties) {
      obj.items.push(1);
      obj.data.dropdownValues.find((x) => x.name == property?.name).buttonAction = 'remove';
    } else if (property?.buttonAction == 'remove') {
      let listFiltered = obj.data.dropdownValues.filter((x) => x.name !== property?.name);
      obj.data.dropdownValues = listFiltered;

      obj.data.dropdownOptions.list.find((x) => x.name == property?.name).disabled = null;

      obj.items.pop();
      this.fgPropsOfSchemaField.removeControl(property?.name);
    }

    if (lastItem == totalProperties) {
      let lastValue = obj.data.dropdownValues[lastItem - 2];
      if (lastValue) lastValue.buttonAction = 'add';
    }
  }

  //#endregion

  public getPropertyName(name: string) {
    let _name = name?.replace(/(\w+_\w+-|[a-zA-Z]+-|-.*)/gm, '');
    return _name;
  }

  public onChangeMinMax(event: any, template: string) {
    let name = [];
    let index = 0;
    let controls;
    const value = event?.value;

    if (template.includes('-')) {
      name = template.split('-');
      index = name[1].replace(/\D+/g, '');
    }

    const obj = SchemaProperties[name[0] ?? template].context;

    // * List reset
    if (!template.includes('-')) obj.items = new Array();
    else {
      obj.items[index].subItems = new Array();
    }

    switch (template) {
      case SchemaProperties.ENUM.name:
      case SchemaProperties.LOAD_FIELDS.name:
      case SchemaProperties.VALUE_KEY_NAME.name:
        for (let i = 0; i < value; i++) {
          obj.items.push({ name: `${template}-item${i}` });
        }
        break;
      case SchemaProperties.TITLE_MAP.name:
        for (let i = 0; i < value; i++) {
          obj.items.push({ value: `${template}-value${i}`, name: `${template}-name${i}` });
        }
        break;
      case SchemaProperties.TRIGGERS.name:
        for (let i = 0; i < value; i++) {
          controls = Object.keys(this.fgPropsOfSchemaField.controls).filter((x) => x.includes('parameters' + i));

          obj.items.push({
            function: `${template}-function${i}`,
            parameters: `${template}-parameters${i}`,
            subItems: controls.map((x) => ({ name: x })) ?? [],
          });
        }
        break;
      default:
        // ! COMEÇAR ADIÇÃO A PARTIR DO ÚLTIMO ITEM: MELHORIA
        for (let i = 0; i < value; i++) {
          obj.items[index]?.subItems.push({ name: `${template}-item${i}` });
        }
        break;
    }

    // * Remove FormControl for deleted items
    for (let j = obj.items.length + 1; j >= value; j--) {
      //
      // * Delete FormControl for sub-items before deleting main FormControl
      if (this.fgPropsOfSchemaField.contains(`${template}-function${j}`)) {
        let controls = Object.keys(this.fgPropsOfSchemaField.controls).filter((x) => x.includes('parameters' + j));
        controls.forEach((name) => this.fgPropsOfSchemaField.removeControl(name));
      }

      this.fgPropsOfSchemaField.removeControl(`${template}-name${j}`);
      this.fgPropsOfSchemaField.removeControl(`${template}-item${j}`);
      this.fgPropsOfSchemaField.removeControl(`${template}-value${j}`);
      this.fgPropsOfSchemaField.removeControl(`${template}-function${j}`);
    }

    for (let j = obj.items[index]?.subItems?.length + 1; j >= value; j--) {
      this.fgPropsOfSchemaField.removeControl(`${template}-item${j}`);
    }

    // * Add FormControl for new items
    if (obj.items.length) this.appendFormControls(obj.items);
  }

  public onChangeDropdown(event: any, template: string) {
    const obj = <TemplateConfig>SchemaProperties[template].context;
    const value = event?.value;

    const indexOf = obj.data.dropdownOptions.list.indexOf(value);
    const lastIndex = obj.data.dropdownOptions.list.length - 1;

    if (indexOf === lastIndex) {
      obj.data.dropdownValues.find((x) => x.name == value?.name).buttonAction = 'remove';
    } else {
      obj.data.dropdownValues.find((x) => x.name == value?.name).buttonAction = 'add';
    }

    obj.data.dropdownOptions.list.find((x) => x.name == value?.name).disabled = 'inactive';

    this.addFormControl(value);
  }

  //#region // * Fields actions

  public disableOptions(property: PropertyConfig) {
    return this.fgPropsOfSchemaField.contains(property?.name);
  }

  //#endregion

  //#region // * Form Group

  public getFormControl(controlName: string) {
    return this.fgPropsOfSchemaField.get(controlName);
  }

  private addFormControl(controlName: any) {
    this.fgPropsOfSchemaField.addControl(
      controlName?.name ?? controlName.function,
      new FormControl({ value: '', disabled: false }, [Validators.required]),
    );

    if (controlName?.value) {
      this.fgPropsOfSchemaField.addControl(controlName?.value, new FormControl({ value: '', disabled: false }, [Validators.required]));
    }
  }

  private appendFormControls(controlNames: Array<any>) {
    controlNames.forEach((controlName) => {
      if (controlName?.subItems?.length) {
        controlName.subItems.forEach((subControl) => {
          this.addFormControl(subControl);
        });
      }
      this.addFormControl(controlName);
    });
  }

  public setValueFormControl(controlName: string, controlValue: any) {
    let control = this.fgPropsOfSchemaField.controls[controlName];
    if (control) control.setValue(controlValue);
  }

  public getValueFormControl(controlName: string) {
    return this.fgPropsOfSchemaField.controls[controlName]?.value;
  }

  public onClose() {
    this.dialogRef.close();
    this._schemaFieldService.resetConfigurations(SchemaProperties);
  }

  public onSubmit() {
    let newSchemaField: SchemaField = new SchemaField();
    newSchemaField = this._schemaFieldService.objToSchemaField(this.fgPropsOfSchemaField.value);
    let fieldIndex = this.data['fieldIndex'];
    if (this.editingField && fieldIndex >= 0) {
      this._schemaFieldService.updateSchemaField(fieldIndex, newSchemaField);
    } else {
      this._schemaFieldService.addSchemaField(newSchemaField);
    }

    this._schemaFieldService.resetConfigurations(SchemaProperties);
    console.log('this._schemaFieldService.getSchemaFields(); :>> ', this._schemaFieldService.getSchemaFields());
  }
  //#endregion
}
