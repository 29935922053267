<section fxLayout="column">

  <div class="container"  fxLayoutAlign="center none">
    <div class="enterprise__commands">
      <a mat-button [routerLink]="['','empresas','cadastrar']">
        <span class="material-icons">
          add_business
        </span>
        Nova empresa
      </a>
    </div>
  </div>

  <div class="container"  fxLayoutAlign="center none">
      <div cdkDropList class="enterprise__list">
        <div fxLayout="row wrap" class="enterprise__box">
            <h6 fxFlex.lg="20"> ID  </h6> 
            <h6 fxFlex.lg="15"> CNPJ  </h6>
            <h6 fxFlex.lg="20"> Razão Social  </h6>
            <h6 fxFlex.lg="10"> Data de Cadastro  </h6>
            <h6 fxFlex.lg="10"> Ativo/Inativo  </h6>
        </div>
        <div class="box__list">
          <div  fxLayout="row wrap" (click)="CompanyClick(company.id);" class="enterprise__box" *ngFor="let company of listSearchService.companies" cdkDrag>
            <h6 fxFlex.lg="20"> {{company.id}}  </h6>
            <h6 fxFlex.lg="15"> {{company.cnpj}}  </h6>
            <h6 fxFlex.lg="22"> {{company.name}}  </h6>
            <h6 fxFlex.lg="12"> {{company.createdOn | date:'dd/MM/yyyy - HH:mm'}}  </h6>
            <h6 fxFlex.lg="6"> <span [ngClass]="company.blocked?'red':'green'" class="material-icons">{{company.blocked?"lock":"lock_open"}}</span> </h6>
            
          </div>
        </div>
      </div>
  </div>
  <mat-paginator [length]="listSearchService.total"  [pageSize]="listSearchService.pageSize" [pageIndex]="listSearchService.page"  [pageSizeOptions]="[10, 20, 30, 50]" (page)="onPageChange($event)"></mat-paginator>
</section>
