import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { EImportOptions } from '../enums/e-import-option.enum';
import { AutocompleteItemV2 } from '../model/autocomplete-item-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SchemaService {
  constructor(private httpClient: HttpClient) {}

  public getTotalDocsAutocompleteItemV2() {
    const url = `${environment.apiEndpoint}/schema/autocomplete-item-V2/CountDocs`;
    return this.httpClient.get(url);
  }

  public getDocsByPageAutocompleteItemV2(pageNumber: number) {
    const url = `${environment.apiEndpoint}/schema/autocomplete-item-v2/${pageNumber}`;
    return this.httpClient.get<Array<AutocompleteItemV2>>(url);
  }

  public getDocByIdAutocompleteItemV2(id: string): Observable<any> {
    const url = `${environment.apiEndpoint}/schema/autocomplete-item-V2/SearchItem/${id}`;
    return this.httpClient.get(url);
  }

  public importCsvAutocompleteItemV2(file: File, inportOpt: EImportOptions) {
    let url = `${environment.apiEndpoint}/schema/autocomplete-item-V2/import`;
    let formData = new FormData();
    formData.append('formFiles', file);
    formData.append('importOptions', inportOpt.toString());
    return this.httpClient.post<Array<AutocompleteItemV2>>(url, formData);
  }

  public deleteAutocompleteItemV2(itemId: string) {
    let url = `${environment.apiEndpoint}/schema/autocomplete-item-V2/delete/${itemId}`;
    return this.httpClient.delete<boolean>(url);
  }
}
