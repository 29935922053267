export class Form {
  public constructor(
    id: string,
    acronym: string,
    checked: boolean,
    description: string,
    requiredType: FormRequiredType
  ) {
    this.id = id;
    this.acronym = acronym;
    this.checked = checked;
    this.description = description;
    this.requiredType = requiredType;
  }

  public id: string;
  public acronym: string;
  public checked: boolean;
  public requiredType: FormRequiredType;
  public description: string = "";
  public blocked: boolean;
}

export class UiForm {
  public constructor(
    id: string,
    acronym: string,
    description: string,
    checked: boolean,
    requiredType: FormRequiredType,
    isEsocial: boolean,
    blocked: boolean
  ) {
    this._id = id;
    this._acronym = acronym;
    this.description = description;
    this._checked = checked;
    this._requiredType = requiredType || FormRequiredType.Required;
    this._isEsocial = isEsocial;
    this._blocked = blocked;

    this._required =
      this._requiredType === FormRequiredType.RequiredAllowByPass ||
      this._requiredType === FormRequiredType.Required;
    this._send_later =
      this._requiredType === FormRequiredType.RequiredAllowByPass;
  }

  private _id: string;
  private _acronym: string;
  private _checked: boolean = false;
  private _requiredType: FormRequiredType = FormRequiredType.Required;
  private _required: boolean = true;
  private _send_later: boolean = false;
  private _isEsocial: boolean;
  private _blocked: boolean;

  public description: string = "";

  public get id(): string {
    return this._id;
  }

  public get acronym(): string {
    return this._acronym;
  }

  public get checked(): boolean {
    return this._checked;
  }

  public set checked(value: boolean) {
    this._checked = value;
  }

  public set required(value: boolean) {
    this._required = value;
    if (this._send_later && !value) this._send_later = false;
    this._updateRequireType();
  }

  public get required(): boolean {
    return this._required;
  }

  public set sendLater(value: boolean) {
    this._send_later = value;
    this._updateRequireType();
  }

  public get sendLater(): boolean {
    return this._send_later;
  }

  private _updateRequireType(): void {
    if (this._required && this._send_later) {
      this._requiredType = FormRequiredType.RequiredAllowByPass;
    } else if (this._required && !this._send_later) {
      this._requiredType = FormRequiredType.Required;
    } else {
      this._requiredType = FormRequiredType.Optional;
    }
  }

  public getRequiredType(): string {
    let type = "";
    switch (this._requiredType) {
      case FormRequiredType.Required:
        type = "Obrigatório.";
        break;
      case FormRequiredType.Optional:
        type = "Não obrigatório.";
        break;
      case FormRequiredType.RequiredAllowByPass:
        type = "Obrigatório, permitir enviar depois.";
        break;
      default:
        break;
    }
    return type;
  }

  public get requiredType(): FormRequiredType {
    return this._requiredType;
  }

  public get isEsocial(): boolean {
    return this._isEsocial;
  }

  public get blocked(): boolean {
    return this._blocked;
  }

  private _FormRequiredType: string[] = [
    "Required",
    "Optional",
    "RequiredAllowByPass"
  ];
  public GetRequiredType(): string {
    return this._FormRequiredType[this._requiredType] || "";
  }
}

enum FormRequiredType {
  Required = 0,
  Optional = 1,
  RequiredAllowByPass = 2
}
