import { AbstractControl } from '@angular/forms';


export function ValidateFullname(control: AbstractControl): any
{
    if(control.value)
    {
        const total_words = control.value.trim().split(/\s+/g).length;
        if (total_words < 2) 
            return { fullname: true };
    }
    return null;
}