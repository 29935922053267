import { Component, OnInit } from '@angular/core';
import { ListSearchService } from '../services/list-search.service';
import { MenuService } from '../services/menu.service';
import { SidenavService } from '../services/sidenav.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent{

  public showUserMenu: boolean = false;
  public isMobileSearchActive: boolean = false;
  profileImage: string = './assets/default-profile.jpg';

  constructor(
    public listSearchService: ListSearchService, 
    public userService: UserService,
    public menuService: MenuService, 
    public sidenavService: SidenavService,) {
    }

  ngOnInit() {
  }

  public get showSearch(): boolean{
    return window.location.href.indexOf("empresas") > -1
  }

  public onSideNavClick(): void{
    this.sidenavService.changeIsMenuOpened('left');
  }

  public onBtnSearchClick(): void{
    this.listSearchService.isMobileSearchActive = true;
  }
  
}
