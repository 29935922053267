<section *ngIf="quota == undefined || isLoading" class="content__holder">
  <div class="card form__content" fxLayout="row wrap" fxLayoutAlign="center center">
    <app-loading></app-loading>
  </div>
</section>

<section *ngIf="quota != undefined && !isLoading" id="quota" class="content__holder">
  <div class="card form__content">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="50" fxLayout="column">
        <!--Title Content-->
        <div  class="is-relative title__content">
          <div class="card__title">
            <span>Quantidade de contratações</span>
            <a (click)="ShareQuotaUpdating();" class="icon__right">
                <i class="material-icons">edit</i>
            </a>
          </div>
        </div>
        <!--Card Content-->
        <form class="card__content">
          <h6><strong>Total Plano: </strong>  
            <span *ngIf="!editShareQuota">{{quota.ShareQuota.avaiable}}</span>
            <mat-form-field *ngIf="editShareQuota" [floatLabel]="false">
                <input type="text" matInput type="number" value="{{quota.ShareQuota.avaiable}}" (keyup)="onUpdateShareQuotaKeyDown($event);"/>
            </mat-form-field>
          </h6>
          <h6><strong>Usadas: </strong>  {{quota.ShareQuota.used}}</h6>
          <h6><strong>Disponível: </strong>  {{quota.ShareQuota.avaiable - quota.ShareQuota.used}}</h6>
          <div *ngIf="editShareQuota" fxLayoutAlign="end">
              <button mat-raised-button class="is-button__outline" color="accent" (click)="SaveShareQuota();" >
                <span>Salvar</span>
              </button>
          </div>
        </form>
      </div>
      <div fxFlex="50" fxLayout="column">
        <!--Title Content-->
        <div  class="is-relative title__content">
          <div class="card__title">
            <span>Quantidade de anexos</span>
            <a (click)="AttachmentQuotaUpdating();" class="icon__right">
                <i class="material-icons">edit</i>
            </a>
          </div>
        </div>
        <!--Card Content-->
        <form class="card__content">
          <h6><strong>Total Plano: </strong>  
            <span *ngIf="!editAttachmentQuota">{{quota.AttachmentQuota.avaiable}}</span>
            <mat-form-field *ngIf="editAttachmentQuota" [floatLabel]="false">
                <input type="text" matInput type="number" value="{{quota.AttachmentQuota.avaiable}}" (keyup)="onUpdateAttachmentQuotaKeyDown($event);"/>
            </mat-form-field>
          </h6>
          <h6><strong>Usados: </strong>  {{quota.AttachmentQuota.used}}</h6>
          <h6><strong>Disponível: </strong>  {{quota.AttachmentQuota.avaiable - quota.AttachmentQuota.used}}</h6>
          <div *ngIf="editAttachmentQuota" fxLayoutAlign="end">
            <button mat-raised-button class="is-button__outline" color="accent" (click)="SaveAttachmentQuota();" >
              <span>Salvar</span>
            </button>
        </div>
        </form>
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <h6><strong>Plano: </strong>  {{PlanName}}</h6>
    </div>
  </div>
</section> 
