import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfigData } from 'src/app/shared/model/dialog-config.model';
import { JsonSchemaFormComponent } from 'lugarh-json-schema-form/lib/json-schema-builder/json-schema-form.component';


@Component({
  selector: 'app-view-form',
  templateUrl: './modal-view-form.component.html',
  styleUrls: ['./modal-view-form.component.scss'],
})
export class ModalViewFormComponent implements OnInit {
  public fullSchema: any;
  public companyId: any;
  public workspaceId: any;
  @ViewChild("form", {static: true}) form: JsonSchemaFormComponent;
  
  constructor(
    public dialogRef: MatDialogRef<ModalViewFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfigData,
  ) {}

  ngOnInit(): void {
    this.fullSchema = this.data.fullSchema;
    this.companyId = this.data.companyId;
    this.workspaceId = this.data.workspaceId;
  }

  public onSubmit(data: any) 
  {
  }

  public onValidFormChange(valid: boolean): void {

  }
}
