import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { Profile } from "../model/authorization/profile.model";
import { LoaderService } from "../security/loader.service";
import { ToastService } from '../services/toast.service';

@Injectable()
export class AuthorizationService {

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private toastService: ToastService
  ) { }

  /**
 * GET: get all profiles from company
 */
  public GetAllProfiles(companyId: string): Observable<Profile[]> {

    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return this.http.get(environment.apiEndpoint + "/Authorization/GetAllProfilesForCompany/" + companyId, { headers: heads })
      .pipe(map((resp: Profile[]) => {
        this.loader.hide();
        return resp;
      }),
        catchError((err: any) => {
          this.loader.hide();
          console.error("Something get wrong when GetAllProfilesForCompany on AuthorizationService =>", err);
          return throwError(err);
        })
      );
  }

  public EnableNewAuthorizationMethod(companyId: string): Promise<boolean> {
    this.loader.show();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(environment.apiEndpoint + '/Authorization/EnableNewAuthorizationMethod/' + companyId, null)
        .subscribe((resp: any) => {
          this.loader.hide();
          this.toastService.success("Novo permissionamento habilitado com sucesso.");
          resolve(true);
        },
          err => {

            this.toastService.error("Não foi possível habilitar a funcionalidade");

            console.error("Something get wrong when EnableNewAuthorizationMethod on AuthorizationService =>", err);
            this.loader.hide();
            resolve(false)
          });
    });
  }

  public DisableNewAuthorizationMethod(companyId: string): Promise<boolean> {
    this.loader.show();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(environment.apiEndpoint + '/Authorization/DisableNewAuthorizationMethod/' + companyId, null)
        .subscribe((resp: any) => {
          this.loader.hide();
          this.toastService.success("Novo permissionamento desabilitado com sucesso.");
          resolve(true);
        },
          err => {

            this.toastService.error("Não foi possível desabilitar a funcionaliade.");

            console.error("Something get wrong when DisableNewAuthorizationMethod on AuthorizationService =>", err);
            this.loader.hide();
            resolve(false)
          });
    });
  }

  public async AddUserInProfile(userId: string, profileId: string, companyId: string): Promise<boolean> {
    this.loader.show();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(environment.apiEndpoint + '/Authorization/AddUserInProfile', { "userId": userId, "profileId": profileId, "companyId": companyId })
        .subscribe((resp: any) => {
          this.loader.hide();
          resolve(true);
        },
          err => {
            console.error("Something get wrong when EnableNewAuthorizationMethod on AuthorizationService =>", err);
            this.loader.hide();
            resolve(false)
          });
    });
  }

}