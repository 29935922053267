<!-- page__header -->
<section class="page__header is-primary">
  <!-- page__heading -->
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="page__heading">
    <h3 fxFlex="100" class="page__title">Usuários com acesso na empresa</h3>
    <h6 fxFlex="100" class="page__subtitle">Adicione os usuários na empresa ou edite </h6>
  </div>
</section>

<!-- page__content -->
<section class="page__content tableSection">
  <div class="card">
    <div fxlayout="row" fxLayoutAlign="none center" class="users__list">
      <p class="list__title"><strong>Usuários com acesso </strong></p>
      <button mat-raised-button color="primary" (click)="onAddNewUserClick();">Adicionar Usuários</button>
    </div>
    <mat-card>
      <table matSort class="users__table">
        <tr class="users__table__header">
          <td>
            <p>Id</p>
          </td>
          <td>
            <p>Nome</p>
          </td>
          <td>
            <p>Email</p>
          </td>
          <td>
            <p>Celular</p>
          </td>
        </tr>
        <tr *ngFor="let user of users" class="users">
          <td>
            <p>{{user.id}}</p>
          </td>
          <td>
            <p>{{user.name}}</p>
          </td>
          <td>
            <p>{{user.email}}</p>
          </td>
          <td>
            <p>{{user.cellphone}}</p>
          </td>
          <td>
            <mat-chip-list aria-label="Fish selection">
              <mat-chip selected *ngIf="user?.isMaster">
                <p class="chip__text">MASTER</p>
              </mat-chip>
              <mat-chip selected *ngIf="user?.isAdmin">
                <p class="chip__text">ADMINISTRADOR</p>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td>
            <p _ngcontent-ofy-c18="" class="users__td _icons">
              <i class="material-icons" title="Editar Usuários" (click)='onEditUserClick(user);'>create</i>
              <i class="material-icons" title="excluir um Usuário." (click)="onDeleteUsersClick(user);">delete</i>
            </p>
          </td>
        </tr>
      </table>
    </mat-card>
  </div>
</section>