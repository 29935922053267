import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    public showMenu: boolean = false;
    public currentActiveMenu: string = 'companies';

    constructor() { }

    public openMenu(): void{
        this.showMenu = true;
    }

    public closeMenu(): void{
        this.showMenu = false;
    }


}
