import { throwError } from 'rxjs';
import { Workspace } from 'src/app/shared/model/workspace/workspace.model';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { CompanyQuery } from 'src/app/shared/model/company-query.model';
import { environment as envProd } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { UiForm } from './model/form.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormsManagementService {


  token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZCN0FDQzUyMDMwNUJGREI0RjcyNTJEQUVCMjE3N0NDMDkxRkFBRTEiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJhM3JNVWdNRnY5dFBjbExhNnlGM3pBa2ZxdUUifQ.eyJuYmYiOjE2Nzk5NDYxMTcsImV4cCI6MTY4MjUzODExNywiaXNzIjoiaHR0cHM6Ly9pZC5ibmUuY29tLmJyIiwiYXVkIjoiaHR0cHM6Ly9pZC5ibmUuY29tLmJyL3Jlc291cmNlcyIsImNsaWVudF9pZCI6Ikx1Z2FyaEFkbSIsImNsaWVudF91c2VyX2FkbWluIjoidXNlcl9hZG1pbiIsInN1YiI6IjY1MTAyOWU5LTMzZjAtNGUwNi04YmZhLTFhZGUyY2Q2Mjk2ZSIsImF1dGhfdGltZSI6MTY3OTk0NjExNywiaWRwIjoibG9jYWwiLCJBc3BOZXQuSWRlbnRpdHkuU2VjdXJpdHlTdGFtcCI6Ik82VDU3WVNLVldST1M1RzdVNTNPNVA0SU81Nlo0UjcyIiwibmFtZSI6WyJCcnVubyBTdGl2YWwiLCJCcnVubyBTdGl2YWwiXSwicHJlZmVycmVkX3VzZXJuYW1lIjoic3RpdmFsQGJuZS1lbXByZWdvcy5jb20uYnIiLCJlbWFpbCI6WyJzdGl2YWxAYm5lLWVtcHJlZ29zLmNvbS5iciIsInN0aXZhbEBibmUtZW1wcmVnb3MuY29tLmJyIl0sImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaG9uZV9udW1iZXIiOiIrNTUgKDQxKSA5OTU5NTgxODkiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOmZhbHNlLCJ0ZW5hbnQiOiJsdWdhcmgtYWRtIiwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSJdLCJhbXIiOlsicHdkIl19.RN9NqLCq0mQaIrGRvq9ck2Z9y8OSxT8zzymnmDmwc-Z3D4aL32oq7vact-uLZXByE0AjFqYVqFsmejn4ErqlizK3o8KAdKGc5VBUK_svQRQ7AIJj_zPckT3P2IdMrpwTrBGxe80xYZBBf-2MJzsCtX_9vLExRmprC6DLCWiIcsFTF7U6dOdB3SNSq4mu7wG1X_iVvh-Itj3GqKMYFVgFWfHD0H7MG0e8U1JryOoS4yjD2gGLXBWv9VN1fQuKA9JFU10QVABl4Nb491uRvzsU4clIkbOliUXDdXQZlhlArXdWVzS5gzkkBDTqViBIop2XL-7KNAvKTHgBF5lWtrIuOQ'
  tokenHom: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZCN0FDQzUyMDMwNUJGREI0RjcyNTJEQUVCMjE3N0NDMDkxRkFBRTEiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJhM3JNVWdNRnY5dFBjbExhNnlGM3pBa2ZxdUUifQ.eyJuYmYiOjE2Nzk1MDk1NzksImV4cCI6MTY4MjEwMTU3OSwiaXNzIjoiaHR0cHM6Ly90ZXN0aW5nLWlkLmJuZS5jb20uYnIiLCJhdWQiOiJodHRwczovL3Rlc3RpbmctaWQuYm5lLmNvbS5ici9yZXNvdXJjZXMiLCJjbGllbnRfaWQiOiJMdWdhcmhBZG0iLCJjbGllbnRfdXNlcl9hZG1pbiI6InVzZXJfYWRtaW4iLCJzdWIiOiI0YjE0M2Y5OC0wZjVjLTRjNWItYTJjMS01NTczYWNhMjJiMjAiLCJhdXRoX3RpbWUiOjE2Nzk1MDk1NzYsImlkcCI6ImxvY2FsIiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI0VlVIWFI3NVFDVzIyT0w1MklHUTNQM0ZKQlBMM1hXRCIsIm5hbWUiOlsiQnJ1bm8gU3RpdmFsIiwiQnJ1bm8gU3RpdmFsIl0sInByZWZlcnJlZF91c2VybmFtZSI6InN0aXZhbEBibmUtZW1wcmVnb3MuY29tLmJyIiwiZW1haWwiOlsic3RpdmFsQGJuZS1lbXByZWdvcy5jb20uYnIiLCJzdGl2YWxAYm5lLWVtcHJlZ29zLmNvbS5iciJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGhvbmVfbnVtYmVyIjoiKzU1ICg0MSkgOTk1OTU4MTg5IiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwidGVuYW50IjoibHVnYXJoLWFkbSIsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiXSwiYW1yIjpbInB3ZCJdfQ.W9E9mAjiLvoqyvtACF3rVnOkq0brniPwoxljkX-Gj8FPlvj6wUsbgNKv6qkUGNLl6oPAh8ow62WjCRymy2TII537EfARZ1oG-8Iz9LHzJ4ryeBmOKnuF4JpB-iKLe-ROpWX9fWFrhTAR4yfk9w47xl-1iZPqaJFSCgjaLBYPAsQgj6PsNzvqe4r0laHy_YxYQwUsJBp9EebVc5g5c33S9-Wz7cE2taYc7eU0xpric8OAhhBLVO7OGZtUV2a1gQQTFGkCwV_TMX6bTuAe6CIk1Fg3vzPBFjilmazl5_ejQXtKXsFjocjVFJ4uS0x0mxNZgZS1kwqWzdAiI4da_FFVtQ'


  constructor(private _http: HttpClient) { }

  @Output() onChangeAmbient: EventEmitter<any> = new EventEmitter();

  private _originalpersonForms: Array<UiForm> = new Array<UiForm>();

  public async getForms(companyId: string, workspaceId: string): Promise<Array<any>> {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    const promise = new Promise<any[]>((resolve, reject) => {
      this._http
        .get(environment.apiEndpoint + `/BenefitsForm/GetFormsByWorkspace/${workspaceId}`, { headers: heads })
        .toPromise()
        .then((resp: any[]) => {
          if (resp) {
            this._originalpersonForms = [];
            resp.forEach(dc => {
              this._originalpersonForms.push(
                new UiForm(
                  dc.id,
                  dc.acronym,
                  dc.description,
                  dc.isInProfile,
                  dc.required,
                  dc.isEsocial,
                  dc.blocked
                )
              );
            });
          }

          this._originalpersonForms.sort(this.alphabeticalOrder);
          resolve(resp);
        })
        .catch((resp: any) => {

          reject(resp);
        });
    });
    return promise;
  }

  //Private Methods
  private alphabeticalOrder(a, b): number {
    if (a.acronym.toLowerCase() < b.acronym.toLowerCase()) return -1;
    else if (a.acronym.toLowerCase() > b.acronym.toLowerCase()) return 1;
    return 0;
  }



  public async getCompanysProduction(pageSize: number, page: number, q: string): Promise<CompanyQuery> {
    return new Promise<CompanyQuery>((resolve, reject) => {
      this._http.get(envProd.apiEndpoint + '/Company/GetAll?pageNumber=' + page + '&pageSize=' + pageSize + '&q=' + q)
        .subscribe((resp: CompanyQuery) => {
          resolve(resp);
        },
          err => {
            console.log('Something went wrong when getting list of companies!', err);
            reject(err);
          });
    });
  }

  public getAllCompanyPrd() {
    return this._http.get<Array<any>>(environment.apiEndpoint + "/Company/GetAllPrd").pipe(catchError((err: any) => {
      console.log(err);
      return throwError(err);
    }))
  }

  public getWorkspacesProduction(companyId: string) {
    return this._http.get<Workspace[]>(environment.apiEndpoint + "/Workspace/GetAllByCompanyPrd/" + companyId).pipe(catchError((err: any) => {
      console.log(err)
      return throwError(err);
    }));
  }

  public postFormInPrd(cmd: any): Observable<any> {
    return this._http.post<any>(environment.apiEndpoint + "/BenefitsForm/implant-form", cmd).pipe(catchError((err: any) => {
      console.log(err)
      return throwError(err);
    }));
  }

}
