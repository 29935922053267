import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { SchemaFullConfig } from '../../model/schema-config/schema-full-config.model';

@Injectable({
  providedIn: 'root',
})
export class SchemaDataService {
  constructor(private _http: HttpClient) {}

  public getSchemaProperties(companyId: string, schemaId: string) {
    let url = `${environment.apiEndpoint}/Schema/${companyId}/${schemaId}`;
    return this._http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log('res :>> ', res);
        return Object.entries(res.schema.properties).map((key: any) => {
          return Object.assign(key[1], { name: key[0] });
        });

        return <Array<any>>Object.values(res.schema.properties);
      })
      .then((data) => {
        return data;
      });
  }

  public getFullSchema(companyId: string, schemaId: string) {
    let url = `${environment.apiEndpoint}/Schema/${companyId}/${schemaId}`;
    return this._http.get<SchemaFullConfig>(url).toPromise<SchemaFullConfig>();
  }

  public saveSchemaConfig(companyId: string, schemaId: string, schema: SchemaFullConfig) {
    let url = `${environment.apiEndpoint}/Schema`;
    return this._http.put(url, { schemaId: schemaId, companyId: companyId, schema: schema }).toPromise();
  }

  public createDefaultSchema(companyId: string) {
    let url = `${environment.apiEndpoint}/Schema/createDefault/${companyId}`;
    return this._http.post(url, {}).toPromise();
  }
}
