import { Component, Inject, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})

export class AppToastComponent {

  public data: ToastMessage;

  private toastDuration: number = 5000;

  private _defaultToast: MatSnackBarConfig = {
    data : null, //{ msg : message, action: "OK", success:true},
    duration : this.toastDuration,
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: null
  };


  constructor(
    public snackBar: MatSnackBar,
    @Optional() @Inject(MAT_SNACK_BAR_DATA) data: any)
  {
    this.data = data;
  }

  ngOnInit() { }

  public showSuccess(message: string): void {
    this._defaultToast.panelClass = ['success-class'];
    this._defaultToast.data = new ToastMessage(message, 'Ok', ToastMessageType.SUCCESS);
    this.snackBar.openFromComponent(AppToastComponent, this._defaultToast);
  }

  public showWarning(message: string): void {
    this._defaultToast.panelClass = ['warning-class'];
    this._defaultToast.data = new ToastMessage(message, 'Dispensar', ToastMessageType.WARNING);
    this.snackBar.openFromComponent(AppToastComponent, this._defaultToast);
  }

  public showError(message: string): void {
    this._defaultToast.panelClass = ['error-class'];
    this._defaultToast.data = new ToastMessage(message, 'Fechar', ToastMessageType.ERROR);
    this.snackBar.openFromComponent(AppToastComponent, this._defaultToast);
  }

  action(): void {
    this.snackBar.dismiss()
  }
}


export enum ToastMessageType
{
  SUCCESS = 0,
  WARNING = 1,
  ERROR = 2,
  INFO = 3
}

export class ToastMessage
{
   private _msg: string = '';
   private _action: string = '';
   private _success: boolean = false;
   private _type: ToastMessageType = ToastMessageType.SUCCESS;

   constructor(msg: string, action: string, type: ToastMessageType)
   {
     this._msg = msg;
     this._action = action;
     this._type = type;
   }


   //Getters
   public get msg(): string
   {
     return this._msg;
   }

   public get action(): string
   {
     return this._action;
   }

   public get type(): ToastMessageType
   {
     return this._type;
   }

   //Methods
   public get isSuccess()
   {
     return this._type === ToastMessageType.SUCCESS;
   }

   public get isWarning()
   {
     return this._type === ToastMessageType.WARNING;
   }

   public get isError()
   {
     return this._type === ToastMessageType.ERROR;
   }

}
