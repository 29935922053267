import { UserService } from '../../../shared/services/user.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ValidateFullname } from '../../../shared/forms/validators/fullname.validator';
import { ValidateCellphone } from '../../../shared/forms/validators/cellphone.validator';
import { ValidatePassword } from '../../../shared/forms/validators/password.validator'
import { ValidateEmail } from '../../../shared/forms/validators/email.validator';
import { ValidadeOnlyLetters } from '../../../shared/forms/validators/onlyLetters.validator';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit  
{

  public readonly NAME_MAX_LENGTH: number = 70;
  public readonly NAME_MIN_LENGTH: number = 4;

  public userForm: FormGroup;
  public floatLabel: string = 'never';
  public maskTelefone = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public isInProgress: boolean = false;

  public _is_loading: boolean = false;
  public _is_email_already_inUse: boolean = false;

  public Name__In_Use: string = "";
  public Email__In_Use: string = "";
  public Cel__In_Use: string = "";

  constructor(private userService: UserService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this._createForm();
  }

  private _createForm(): void
  {
    this.userForm = this._formBuilder.group({
      name:  new FormControl('', [
        Validators.required, Validators.minLength(this.NAME_MIN_LENGTH), Validators.maxLength(this.NAME_MAX_LENGTH), ValidateFullname, ValidadeOnlyLetters
      ]),
      cellphone: new FormControl('', [
        Validators.required, ValidateCellphone
      ]),
      email: new FormControl('', [
        Validators.required, ValidateEmail
      ]),
      password: new FormControl('', [
        Validators.required, ValidatePassword
      ])
    });
  }

  public verifyEmail(): void{
    // var email = this.userForm.controls.email;
    // if (!email.hasError('email') && !email.hasError('required')){
    //   this._is_loading = true

    //   // this.userService.VerifyEmail(email.value).then((data) =>
    //   // {
    //   //   if (data != null){
    //   //     this._is_email_already_inUse = true;
    //   //     this.userForm.controls.email.setValue(email.value);
    //   //     this.userForm.controls.name.setValue(data.name);
    //   //     this.userForm.controls.cellphone.setValue(data.cellphone);
    //   //     this.Email__In_Use = email.value;
    //   //     this.Name__In_Use = data.name;
    //   //     this.Cel__In_Use = data.cellphone.replace("+55", "");
    //   //     this.userForm.disable();
    //   //     this._is_loading = false;
    //   //   }
    //   //   else
    //   //     this.enableInputs();
    //   //     this._is_loading = false;
    //   // })
    // }
    // else{
    // this.enableInputs();
    // this._is_loading = false;
    // }
  }

  public  onInputUserClick(): void
  {

    if(this.userForm.invalid)
      return;

    this.userForm.disable();
    this.isInProgress = true;
    var userObj = this.userForm.value;
    console.log(userObj);
    this.userService.AddUserAdmin(userObj).then((data) =>{
        this.isInProgress = false;
        console.log(data);
        this.dialog.closeAll();

    })
  }

  public getNameErrorMessage(): string
  {
    return this.userForm.controls.name.hasError('required')  ? 'Preencha o nome completo.' :
           this.userForm.controls.name.hasError('minlength') ? `Entre com no mínimo ${this.NAME_MIN_LENGTH} caracteres.` :
           this.userForm.controls.name.hasError('maxlength') ? `O número máximo de caracteres foi antingido.` :
           this.userForm.controls.name.hasError('fullname') ? 'Deve ser um nome completo.' :
           this.userForm.controls.name.hasError('onlyletters') ? 'Caracteres especiais não são permitidos.' : '';
  }

  private enableInputs(): void
  {
    this.userForm.enable();
    this._is_email_already_inUse = false;
  }

  public resetAll(): void
  {
    this.userForm.reset();
    this.Email__In_Use = "";
    this.Name__In_Use = "";
    this.Cel__In_Use = "";
    this.enableInputs();
  }
}