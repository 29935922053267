import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './app-authorize.component.html'
})

/**
 * Component to act as callback on a sign proccess
 */
export class AuthorizeComponent implements OnInit {
  lang: any;

  constructor(private authService: AuthService,
    private router: Router) { }

  ngOnInit() 
  {
    this.authService.verifyDataUserIsComplete().then(() => {
        //this.loaderService.hide();
        var redirect = JSON.parse(localStorage.getItem("after_auth_redirect_url"));
        if(redirect == null || redirect.url == null)
          this.router.navigate(['/empresas']);
        else
          this.router.navigate([redirect.url], { queryParams: redirect.queryParams });        
    });
  } 
}
