/** Commom operations for Url's */
export class UrlTool {

  constructor() { }

  /** Transform que query string in a objecto to be used with Router navigation */
  public static queryToObject(query?: string): any {
    var obj = {};

    if (!query) query = window.location.search;
    if (!query) return obj;

    query = query.substring(1);
    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }
}