import { AbstractControl } from '@angular/forms';


export function ValidadeOnlyLetters(control: AbstractControl): any
{ 
    if(control.value)
    {
        var regex = /^[a-zA-ZÀ-ÿ\s']+$/
        if(!regex.test(control.value) || control.value == "")
            return { onlyletters: true }       
    }
    return null;
}