<section>
  <div class="container list__search__content" fxLayout="row" fxLayoutAlign="center center">
    <i class="material-icons">search</i>
      <mat-form-field floatLabel="never">
        <input  matInput  placeholder="Procure por nome ou CNPJ ou ID " [(ngModel)]="value"  autocomplete="off" (keydown)="onInputKeyDown($event);" />
        <button  matSuffix mat-icon-button aria-label="Clear" (click)="onCleanInput()" *ngIf="!listSearchService.isMobileSearchActive">
          <mat-icon>close</mat-icon>
        </button> 
      </mat-form-field>
      <!-- Close Mobile -->
    <i class="material-icons close"(click)="onClose()">close</i>
  </div>
</section>
