<section>
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="modal__content">
    <h3 fxFlex="100" class="modal__title">Excluir usuário</h3>
    <h6 fxFlex="100" class="modal__subtitle">
      Quer realmente excluir o usuário
      "<strong [innerHtml]="data.name"></strong>"? <br> <!--ADD USER-->
      Essa ação não poderá ser desfeita.
    </h6>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="modal__actions">
    <div>
         <button mat-raised-button fxFlexFill color="accent" (click)="RemoveUserCompany();">EXCLUIR USUÁRIO</button>      
    </div>
  </div>
</section>