import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { CompanyService } from 'src/app/shared/services/company.service';
import { Company } from 'src/app/shared/model/company.model';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { ShareService } from 'src/app/shared/services/share.service';
import { forkJoin } from 'rxjs';
import { ShareQueryItem, ShareQuery } from 'src/app/shared/model/share-query.model';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ShareRemoveComponent } from '../share-remove/share-remove.component';

@Component({
  templateUrl: './shares.component.html',
  styleUrls: ['./shares.component.scss']
})
export class SharesComponent implements OnInit {

  filter: string = '';
  companyId: string = '';
  company: Company;
  displayedColumns: string[] = ['id', 'selfie', 'name', 'status', 'profile', 'created'];
  dataSource: ShareQueryItem[];
  queryResult: ShareQuery;
  query: string = '';
  @ViewChild('ctrl', { static: true }) ctrl: FormControl;

  total: number = 0;
  size: number = 10;

  public filters = [
    { value: 'status_', viewValue: 'Todos' },
    { value: 'status_NotStarted', viewValue: 'Novos' },
    { value: 'status_Started', viewValue: 'Em andamento' },
    { value: 'status_Pendent', viewValue: 'Pendentes' },
    { value: 'status_WaitingApproval', viewValue: 'Aguardando aprovação' },
    { value: 'status_Completed', viewValue: 'Concluídos' },
    { value: 'status_Active', viewValue: 'Ativos' },
    { value: 'status_Inactive', viewValue: 'Inativos' }
  ];

  public checkedItems: string[] = []

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  constructor(private readonly route: ActivatedRoute,
    private readonly companyService: CompanyService,
    private readonly loader: LoaderService,
    private readonly shareService: ShareService,
    private readonly dialog: MatDialog) { }

  ngOnInit() {
    this.companyId = this.route.snapshot.paramMap.get('id');
    this.loader.show();
    forkJoin(
      [
        this.companyService.getOne(this.companyId),
        this.shareService.query(this.companyId, this.query, 0, this.size, this.filter)
      ])
      .subscribe(results => {
        this.company = results[0];
        this.queryResult = results[1]
        this.dataSource = this.queryResult.items;
        this.total = this.queryResult.total;
        this.loader.hide();
      });

    this.ctrl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.doQuery();
    });
  }

  doQuery(): void {
    this.loader.show();
    this.shareService.query(this.companyId, this.query, 0, this.size, this.filter).subscribe(qResult => {
      this.checkedItems = [];
      this.paginator.firstPage();
      this.dataSource = qResult.items;
      this.total = qResult.total;
      this.loader.hide();
    });
  }

  onPageChange(event): void {
    this.size = event.pageSize;
    this.loader.show();
    this.shareService.query(this.companyId, this.query, event.pageIndex, this.size, this.filter).subscribe(qResult => {
      this.dataSource = qResult.items;
      this.total = qResult.total;
      this.loader.hide();
    });
  }

  isChecked(id: string): boolean {
    return this.checkedItems.indexOf(id) >= 0;
  }

  onCheckChange(e: MatCheckboxChange) {
    if (e.checked)
      this.checkedItems.push(e.source.value);
    else
      this.checkedItems = this.checkedItems.filter(i => i !== e.source.value);
  }

  onSelfieError(event): void {
    event.target.src = '/assets/female_avatar.png';
  }

  onRemoveClick(): void {
    let dialogRef = this.dialog.open(ShareRemoveComponent, { disableClose: true, maxWidth: "500px" })
    dialogRef.afterClosed().subscribe((e) => {
      if (e) {
        this.loader.show();
        this.shareService.sendToDeletionQueue(this.checkedItems, this.companyId)
          .subscribe(() => {
            alert('Os colaboradores foram enviados para a fila de exclusão, pode levar alguns momentos até que todos tenham sido deletados.');
            this.doQuery();
          });
      }
    });
  }
  onChangeFilters(filters): void{
    this.filter = filters;
    this.doQuery();
    
  } 
}