import { FormControl } from '@angular/forms';


export function cepValidator(control: FormControl) {
  const cep = control.value;
  if (cep && cep !== '') {
    let cepReplace = (cep as string).replace(/\W/g, '');
    const validacep = /^[0-9]{8}$/;
    return validacep.test(cepReplace) ? null : { cepInvalido: true };
  }
  return null;
}


