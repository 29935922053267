import { ExportData } from './../model/export-data.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Company } from '../model/company.model';
import { CompanyQuery } from '../model/company-query.model';
import { Observable } from 'rxjs';
import { AddCompanyCmd } from '../model/add-company-cmd.model';
import { CompanyView } from '../model/company-view.model';

@Injectable()
export class CompanyService {
  constructor(private readonly http: HttpClient) {}

  public async GetCompanies(pageSize: number, page: number, q: string): Promise<CompanyQuery> {
    return new Promise<CompanyQuery>((resolve, reject) => {
      this.http.get(environment.apiEndpoint + '/Company/GetAll?pageNumber=' + page + '&pageSize=' + pageSize + '&q=' + q).subscribe(
        (resp: CompanyQuery) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when getting list of companies!', err);
          reject(err);
        },
      );
    });
  }

  public getOne(id: string): Observable<Company> {
    return this.http.get<Company>(`${environment.apiEndpoint}/Company/${id}`);
  }

  public async UpdateOne(id: string, data: any): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.http.put(environment.apiEndpoint + '/Company/edit?companyId=' + id, data).subscribe(
        (resp: Company) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when update this company!', err);
          reject(err);
        },
      );
    });
  }

  public async ReindexOne(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(environment.apiEndpoint + '/Company/reindex/' + id).subscribe(
        (resp: any) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when reindex this company!', err);
          reject(err);
        },
      );
    });
  }

  public async BlockCompany(id: string, block: boolean): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.http.get(environment.apiEndpoint + '/Company/Block/' + id + '/' + block).subscribe(
        (resp: Company) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when block a company!', err);
          reject(err);
        },
      );
    });
  }

  public async AddIntegration(id: string, integrationName: string, integration: any): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.http.post(environment.apiEndpoint + '/Company/Integration/' + id + '/' + integrationName, integration).subscribe(
        (resp: Company) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when add integration to company!', err);
          reject(err);
        },
      );
    });
  }

  public async EditIntegration(id: string, integrationName: string, integration: any): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.http.put(environment.apiEndpoint + '/Company/Integration/' + id + '/' + integrationName, integration).subscribe(
        (resp: Company) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong when edit integration company!', err);
          reject(err);
        },
      );
    });
  }

  public verifyCnpj(cnpj: string): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.http.get(environment.apiEndpoint + '/Company/CNPJInUse?cnpj=' + cnpj).subscribe(
        (resp: any) => {
          resolve(resp);
        },
        (err) => {
          console.log('Something went wrong!', err);
          reject(false);
        },
      );
    });
    return promise;
  }
  public verifyCnpjObservable(cnpj: string): Observable<any> {
    const url = `${environment.apiEndpoint}/Company/CNPJInUse?cnpj=${cnpj}`;
    return this.http.get(url);
  }

  public cnpjIsTaken(cnpj: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiEndpoint}/Company/CNPJInUse?cnpj=${cnpj}`);
  }

  /**
   * Adds new company
   * @param cmd
   */
  public AddNewCompany(cmd: AddCompanyCmd): Observable<CompanyView> {
    return this.http.post<CompanyView>(`${environment.apiEndpoint}/Company/`, cmd);
  }

  public getExportLayout(companyId: string): Promise<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/Company/ExportLayout/${companyId}`).toPromise();
  }
  // exportData
  public pathExportData(companyId: string, key: string, value: string) {
    let url = `${environment.apiEndpoint}/Company/ExportData/${companyId}`;
    return this.http.patch(url, { key: `${key}`, value: `${value}` });
  }

  public getExportData(companyId: string) {
    let url = `${environment.apiEndpoint}/Company/Data/${companyId}`;
    return this.http.get<any>(url);
  }

  public deleteExportData(companyId: string, key: string) {
    let url = `${environment.apiEndpoint}/Company/ExportData/${companyId}/${key}`;
    return this.http.delete(url);
  }
  // adress
  public pathAddressData(
    companyId: string,
    zipCode: string,
    streetName: string,
    number: string,
    complement: string,
    city: string,
    neighborhood: string,
    streetType: string,
  ) {
    let url = `${environment.apiEndpoint}/Company/Address/${companyId}`;
    return this.http.patch(url, {
      zipCode: `${zipCode}`,
      streetName: `${streetName}`,
      number: `${number}`,
      complement: `${complement}`,
      city: `${city}`,
      neighborhood: `${neighborhood}`,
      streetType: `${streetType}`,
    });
  }
}
