import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { DigitalSignatureConfigWorkspace } from '../model/digital-signature-config-workspace';

@Injectable({
  providedIn: 'root',
})
export class DigitalSignatureConfigService {
  private baseUrl = `${environment.apiEndpoint}/digitalSignature/config`;
  constructor(private httpClient: HttpClient) {}

  getConfigForCompany(companyId: string): Promise<DigitalSignatureConfigWorkspace[]> {
    let url = `${this.baseUrl}/company/${companyId}`;
    return this.httpClient.get<DigitalSignatureConfigWorkspace[]>(url).toPromise();
  }

  public getConfigForCompany$(companyId: string): Observable<DigitalSignatureConfigWorkspace[]> {
    let url = `${this.baseUrl}/company/${companyId}`;
    return this.httpClient.get<DigitalSignatureConfigWorkspace[]>(url).pipe(
      catchError((err) => {
        console.error('getConfigForCompany$ err :>> ', err);
        return of([]);
      }),
    );
  }

  toggleForAllWorkspaces(companyId: string, enable: boolean) {
    let url = `${this.baseUrl}/company/${companyId}/enable/${enable}`;
    return this.httpClient.patch(url, {}).toPromise();
  }

  toggleForOneWorkspace(companyId: string, workspaceId: string, enable: boolean) {
    let url = `${this.baseUrl}/company/${companyId}/workspace/${workspaceId}/enable/${enable}`;
    return this.httpClient.patch(url, {}).toPromise();
  }

  public toggleEnableUpload$(companyId: string, workspaceId: string, enable: boolean) {
    const url = `${this.baseUrl}/company/${companyId}/workspace/${workspaceId}/enable-upload/${enable}`;
    return this.httpClient.patch(url, {}).pipe(
      catchError((err) => {
        console.error('toggleForOneWorkspaceUploadFile err :>> ', err);
        return EMPTY;
      }),
    );
  }
}
