import { ToastService } from './../../../shared/services/toast.service';
import { Workspace } from 'src/app/shared/model/workspace/workspace.model';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsManagementService } from '../forms-management.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ListSearchService } from 'src/app/shared/services/list-search.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatStepper } from '@angular/material/stepper';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-implement-form-modal',
  templateUrl: './implement-form-modal.component.html',
  styleUrls: ['./implement-form-modal.component.scss'],
})
export class ImplementFormModalComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  // Referência ao paginator e ao sort
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public workspaces: Workspace[] = new Array<Workspace>();
  public workspaces$: Observable<Workspace[]>;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public isEditable = true;
  public companyName: string;
  public workspaceName: Array<any> = [];
  public workspacesName: any;
  public workspacesId: Array<any> = [];
  public companiesPrd: Array<prdModel>;
  public workspacesPrd: Array<prdWorkModel>;
  public isLoading: boolean;
  public paginatedArray: Array<prdModel>;
  private backup: Array<prdModel>;
  public selectedItem: string = '';
  // Propriedades de paginação
  pageIndex: number = 0;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(
    private toastService: ToastService,
    public listSearchService: ListSearchService,
    private formsManagementService: FormsManagementService,
    private _formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<ImplementFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.stepperControl();
    this.getAllCompanysPrd();
  }

  stepperControl() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: this._formBuilder.array([], Validators.required),
    });
  }

  public chooseCompany(companyId: string, companyName: string, stepper: MatStepper) {
    this.firstFormGroup.controls['firstCtrl'].setValue(companyId);
    this.companyName = companyName;
    this.getWorkspacesPrd(companyId);
    setTimeout(() => {
      stepper.next();
    }, 100);
  }

  public chooseWorkspace(workspace: WorkspaceModel) {
    const secondCtrlArray = this.secondFormGroup.get('secondCtrl') as FormArray;
    const valueExists = secondCtrlArray.value.find((value) => value === workspace.id);

    if (!valueExists) {
      secondCtrlArray.push(this._formBuilder.control(workspace.id));
      this.workspaceName.push(workspace.name);
      this.workspacesId.push(workspace.id);
      workspace.selected = !workspace.selected;
      this.workspacesName = this.workspaceName.join(', ');
    } else if (valueExists) {
      const index = secondCtrlArray.value.findIndex((value) => value === workspace.id);
      secondCtrlArray.removeAt(index);
      this.workspaceName.splice(index, 1);
      this.workspacesId.splice(index, 1);
      workspace.selected = !workspace.selected;
      this.workspacesName = this.workspaceName.join(', ');
    }
  }
  public nextStepConfirmation(stepper: MatStepper) {
    stepper.next();
  }

  public async getAllCompanysPrd() {
    this.isLoading = true;
    this.companiesPrd = await this.formsManagementService.getAllCompanyPrd().toPromise();
    // this.paginatedArray = this.companiesPrd;
    let companyFindCnpj = this.companiesPrd.filter((company) => company.CNPJ == this.data.company.cnpj);
    this.paginatedArray = companyFindCnpj;
    this.isLoading = false;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.paginatedArray.filter((option) => option.Name.toLowerCase().includes(filterValue));
  }

  AddCompanyToList(company) {
    if (company !== 'clear') {
      this.backup = [...this.paginatedArray];
      const selectedCompany = this.backup.find((obj) => obj.Name === company);
      this.paginatedArray = selectedCompany ? [selectedCompany] : [];
    } else {
      this.paginatedArray = [...this.companiesPrd];
      this.myControl.setValue('');
    }
  }

  updatePaginatedArray(event: PageEvent) {
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedArray = this.companiesPrd.slice(startIndex, endIndex);
  }

  public async getWorkspacesPrd(companyId: string) {
    this.isLoading = true;
    let works = await this.formsManagementService.getWorkspacesProduction(companyId).toPromise();
    this.workspacesPrd = Object.keys(works).map((key) => ({
      id: key,
      name: works[key],
    }));
    this.isLoading = false;
  }

  public async implenteFormPrd() {
    this.isLoading = true;
    let companyId = this.firstFormGroup.controls['firstCtrl'].value;
    let formId = this.data.formId;
    let cmd = {
      TargetCompany: companyId,
      TargetWorkspaces: this.workspacesId,
      SourceBenefitsForm: formId,
    };
    try {
      let response = await this.formsManagementService.postFormInPrd(cmd).toPromise();
      this.toastService.success(`Formulário integrado com sucesso na ${this.companyName}`);
      this.isLoading = false;
      this.dialogRef.close(response);
    } catch (error) {
      console.log('error ::>', error);
      this.toastService.error(`Erro ao integrar ${this.data.formName}, tente novamente`);
      this.isLoading = false;
      this.dialogRef.close();
    }
  }
}

export class prdModel {
  Id: string;
  Name: string;
  CNPJ: string;
}
export class prdWorkModel {
  id: string;
  name: string;
}

export interface WorkspaceModel {
  id: string;
  name: string;
  selected: boolean; // Propriedade para controlar o estado de seleção
}
