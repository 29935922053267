import { Address } from './../../../shared/model/address.model';
import { Workspace } from './../../../shared/model/workspace/workspace.model';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { ToastService } from 'src/app/shared/services';
import { cepValidator } from 'src/app/shared/forms/validators/form.validator';
import { queryCepService } from 'src/app/shared/services/consult-cep.service';
import { map, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { empty } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-workspace-address',
  templateUrl: './app-workspace-address.component.html',
  styleUrls: ['./app-workspace-address.component.scss']
})
export class AppWorkspaceAddressComponent implements OnInit {
  // * Variaveis
  @Input() address: Address;
  public companyUpdating: boolean = false;
  public addressForm: FormGroup;
  public AuxExportData: Address;
  public companyId: string;
  public workspaceId: string;
  public custom_mask;

  constructor(
    private workspaceService: WorkspaceService,
    private formBuilder: FormBuilder,
    private toasterService: ToastService,
    private cepService: queryCepService,
    private activatedRouter: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initAdressForm();
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
    this.activatedRouter.params.subscribe(params => this.workspaceId = params.workId);
    this.getExportData();
    this.custom_mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }
  public async getExportData() {
    await this.workspaceService.GetWorkspaceById(this.workspaceId).toPromise().then(obj => {
      this.AuxExportData = obj.data.address
      if (this.AuxExportData) {
        this.addressForm.setValue(this.AuxExportData);
        this.addressForm.controls.complement.setValue(this.AuxExportData.complement);
      }
    });
    this.initialValidator();
  }
  public initAdressForm() {
    // let cep = this.address.zipCode.replace(/\W/g, '')
    this.addressForm = this.formBuilder.group({
      zipCode: new FormControl('', [Validators.required, cepValidator]),
      streetType: new FormControl('', [Validators.required]),
      streetName: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(this.AuxExportData?.complement),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required])
    });
  }

  public initialValidator() {
    this.addressForm.get('zipCode').statusChanges.pipe(
      distinctUntilChanged(),
      switchMap(status => status === 'VALID' ?
        this.cepService.queryCep(this.addressForm.get('zipCode').value)
        : empty()
      )
    ).subscribe(data => data ? this.dataForm(data) : {});
  }

  public dataForm(data) {
    let aux: Array<string>;
    let cep = this.addressForm.get('zipCode').status;
    if (cep == 'VALID' && data.erro != 'true') {
      aux = (data.logradouro as string).split(' ');
      aux.reverse().pop();
      this.addressForm.get('streetName').setValue(aux.reverse().join(' '));
      this.addressForm.get('city').setValue(`${data.localidade}/${data.uf}`);
      this.addressForm.get('neighborhood').setValue(data.bairro);
    } else {
      this.toasterService.error('Ops... cep inválido, tente novamente!');
    }
  }

  public async pathAddressData() {
    let zipCode = this.addressForm.get('zipCode').value;
    let streetName = this.addressForm.get('streetName').value;
    let number = this.addressForm.get('number').value;
    let complement = this.addressForm.get('complement').value;
    let city = this.addressForm.get('city').value;
    let neighborhood = this.addressForm.get('neighborhood').value;
    let streetType = this.addressForm.get('streetType').value;
    try {
      await this.workspaceService.pathAddressData(
        this.companyId,
        this.workspaceId,
        zipCode,
        streetName,
        number,
        complement,
        city,
        neighborhood,
        streetType).toPromise();
      this.toasterService.success('Endereço salvo com sucesso!');


    } catch (error) {
      this.toasterService.error('Houve algum erro, tente novamente.')
    }

  }

  public showFormCompany() {
    this.companyUpdating = !this.companyUpdating;
  }
}
