import { PropertyDataConfig } from './../../model/schema-config/template-config.model';
import { Injectable } from '@angular/core';

import { SchemaFullConfig } from '../../model/schema-config/schema-full-config.model';
import { SchemaField, SchemaProperties } from '../../model/schema-config/shema.model';

// * Set properties filter

@Injectable({
  providedIn: 'root',
})
export class SchemaFieldService {
  // * Variables
  public schemaFullConfig: SchemaFullConfig;

  // * Arrays
  private schemaFields: Array<SchemaField> = new Array<SchemaField>();

  //#region // * Objs Config

  public objToSchemaField(obj) {
    let keys = Object.keys(obj);
    let schemaField = new SchemaField();

    keys.forEach((property) => {
      let value = obj?.[property] ?? obj?.keys?.[property];

      let propertyName = null;

      if (property.includes('FIXED') || !property.includes('-') || property.includes('DYNAMIC')) {
        propertyName = property.replace(/\w.*-/g, '');
        propertyName == 'name' ? (value = value.toString().replace(/\W+/g, '')) : '';
        schemaField[this.formatProperty(propertyName)] = value;
      } else {
        propertyName = property.split('-').map((prop) => {
          return this.formatProperty(prop);
        });

        if (property.includes('VALIDATION')) {
          schemaField[propertyName[0]] = { ...schemaField[propertyName[0]] } ?? {};
          schemaField[propertyName[0]][propertyName[1]] = value;
        }

        if (property.includes('ENUM')) {
          schemaField[propertyName[0]] = new Array(...(schemaField[propertyName[0]] ?? []));
          schemaField[propertyName[0]].push(value);
        }

        if (property.includes('-name')) {
          let index = propertyName[1].replace(/\D+/g, '');
          let titleMapObj = new Object();

          schemaField[propertyName[0]] = new Array(...(schemaField[propertyName[0]] ?? []));

          Object.assign(titleMapObj, { value: obj[`TITLE_MAP-value${index}`], name: obj[`TITLE_MAP-name${index}`] });
          schemaField[propertyName[0]].push(titleMapObj);
        }

        if (property.includes('-function')) {
          let triggerObj = new Object();
          let arrParameters = new Array();
          let index = propertyName[1].replace(/\D+/g, '');
          let parameters = Object.keys(obj).filter((x) => x.split('-')[1].includes('parameters') && x.split('-')[1].includes(index));

          parameters.forEach((param) => {
            arrParameters.push(obj[param]);
          });

          schemaField[propertyName[0]] = new Array(...(schemaField[propertyName[0]] ?? []));

          Object.assign(triggerObj, { function: obj[property], parameters: arrParameters });
          schemaField[propertyName[0]].push(triggerObj);
        }
      }
    });

    return schemaField;
  }

  public getPropsOfndexedClass(obj: any) {
    let keys = Object.keys(obj);
    let mappedProperties = keys.map((prop, index) => {
      return { isFormatted: this.formatProperty(prop), noFormatted: keys[index] };
    });
    return mappedProperties;
  }

  public resetConfigurations(obj: any) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      console.log('key :>> ', key);
      SchemaProperties[key].resetValuesToDefault();
    });
  }

  public formatProperty(prop: string) {
    let args = prop.toLowerCase().split('_');
    args = args.map((word, index) => {
      if (index !== 0) return word.charAt(0).toUpperCase() + word.slice(1);
      return word;
    });

    return args.join('');
  }

  //#endregion

  //#region // * Schema Config

  public addSchemaField(schemaField: SchemaField) {
    if (!this.schemaFields.find((x) => x.name == schemaField?.name)) {
      this.schemaFields.push(schemaField);
    }
  }

  public updateSchemaField(index: number, schemaField: SchemaField) {
    let fieldToReplace = this.schemaFields[index];
    if (!fieldToReplace) return;

    Object.assign(fieldToReplace, schemaField);
  }

  public appendSchemaField(schemaFields: Array<SchemaField>) {
    schemaFields.forEach((schemaField) => {
      if (!this.schemaFields.find((x) => x.name == schemaField.name)) {
        this.schemaFields.push(schemaField);
      }
    });
  }

  public removeSchemaField(schemaFieldName: string) {
    this.schemaFields = this.schemaFields.filter((item, index, self) => self.findIndex((obj) => obj.name === schemaFieldName) !== index);
  }

  public resetSchemaFields() {
    this.schemaFields = [];
  }

  public getSchemaFields() {
    return this.schemaFields;
  }

  public setSchemaFields(fields: SchemaField[]) {
    this.schemaFields = fields;
  }

  //#endregion
}
