<section>
    <div class="header">
        <h5 class="title">Implantar formulários</h5>
        <span class="description">Você pode escolher o formulário que deseja implantar em produção</span>
    </div>
    <mat-card class="container-load mat-elevation-z4" *ngIf="isLoading">
        <section class="load-content">
            <app-loading></app-loading>
        </section>
    </mat-card>
    <mat-card class="body" *ngIf="!isLoading">
        <h6 class="title">Formulários</h6>

        <mat-form-field appearance="standard" class="input-search">
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Segmentos" #input>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                <td mat-cell *matCellDef="let row"> {{row.acronym}} </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>

            <!--action Column-->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Ação </th>
                <td mat-cell *matCellDef="let row">
                    <div class="actions">
                        <mat-icon (click)="implentForms(row.id, row.acronym)">ios_share</mat-icon>
                    </div>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </mat-card>
</section>