import { ExportData } from './../../shared/model/export-data.model';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Company } from '../../shared/model/company.model';
import { CompanyService } from '../../shared/services/company.service';
import { ValidateCNPJ } from '../../shared/forms/validators/cnpj.validator';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './app-integrations.component.html',
  styleUrls: ['./app-integrations.component.scss']
})
export class AppIntegrationsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private activatedRouter: ActivatedRoute,
    private toasterService: ToastService,
  ) { }

  public companyId: string;
  public company: Company;
  @Input('company')
  public set companyData(value: Company) {
    this.company = value;
    this.configFormIntegration();
    this._fillWebFopagIntegration();
  }

  public maskCNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public isLoading: boolean = false;
  public editingIntegration: boolean = false;
  public isCnpjLoading: boolean = false;

  public hasWebFopagIntegration: boolean = false;
  public addingIntegration: boolean = false;
  public integrationForm: FormGroup;

  public actualIntegration: any = {
    CS: "",
    CNPJ: ""
  };
  public exportForm: FormGroup;
  // public exportData: Array<any> = [];
  public exportData: any;
  public reserveValue: any[] = [];

  public actualExportData: any = {
    keyOne: "",
    keyTwo: ""
  }

  ngOnInit() {
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
    this.exportForm = this.formBuilder.group({
      keyOne: [''],
      keyTwo: [''],
    });
    this.getExportData();
  }

  public async editIntegration() {
    if (!this.isFormValid)
      return;

    this.isLoading = true;
    var integrationName = this.integrationForm.controls.type.value;
    var integration = {
      CS: this.integrationForm.controls.cs.value,
      CNPJ: this.integrationForm.controls.cnpj.value,
    }

    var waiter: any;

    if (this.company.hasIntegration && this.company.integrations[integrationName] != undefined)
      waiter = this.companyService.EditIntegration(this.company.id, integrationName, integration);
    else
      waiter = this.companyService.AddIntegration(this.company.id, integrationName, integration);

    await waiter.then((resp: any) => {
      this.company = resp;
      this.editingIntegration = false;
      this.isLoading = false;
    });
  }

  public onEditingIntegration() {
    this.editingIntegration = !this.editingIntegration;
  }

  public get getCnpjErrorMessage(): string {
    return this.integrationForm.controls.cnpj.hasError('required') ? 'Preencha o CNPJ da empresa.' :
      this.integrationForm.controls.cnpj.hasError('cnpj') ? 'O CNPJ é inválido.' :
        this.integrationForm.controls.cnpj.hasError('exists') ? 'Este CNPJ já está cadastrado no Lugarh.' : '';
  }

  public get isFormValid(): boolean {
    if (!this.integrationForm.invalid)
      return true;
    return false;
  }

  private configFormIntegration() {
    this.integrationForm = this.formBuilder.group({
      type: new FormControl('', [
        Validators.required
      ]),
      cs: new FormControl('', [
        Validators.required
      ]),
      cnpj: new FormControl('', [
        Validators.required, ValidateCNPJ
      ])
    });
  }

  private _fillWebFopagIntegration(): void {
    if (this.company.integrations && typeof this.company.integrations === 'object' && this.company.integrations.hasOwnProperty('WebFopag')) {
      const webfopag = this.company.integrations.WebFopag;
      if (webfopag && typeof webfopag === 'object' && webfopag.hasOwnProperty('CS') && webfopag.hasOwnProperty('CNPJ')) {
        this.hasWebFopagIntegration = true;
        this.integrationForm.controls.cs.setValue(webfopag.CS);
        this.actualIntegration.CS = webfopag.CS;
        this.integrationForm.controls.cnpj.setValue(webfopag.CNPJ);
        this.actualIntegration.CNPJ = webfopag.CNPJ;
      }
    }
  }

  //Dados de exportação
  public async pathExportData() {
    let keyOne = this.exportForm.get('keyOne').value;
    let keyTwo = this.exportForm.get('keyTwo').value;   
    if (keyOne != this.exportData?.ExportCompany?.key) {     
      await this.companyService.pathExportData(this.companyId, keyOne, keyTwo).toPromise();
      setTimeout(() => {
        this.getExportData();
      }, 4000);
    } else if (keyOne == this.exportData.ExportCompany.key) {     
      this.toasterService.error('Chave já existente');
    }  
  }

  public async getExportData() {
    await this.companyService.getExportData(this.companyId).toPromise().then(obj => {
      this.exportData = obj
    });
    let data = this.exportData?.ExportCompany;
    this.reserveValue = Object.keys(data).map(key => ({ type: key, value: data[key] }));  
    
  }

  public async deleteExportData(key: string) {
    try {
      await this.companyService.deleteExportData(this.companyId, key).toPromise();
      setTimeout(() => {
        this.getExportData();
      }, 1000);
      this.toasterService.success(`${key} deletada com sucesso !`);

    } catch (error) {
      this.toasterService.error('Houve algum erro, tente novamente.')
    }

  }

}
