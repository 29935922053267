import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Plan } from '../model/plan.model';

@Injectable()
export class PlanService {

    readonly _actualMainPlanLocalStorageItemName: string = 'PlanService:actual_main_plan';

    constructor(private http: HttpClient) { }

    public async GetPlan(planId: number): Promise<Plan> {
        const promise = new Promise<Plan>((resolve, reject) => {
        this.http.get(environment.apiEndpoint + '/plan/' + planId)
            .toPromise()
            .then((resp: Plan) => {
                resolve(resp);
            })
            .catch((resp: any) => {
                reject(resp);
            });
        });
        return promise;
    }

    public async GetQuotasLoggedCompany(companyId: string): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
        this.http.get(environment.apiEndpoint + '/quota/GetQuotasEntity/' + companyId)
            .toPromise()
            .then((resp: any) => {
                resolve(resp);
            })
            .catch((resp: any) => {
                reject(resp);
            });
        });
        return promise;
    }

    public async UpdateShareQuotasEntity(companyId: string, avaiable: number): Promise<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
        this.http.put(environment.apiEndpoint + '/quota/UpdateShareQuotasEntity/' + companyId + '/' + avaiable, null)
            .toPromise()
            .then((resp: any) => {
                resolve(true);
            })
            .catch((resp: any) => {
                reject(false);
            });
        });
        return promise;
    }

    public async UpdateAttachmentQuotasEntity(companyId: string, available: number): Promise<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
        this.http.put(environment.apiEndpoint + '/quota/UpdateAttachmentQuotasEntity/' + companyId + '/' + available, null)
            .toPromise()
            .then((resp: any) => {
                resolve(true);
            })
            .catch((resp: any) => {
                reject(false);
            });
        });
        return promise;
    }
}