import { Component, OnInit, Input } from '@angular/core';
import { EmployeesSummary } from '../models';
import { MatDialog } from '@angular/material/dialog';
import { RemoveEmployeesDialogComponent } from '../remove-employees-dialog/remove-employees-dialog.component';
import { EmployeesService } from '../services/employees.service';

@Component({
  selector: 'employees-panel',
  templateUrl: './employees-panel.component.html',
  styleUrls: ['./employees-panel.component.scss']
})
export class EmployeesPanelComponent implements OnInit {
  

  @Input()
  public companyId: string;

  private _loading: boolean = false;
  public status: string = '';
  public summary: EmployeesSummary = { 
    completed: 0,
    inactive: 0,
    notStarted: 0,
    pendent: 0,
    started: 0,
    waitingApproval: 0,
    total: 0
  };

  constructor(private _dialog: MatDialog, private _service: EmployeesService) { }

  public get isDeleteDisabled(): boolean 
  {
    return !(this.status.length > 0);
  }

  public showRemove(): void 
  {
    const dialogRef = this._dialog.open(RemoveEmployeesDialogComponent, {
      data: {companyId: this.companyId, status: this.status}
    });


    dialogRef.afterClosed().subscribe(
      (result: { status: string }) => 
      {
        if(result.status.length > 0)
        {
          switch(result.status)
          {
            case 'NotStarted':
              this.summary.total -= this.summary.notStarted;
              this.summary.notStarted = 0;
              break;
            case 'Inactive':
              this.summary.total -= this.summary.inactive;
              this.summary.inactive = 0;
              break;
          }
        }
      }
    );
  }
   

  ngOnInit() 
  {
    this._getSummary();
  }

  public get loading(): boolean
  {
    return this._loading;
  }

  private _getSummary(): void
  {
    this._loading = true;
    this._service.getSummary(this.companyId).subscribe(sum  =>
    {  
      this.summary = sum; 
      this._loading = false;
    });
  }


  public get isRemoveVisible(): boolean {
    return this.summary.inactive > 0 ||
           this.summary.notStarted > 0;
  }

  public get isInactiveOptionEnabled(): boolean {
    return this.summary.inactive > 0;
  }

  public get isNotStartedOptionEnabled(): boolean {
    return this.summary.notStarted > 0;
  }

}
