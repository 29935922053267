import { Injectable } from '@angular/core';
import { AppToastComponent } from '../app-toast/app-toast.component';

/**
 * Service to control de toasts messages
 */
@Injectable()
export class ToastService 
{

    constructor(private toastComp: AppToastComponent) { }

    public success(message: string): void 
    {
        this.toastComp.showSuccess(message);
    }

    public warning(message: string): void 
    {
        this.toastComp.showWarning(message);
    }

    public error(message: string): void 
    {
        this.toastComp.showError(message);
    }
}