import { PropertyConfig } from './property-config.model';

export class TemplateConfig {
  data?: PropertyDataConfig = {};
  title?: string;
  list?: Array<any>;
  items?: Array<any>;
  subtitle?: string;
  template?: string;

  constructor(field?: {
    template?: string;
    title?: string;
    subtitle?: string;
    data?: PropertyDataConfig;
    list?: Array<any>;
    items?: Array<any>;
  }) {
    this.data = field?.data ?? new PropertyDataConfig();
    this.title = field?.title ?? null;
    this.list = field?.list ?? [];
    this.items = field?.items ?? [];
    this.subtitle = field?.subtitle ?? null;
    this.template = field?.template;
  }
}

export class PropertyDataConfig {
  template?: string = null;
  list?: Array<PropertyConfig> = [];
  dropdownValues?: Array<PropertyConfig> = [];
  dropdownOptions?: DropdownOptions = {};
}

class DropdownOptions {
  list?: Array<PropertyConfig> = [];
  totalProperties?: number = 0;
}
