import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../shared/services/company.service';
import { Company } from '../shared/model/company.model';
import { LoaderService } from '../shared/security/loader.service';
import { UserInfo } from '../shared/model/userInfo.model';
import { UserService } from '../shared/services/user.service';
import { ToastService } from '../shared/services/toast.service';
import { AuthorizationService } from '../shared/services/authorization.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SetupDigitalSignatureComponent } from './setup-digital-signature/setup-digital-signature.component';


@Component({
  selector: 'app-app-company',
  templateUrl: './app-company.component.html',
  styleUrls: ['./app-company.component.scss']
})
export class AppCompanyComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  //Company
  public companyId: string;
  public company: Company;

  //User
  public users: UserInfo[];
  public userToAdd: UserInfo = null;

  public addingUser: boolean = false;
  public setupDialogRef: DynamicDialogRef;

  constructor(
    private companyService: CompanyService,
    private activatedRouter: ActivatedRoute,
    private userService: UserService,
    private loaderService: LoaderService,
    private readonly toastService: ToastService,
    private readonly authorizationService: AuthorizationService,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
    this.LoadCompanyInfos();
    this.requestLoadProfiles(this.companyId);
  }

  ngOnDestroy(): void {
    if (this.setupDialogRef) {
        this.setupDialogRef.close();
    }
  }

  private async LoadCompanyInfos() {
    this.loaderService.show();
    await this.requestLoadCompany();
    this.loaderService.hide();
  }

  //Load infos company
  public requestLoadCompany() {
    this.isLoading = true;
    this.companyService.getOne(this.companyId).subscribe(company => {
      this.company = company;
      this.isLoading = false;
    });
  }

  //Load users company
  public async LoadUsers() {
    this.users = new Array<UserInfo>();
    this.company.users.forEach(async user => {
      this.users.push(await this.userService.GetUserInfo(user));
    });
  }

  public onFindMailKeyDown(evt: KeyboardEvent, value: string): void {
    if (evt.key === "Enter") {
      event.preventDefault();
      this.GetUserByEmail(value);
    }
  }

  //Get user by email
  public GetUserByEmail(email: string) {
    this.loaderService.show();
    this.userService.UserExists(email).subscribe(
      (usr: UserInfo) => {
        if (usr != null)
          this.userToAdd = usr;
        this.loaderService.hide();
      },
      err => {
        this.toastService.error('Não foi possível carregar dados de usuário');
        this.loaderService.hide();
      });
  }

  //Add user in company
  public async AddUserCompany(id: string) {
    this.loaderService.show();

    if (await this.userService.AddUserCompany(id, this.company.id)) {
      this.company.users.push(id);
      await this.LoadUsers();
    }

    this.addingUser = false;
    this.userToAdd = null;

    this.loaderService.hide();
  }

  //Remove user from company
  public async RemoveUserCompany(id: string) {
    this.loaderService.show();

    if (await this.userService.RemoveUserCompany(id, this.company.id)) {
      var index = this.company.users.indexOf(id);
      this.company.users.splice(index, 1);
      await this.LoadUsers();
    }

    this.loaderService.hide();
  }

  //Enable user to add
  public AddingUser() {
    this.addingUser = !this.addingUser;
  }

  public enableAuthorization: number = 999;

  public requestLoadProfiles(companyId: string) {

    this.authorizationService.GetAllProfiles(companyId).subscribe(profiles => {

      this.enableAuthorization = (Number) (profiles != null && profiles.length > 0);

    });
  }
  public async onEnableAuthorizationClick(): Promise<void> {
    await this.authorizationService.EnableNewAuthorizationMethod(this.companyId);
    await this.requestLoadProfiles(this.companyId);
  }

  public async onDisableAuthorizationClick(): Promise<void> {
    await this.authorizationService.DisableNewAuthorizationMethod(this.companyId);
    await this.requestLoadProfiles(this.companyId);
  }

  public showSetupDigitalSignatureDialog() {
      this.setupDialogRef = this.dialogService.open(SetupDigitalSignatureComponent, {
          data: {
            companyId: this.company.id
          },
          header: 'Configurar assinatura digital na empresa ' + this.company.name,
          width: '50vw',
          contentStyle: {"max-height": "75vh", "overflow": "auto"},
          baseZIndex: 10000
      });
  }
}