import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizeComponent } from './app-authorize/app-authorize-component';
import { AppWorkspaceCompanyComponent } from './app-company-workspaces/app-workspace-company/app-workspace-company.component';
import { AppCompanyComponent } from './app-company/app-company.component';
import { CompanyFormComponent } from './app-company/company-form/company-form.component';
import { FormsManagementComponent } from './app-company/forms-management/forms-management.component';
import { AppEnterpriseListComponent } from './app-enterprise-list/app-enterprise-list.component';
import { UserManagementComponent } from './app-menu/user-management/user-management.component';
import { ContractConfigsComponent } from './components/contract-configs/contract-configs.component';
import { GeneratedReportComponent } from './components/report/generated-report/generated-report.component';
import { ShareReportComponent } from './components/report/share-report/share-report.component';
import { SchemaConfigComponent } from './components/schema-config/schema-config.component';
import { SchemaManagerComponent } from './components/schema-manager/schema-manager.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { SharesComponent } from './shares/shares/shares.component';
import { UserCompanyComponent } from './users-companys/user-company/user-company.component';

const appRoutes: Routes = [
    { path: 'authorize', component: AuthorizeComponent },
    { path: 'settings', component: UserManagementComponent },
    { path: 'empresas', component: AppEnterpriseListComponent, canActivate: [AuthGuardService] },
    { path: 'empresas/cadastrar', component: CompanyFormComponent, canActivate: [AuthGuardService] },
    { path: 'empresa/:id', component: AppCompanyComponent, canActivate: [AuthGuardService] },
    { path: 'empresa/:id/colaboradores', component: SharesComponent, canActivate: [AuthGuardService] },
    { path: 'empresa/:id/workspace/:workId', component: AppWorkspaceCompanyComponent, canActivate: [AuthGuardService] },
    { path: 'empresa/:id/workspace/:workId/formularios', component: FormsManagementComponent},
    { path: 'empresa/:id/user-company', component: UserCompanyComponent, canActivate: [AuthGuardService] },
    { path: 'schemas', component: SchemaManagerComponent, canActivate: [AuthGuardService] },
    { path: 'contratos', component: ContractConfigsComponent, canActivate: [AuthGuardService] },

    { path: 'empresa/:id/schema-config', component: SchemaConfigComponent, canActivate: [AuthGuardService] },

    //#region Report
    { path: 'share-report', component: ShareReportComponent, canActivate: [AuthGuardService] },
    { path: 'generated-report', component: GeneratedReportComponent, canActivate: [AuthGuardService] },
    //#endregion

    { path: '', redirectTo: 'empresas', pathMatch: 'full' },
    { path: '**', redirectTo: 'empresas' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
