<section  *ngIf="this.menuService.showMenu == true" (click)="this.menuService.closeMenu();" >
  <div fxLayout="row wrap" class="user__menu" >
    <!-- Close -->
    <div fxFlex="100" fxLayoutAlign="end center" class="user__menu__item">
      <button mat-icon-button >
        <i class="material-icons">close</i>
      </button>
    </div>

    <!-- Company -->
    <div fxFlex="100" class="user__menu__item company__item">
      <div fxLayout="row" fxLayoutAlign="start center" class="logged__company">
        <i class="material-icons">perm_identity</i>
        <p fxFlex class="company__name is-active" *ngIf="userService.getUserInfo != undefined">{{userService.getUserInfo.name}}</p>
      </div>
    </div>

    <div fxFlex="100" fxLayoutAlign="end center" class="user__menu__item">
      <button mat-button (click)="loggout();" class="button__small">
        Sair
      </button>
    </div>
  </div>

</section>
