<p-toast position="top-right"></p-toast>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-panel header="Relatório de Shares">
  <ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

  <div class="grid">
    <p-tabView>
      <p-tabPanel header="Filtro de Período">
        <div class="col">
          <p-calendar
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [readonlyInput]="true"
            inputId="range"
            hourFormat="24"
            [inline]="true"
            [yearNavigator]="true"
            yearRange="2000:2050"
            [showTime]="false">
          </p-calendar>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Filtro de Empresas">
        <div class="col">
          <ngx-loading [show]="isLoadingCompany" [config]="{ fullScreenBackdrop: false }"></ngx-loading>

          <p-pickList
            [showTargetControls]="false"
            [showTargetFilter]="false"
            [showSourceControls]="false"
            [source]="sourceCompanies"
            [target]="targetCompanies"
            sourceHeader="Disponíveis"
            targetHeader="Selecionados"
            [dragdrop]="true"
            [responsive]="true"
            [sourceStyle]="{ height: '30rem' }"
            [targetStyle]="{ height: '30rem' }"
            filterBy="name,cnpj,id"
            sourceFilterPlaceholder="Pesquisar campo"
            invalidFileSizeMessageSummary="{0}: Tamanho inválido"
            invalidFileSizeMessageDetail="Tamanho máximo do upload é de {0}"
            invalidFileTypeMessageSummary="{0}: Formato inválido"
            invalidFileTypeMessageDetail="Formatos permitidos: {0}"
            invalidFileLimitMessageDetail="Máximo de arquivos: {0}"
            invalidFileLimitMessageSummary="Número máximo de arquivos excedido">
            <ng-template let-field pTemplate="item">
              <div class="file-item">
                <div class="image-container"></div>
                <div class="file-list-detail">
                  <i class="pi pi-home field-description-icon"></i>
                  <span class="field-description" *ngIf="!field?.name">Sem descrição</span>
                  <span class="field-description">{{ field.name }}</span>
                </div>
              </div>
            </ng-template>
          </p-pickList>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <button (click)="generateShareReport()" pButton type="button" label="Gerar Relatório"></button>
</p-panel>
