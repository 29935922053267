import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Company } from 'src/app/shared/model/company.model';
import { CreateContractConfigCmd } from 'src/app/shared/model/create-contract-cmd.model';
import { MappingField } from 'src/app/shared/model/mapping-field.model';
import { UpdateContractConfigCmd } from 'src/app/shared/model/update-contract-config-cmd.model';
import { Workspace } from 'src/app/shared/model/workspace/workspace.model';
import { CompanyService, MenuService, WorkspaceService } from 'src/app/shared/services';
import { ContractConfigService } from 'src/app/shared/services/contract-config.service';

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.scss'],
  providers: [DialogService],
})
export class CreateContractComponent implements OnInit {
  isLoading: boolean;
  name: string;
  description: string;
  companies: Company[];
  filteredCompanies: any[];
  selectedCompany: Company;
  workspaces: Workspace[];
  selectedWorkspaces: Workspace[];
  sourceFields: MappingField[];
  targetFields: MappingField[];
  uploadedFiles: any[] = [];
  isEditing = false;
  templateFileUrl: string;

  form = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    company: ['', Validators.required],
    workspaceList: [[]],
    fields: [],
    selectedFile: [null, Validators.required],
  });

  constructor(
    public menuService: MenuService,
    private contractConfigService: ContractConfigService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private workspaceService: WorkspaceService,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.isEditing = this.config.data && this.config.data.id;
    this.targetFields = [];
    this.isLoading = true;
    Promise.all([
      this.companyService.GetCompanies(9999, 1, ''),
      this.contractConfigService.getAvailableFields(),
    ]).then((value) => {
      this.companies = value[0].companies;
      this.sourceFields = value[1];
      this.isLoading = false;

      if (this.isEditing) this.setContractData();
    });
  }

  setContractData() {
    this.isLoading = true;
    this.contractConfigService
      .getOne(this.config.data.id)
      .then(async (result) => {
        this.form.controls['name'].setValue(result.name);
        this.form.controls['description'].setValue(result.description);
        this.form.controls['company'].setValue(result.companyId);
        this.selectedCompany = this.companies.find((x) => x.id === result.companyId);
        this.workspaces = await this.workspaceService
          .GetAllWorkspaces(result.companyId)
          .toPromise();
        this.selectedWorkspaces = this.workspaces.filter((x) =>
          result.workspaceList.includes(x.id),
        );
        this.targetFields = this.sourceFields.filter((x) =>
          result.selectedFields.includes(x.dstName),
        );
        this.sourceFields = this.sourceFields.filter(
          (x) => !this.targetFields.map((x) => x.dstName).includes(x.dstName),
        );
        this.templateFileUrl = result.templateFileUrl;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      })
      .finally(() => (this.isLoading = false));
  }

  filterCompany(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.companies.length; i++) {
      let company = this.companies[i];
      if (company.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(company);
      }
    }

    this.filteredCompanies = filtered.sort((a, b) => this.compare(a.name.trim(), b.name.trim(), true));
  }

  onSelectCompany(value) {
    this.isLoading = true;
    this.workspaceService.GetAllWorkspaces(value.id).subscribe((result) => {
      this.workspaces = result;
      this.isLoading = false;
    });
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }

  onSelectFile(event) {
    this.form.controls['selectedFile'].setValue(event.currentFiles[0]);
  }

  onRemoveFile(event) {
    this.form.controls['selectedFile'].setValue(null);
  }

  submit() {
    this.form.markAllAsTouched();
    const workspaces = this.form.controls['workspaceList'].value;
    if (this.isEditing) {
      this.form.controls['selectedFile'].removeValidators(Validators.required);
      this.form.controls['selectedFile'].updateValueAndValidity();
    }

    if (this.form.valid) {
      if (this.isEditing) this.updateContract(workspaces);
      else this.createContract(workspaces);
    } else {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
    }
  }

  private createContract(workspaces: any) {
    const model = {
      name: this.form.controls['name'].value,
      description: this.form.controls['description'].value,
      companyId: this.form.controls['company'].value.id,
      workspaceList: workspaces ? workspaces.map((x) => x.id) : [],
      fields: this.targetFields.map((x) => x.dstName),
    } as CreateContractConfigCmd;

    this.isLoading = true;
    this.contractConfigService
      .createContractConfig(this.form.controls['selectedFile'].value, model)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Contrato criado com sucesso!',
        });
        this.ref.close({ reload: true });
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Oops! Algo deu errado.',
        });
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private updateContract(workspaces: any) {
    const id = this.config.data.id;
    console.log(id);
    const model = {
      id: this.config.data.id,
      name: this.form.controls['name'].value,
      description: this.form.controls['description'].value,
      companyId: this.form.controls['company'].value.id,
      workspaceList: workspaces ? workspaces.map((x) => x.id) : [],
      fields: this.targetFields.map((x) => x.dstName),
    } as UpdateContractConfigCmd;

    this.isLoading = true;
    this.contractConfigService
      .updateContractConfig(model)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Contrato atualizado com sucesso!',
        });
        this.ref.close({ reload: true });
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Oops! Algo deu errado.',
        });
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  downloadTemplate() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.templateFileUrl);
    link.click();
    link.remove();
  }

  closeDialog() {
    this.ref.close({ reload: false });
  }

  // * Sort Array
  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
