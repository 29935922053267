<p-toast position="top-right"></p-toast>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-panel header="Relatórios Gerados">
    <ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

    <p-table #dt [value]="controlReports" [rows]="10" [paginator]="true" [columns]="controlReports"
        [globalFilterFields]="['id','name','description']" responsiveLayout="scroll" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="pesquisar..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">Nome <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="description">Descrição <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th pSortableColumn="error">Error <p-sortIcon field="error"></p-sortIcon>
                </th>
                <th pSortableColumn="createdAt">Data de Criação <p-sortIcon field="createdAt"></p-sortIcon>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>
                    {{rowData.name}}
                </td>
                <td>
                    {{rowData.description}}
                </td>
                <td>
                    {{eReportStatusArr[rowData.status].name}}
                </td>
                <td>
                    {{rowData.error}}
                </td>
                <td>
                    {{rowData.createdAt | date:'dd/MM/yyyy - HH:mm'}}
                </td>
                <td>
                    <a [href]="rowData.fileUrl" target="_blank">
                        <i class="pi pi-download"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                No total existem {{controlReports ? controlReports.length : 0 }} Itens.
            </div>
        </ng-template>
    </p-table>
</p-panel>