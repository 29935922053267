<section class="dialog-content">
  <div class="header">
    <h2 mat-dialog-title [innerHTML]="data?.header"></h2>
    <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="onClose()"></button>
  </div>
  <mat-dialog-content>
    <p-card class="card-content">
      <form class="form-group" [formGroup]="fgPropsOfSchemaField">
        <!-- * FORM CONTROLS: FIXED PROPERTIES -->
        <fieldset class="controls-fixed">
          <!--  -->
          <!-- * RENDER: FIXED INPUTS -->
          <ng-container *ngTemplateOutlet="inputs; context: schemaFieldObj.FIXED_PROPERTIES.context"> </ng-container>
          <hr />
        </fieldset>

        <!-- * FORM CONTROLS: DYNAMIC PROPERTIES -->
        <fieldset class="controls-dynamic">
          <div class="col">
            <!--  -->
            <!-- * RENDER: SIMPLE PROPERTIES -->
            <ng-container *ngTemplateOutlet="simpleProperties; context: schemaFieldObj.DYNAMIC_PROPERTIES.context"></ng-container>

            <hr />

            <!-- * RENDER: ADVANCED PROPERTIES -->
            <ng-container *ngTemplateOutlet="advancedProperties; context: schemaFieldObj.ADVANCED_PROPERTIES"></ng-container>
            <!--  -->
          </div>
        </fieldset>
      </form>

      <br />
      <!--<pre>{{ formGroupValue() }}</pre>-->
      <br />
    </p-card>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="onClose()">Cancelar</button>
    <button mat-button cdkFocusInitial mat-dialog-close [disabled]="!fgPropsOfSchemaField.valid" (click)="onSubmit()">Confirmar</button>
  </mat-dialog-actions>
</section>

<!-- * DEFAULT TEMPLATE :>> MODAL INPUTS -->
<ng-template #inputs let-title="title" let-list="list">
  <!--  -->
  <h5 *ngIf="title">{{ title }}</h5>
  <!--  -->
  <ng-container *ngFor="let item of list; index as index">
    <ng-container [ngSwitch]="item?.type">
      <ng-container *ngSwitchCase="'string'">
        <ng-container *ngTemplateOutlet="inputText; context: { item }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <ng-container *ngTemplateOutlet="inputNumber; context: { item }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <ng-container *ngTemplateOutlet="inputCheckbox; context: { item }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> ADVANCED PROPERTY -->
<ng-template #advancedProperties let-title="context.title" let-list="value">
  <!--  -->
  <h5 *ngIf="title" [innerHTML]="title"></h5>
  <!--  -->
  <ng-container *ngFor="let item of list; index as index">
    <ng-container [ngSwitch]="item?.name">
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'enum'">
        <ng-container *ngTemplateOutlet="arrayTemplate; context: schemaFieldObj.ENUM.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'load_fields'">
        <ng-container *ngTemplateOutlet="arrayTemplate; context: schemaFieldObj.LOAD_FIELDS.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'value_key_name'">
        <ng-container *ngTemplateOutlet="arrayTemplate; context: schemaFieldObj.VALUE_KEY_NAME.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'title_map'">
        <ng-container *ngTemplateOutlet="arrayTemplate; context: schemaFieldObj.TITLE_MAP.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'triggers'">
        <ng-container *ngTemplateOutlet="triggersTemplate; context: schemaFieldObj.TRIGGERS.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="schemaFieldObj.ADVANCED_PROPERTIES.name + '-' + 'validation_messages'">
        <ng-container *ngTemplateOutlet="simpleProperties; context: schemaFieldObj.VALIDATION_MESSAGES.context"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> SIMPLE PROPERTY -->
<ng-template #simpleProperties let-data="data" let-items="items" let-title="title" let-template="template" let-subtitle="subtitle">
  <!--  -->
  <h5 *ngIf="title" [innerHTML]="title"></h5>
  <h6 *ngIf="subtitle" [innerHTML]="subtitle"></h6>
  <!--  -->
  <ng-container *ngFor="let item of items; index as index">
    <div class="content-row">
      <!--  -->
      <!-- * RENDER: DROPDOWN PROPERTIES -->
      <ng-container *ngTemplateOutlet="dropdown; context: { data: data, index: index, template: template }"></ng-container>

      <!-- * RENDER: DYNAMIC INPUTS -->
      <ng-container *ngTemplateOutlet="inputs; context: { list: [data.dropdownValues[index]] }"></ng-container>

      <!-- * RENDER: BUTTON ACTION ADD -->
      <ng-container *ngTemplateOutlet="buttonTemplate; context: { data: data.dropdownValues[index], template: template }"> </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> TRIGGERS PROPERTY -->
<ng-template #triggersTemplate let-template="template" let-items="items" let-subItems="subItems" let-subtitle="subtitle">
  <div class="template-content">
    <!--  -->
    <h6 *ngIf="subtitle" [innerHTML]="subtitle"></h6>
    <!--  -->
    <!-- * RENDER: MIN MAX INPUT -->
    <ng-container *ngTemplateOutlet="minmax; context: { title: 'Items da lista', template: template, value: items?.length }"> </ng-container>

    <ng-container *ngFor="let item of items; index as index">
      <!-- * RENDER: INPUT & SUB-LIST ITEMS -->
      <ng-container *ngIf="item?.function">
        <mat-card class="card-trigger">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>Trigger {{ index }}</mat-card-title>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngTemplateOutlet="inputText; context: { name: item.function, subtitle: 'Function' }"></ng-container>
            <ng-container
              *ngTemplateOutlet="
                arrayTemplate;
                context: { template: item?.parameters, items: item?.subItems, subtitle: 'Parameters' }
              "></ng-container>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> ARRAYS PROPERTY -->
<ng-template #arrayTemplate let-template="template" let-items="items" let-subtitle="subtitle">
  <div class="template-content">
    <!--  -->
    <h6 *ngIf="subtitle" [innerHTML]="subtitle"></h6>
    <!--  -->
    <!-- * RENDER: MIN MAX INPUT -->
    <ng-container *ngTemplateOutlet="minmax; context: { title: 'Items da lista', template: template, value: items?.length }">
    </ng-container>

    <!-- * RENDER: INPUTS -->
    <ng-container *ngFor="let item of items; index as index">
      <!--  -->
      <!-- * RENDER: SINGLE LINE -->
      <ng-container *ngIf="item?.name && !item?.value">
        <ng-container *ngTemplateOutlet="inputText; context: { item }"></ng-container>
      </ng-container>

      <!-- * RENDER: KEY AND VALUE -->
      <ng-container *ngIf="item?.value">
        <span class="prop-title">Objeto {{ index }}</span>
        <div class="title-map-fields">
          <ng-container *ngTemplateOutlet="inputText; context: { name: item?.value }"></ng-container>
          <ng-container *ngTemplateOutlet="inputText; context: { name: item?.name }"></ng-container>
        </div>
      </ng-container>
      <!--  -->
    </ng-container>
  </div>
</ng-template>

<ng-template #minmax let-title="title" let-template="template" let-value="value">
  <div class="template-content minmax">
    <p-inputNumber
      mode="decimal"
      class="p-inputtext-sm minmax"
      inputId="stacked"
      [min]="0"
      [max]="999"
      [showButtons]="true"
      [ngModel]="value"
      (onInput)="onChangeMinMax($event, template)"
      [placeholder]="title">
    </p-inputNumber>
  </div>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> CHECKBOX -->
<ng-template #inputCheckbox let-item="item">
  <div class="checkbox-group">
    <p-checkbox [name]="item?.name" [formControl]="getFormControl(item?.name)" [inputId]="item?.name" [binary]="true"></p-checkbox>
    <label [for]="item?.name">{{ setPlaceholder(item) }}</label>
  </div>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> TEXT FIELD -->
<ng-template #inputText let-item="item" let-name="name" let-subtitle="subtitle">
  <!--  -->
  <h6 *ngIf="subtitle" [innerHTML]="subtitle"></h6>
  <!--  -->
  <div class="p-inputgroup">
    <ng-template [ngIf]="item?.icon">
      <span class="p-inputgroup-addon"><i class="pi {{ item?.icon }}"></i></span>
    </ng-template>
    <input
      pInputText
      type="text"
      class="p-inputtext-sm"
      [formControl]="getFormControl(item?.name ?? name)"
      [placeholder]="setPlaceholder(item, name)" />
  </div>
</ng-template>

<!-- * DEFAULT TEMPLATE :>> NUMBER FIELD -->
<ng-template #inputNumber let-item="item">
  <div class="p-inputgroup">
    <ng-template [ngIf]="item?.icon">
      <span class="p-inputgroup-addon"><i class="pi {{ item?.icon }}"></i></span>
    </ng-template>
    <p-inputNumber
      mode="decimal"
      class="p-inputtext-sm"
      inputId="inputNumber"
      [useGrouping]="false"
      [formControl]="getFormControl(item?.name)"
      [placeholder]="setPlaceholder(item)">
    </p-inputNumber>
  </div>
</ng-template>

<!-- * AJUSTAR DROPDOWN -->
<ng-template #dropdown let-data="data" let-index="index" let-template="template">
  <p-dropdown
    class="dropdown"
    [options]="data?.dropdownOptions?.list"
    [(ngModel)]="data?.dropdownValues[index]"
    [ngModelOptions]="{ standalone: true }"
    optionLabel="label"
    [optionDisabled]="data?.dropdownValues[index]?.disabled"
    [disabled]="disableOptions(data?.dropdownValues[index])"
    (onChange)="onChangeDropdown($event, template)"
    placeholder="Selecione uma propriedade...">
    <ng-template let-item pTemplate="selectedItem">
      {{ item?.label }}
    </ng-template>
  </p-dropdown>
</ng-template>

<ng-template #buttonTemplate let-data="data" let-template="template">
  <div class="buttongroup">
    <button
      [id]="template"
      #button
      pButton
      pRipple
      [icon]="getIcon(data?.buttonAction)"
      [ngClass]="getClass(data?.buttonAction)"
      [disabled]="!getFormControl(data?.name)?.valid"
      (click)="setAction(template, data)"></button>
  </div>
</ng-template>

<button pButton pRipple (click)="showTemplate()">template</button>
