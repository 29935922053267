import _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/shared/model/company.model';
import { UserInfo } from 'src/app/shared/model/userInfo.model';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserDeletedComponent } from '../user-deleted/user-deleted.component';
import { AddUserCompanyComponent } from '../add-user-company/add-user-company.component';
import { WorkspaceService } from 'src/app/shared/services';
import { WorkspaceInfoSettings } from '../../shared/model/workspace/workspace-info-settings.model';

@Component({
  selector: 'app-user-company',
  templateUrl: './user-company.component.html',
  styleUrls: ['./user-company.component.scss']
})
export class UserCompanyComponent implements OnInit {

  //Company
  public companyId: string;
  public company: Company;

  //User
  public users: UserInfo[] = new Array<UserInfo>();
  public userToAdd: UserInfo = null;

  //public plan: Plan;

  public addingUser: boolean = false;

  constructor(
    private companyService: CompanyService,
    private activatedRouter: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private workspaceService: WorkspaceService,
    private readonly toastService: ToastService) { }

  ngOnInit() {
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
    this.LoadCompanyInfos();
  }
  private async LoadCompanyInfos() {
    this.loaderService.show();
    await this.requestLoadCompany();
    this.loaderService.hide();
  }
  //Load infos company
  public requestLoadCompany() {
    this.companyService.getOne(this.companyId).subscribe(company => {
      this.company = company;
      this.LoadUsers();
    });
  }

  //Load users company
  public async LoadUsers() {
    this.loaderService.show();
    this.userService.GetAllUserFromCompany(this.companyId).then(resp => {
      this.users = resp;
      this.workspaceService.GetAllUserWorkspaceSettings(this.companyId)
        .subscribe((resp: any) =>{
          this.users.forEach(userInfo => {
            userInfo.workspacesSettings = this.GetworkspacesSettings(resp, userInfo);
        });
        this.loaderService.hide();
      },
      error =>{
          console.log(error);
          this.loaderService.hide();
      });
    }, err =>{
      this.toastService.error("Erro ao carregar lista de Usuários");
      this.loaderService.hide();
    })
  }

  //Enable user to add
  public AddingUser() {
    this.addingUser = !this.addingUser;
  }

  //Modal add & edit user
  public onAddNewUserClick(): void {
    let dialogRef = this.dialog.open(AddUserCompanyComponent, {
      disableClose: true,      
      width: '800px',
      data: {companyId: this.companyId}
    })
    dialogRef.afterClosed().subscribe(update => {
      if(update)
        this.LoadUsers();
    });
  }

  public onEditUserClick(item : UserInfo): void {
    let user = _.cloneDeep(item);
    user.companyId = this.companyId;

    let dialogRef = this.dialog.open(AddUserCompanyComponent, {
      disableClose: true,     
      width: '800px',
      data: user
    });
    dialogRef.afterClosed().subscribe(update => {
      if(update)
        this.LoadUsers();
    });
  }

  //Modal confirmation of delete
  public onDeleteUsersClick(user : UserInfo): void {
    let dialogRef = this.dialog.open(UserDeletedComponent, {
      data: {
        companyId: this.companyId,
        name: user.name,
        userId: user.id
      }
    });
    dialogRef.afterClosed().subscribe(update => {
      if(update)
        this.LoadUsers();
    });
  }

  private GetworkspacesSettings(obj: any, userInfo : UserInfo): WorkspaceInfoSettings[] {
    let workspaceSettings : WorkspaceInfoSettings[] = obj[userInfo.id];
    return workspaceSettings;
  }

}
