<mat-card class="container-load mat-elevation-z4" *ngIf="isLoading">
  <section class="load-content">
    <app-loading></app-loading>
  </section>
</mat-card>

<section *ngIf="!isLoading">
  <div class="card__title">Dados de Exportação</div>
  <form class="card__content" [formGroup]="exportForm">
    <div class="export-content">
      <h6>
        <strong>Chave: </strong>
        <mat-form-field [floatLabel]="false">
          <input type="text" matInput formControlName="keyOne" required />
          <mat-error>Digite a chave.</mat-error>
        </mat-form-field>
      </h6>
      <h6>
        <strong>Valor: </strong>
        <mat-form-field [floatLabel]="false">
          <input type="text" matInput formControlName="keyTwo" required />
          <mat-error>Digite o Valor.</mat-error>
        </mat-form-field>
      </h6>
      <button
        mat-raised-button
        [disabled]="this.exportForm.controls.keyOne.value == '' || this.exportForm.controls.keyTwo.value == ''"
        (click)="pathExportData()">
        <i class="material-icons"> add </i>
      </button>
    </div>
  </form>
  <div style="margin: 10px">
    <div class="export-body" *ngFor="let data of this.keyArray">
      <div>
        <span
          ><strong>{{ data.type }}</strong
          >: {{ data.value }}</span
        >
      </div>
      <div>
        <i class="material-icons" (click)="deleteExportData(data.type)"> delete </i>
      </div>
    </div>
  </div>
</section>
