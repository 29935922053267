import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {SidenavService} from './shared/services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import {MediaMatcher} from '@angular/cdk/layout';
import { LoaderService } from './shared/security/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LugaRh-Adm';
  public mobileQuery: MediaQueryList;
  public isLoading: boolean = false;
  @ViewChild('snav', { static: true }) snav: MatSidenav;

  constructor(
    private loader: LoaderService,
    public sidenavService: SidenavService,
    public media: MediaMatcher)
  {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.sidenavService.isMenuOpenedEvent.subscribe(value =>
			{
				setTimeout((e) => {
						this.snav.toggle();
				},25);

			});
  }

	ngOnInit()
	{
    // Subscribing the loader status to define if the loader must be shown
    this.loader.loaderStatus.subscribe((val: boolean) => {
      setTimeout(() => { this.isLoading = val; }, 100); //Resolve problema de warning ao alterar variável em estado 'checked'.
    });
  }


  public onBackdropClick(): void
  {
    this.sidenavService.isMenuOpenedLeft = false;
  }

}
