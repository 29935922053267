<section>
  <div class="modal__close" fxFlexFill fxLayoutAlign="end none">
    <div>
      <button mat-icon-button matTooltip="Cancelar" matTooltipPosition="left" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div class="modal__content">
    <h3 class="modal__title"  [innerHtml]="fullSchema.title"></h3>
    <h6 class="modal__subtitle" [innerHtml]="fullSchema.subTitle"></h6>

    <div class="schema__form">
      <json-schema-form #form [form]="fullSchema"
      [shareWorkspaceId]="workspaceId" 
      [currentCompanyId]="companyId" 
      framework="material-design" (onSubmit)="onSubmit($event)"
        (validForm)="onValidFormChange($event)">
      </json-schema-form>
    </div>

  </div>
</section>

