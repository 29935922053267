import { AbstractControl } from '@angular/forms';


export function ValidateCellphone(control: AbstractControl): any
{
    const regex = /\([1-9]{2}\)\s?9\d{4}-\d{4}/g 

    if(!regex.test(control.value)){
        return {cellphone: true}
    }
    return null;
}