<section fxLayout="column">
  <div class="container" fxLayoutAlign="center none">
    <div class="form__area">

      <h4>Cadastre uma nova empresa</h4>
      <form fxLayout="row wrap" [formGroup]="fgroup" fxLayoutAlign="center center" (submit)="submit()">
        
        <!--Empresa-->
        <mat-form-field fxFlexFill  appearance="fill">
          <mat-label>Empresa</mat-label>
          <input  matInput 
              placeholder="Empresa" 
              formControlName="companyName"
              autocomplete="off"  />
         
          <mat-error *ngIf="fgroup.get('companyName').errors?.required">
            o Nome da empresa é obrigatório.
          </mat-error>

          <mat-error *ngIf="fgroup.get('companyName').errors?.minlength">
            O nome da empresa deve ter no mínimo {{fgroup.get('companyName').errors?.minlength.requiredLength}} caracteres.
          </mat-error>

          <mat-error *ngIf="fgroup.get('companyName').errors?.maxlength">
            O nome da empresa deve ter no máximo {{fgroup.get('companyName').errors?.maxlength.requiredLength}} caracteres.
          </mat-error>
        </mat-form-field>

        <!--Cnpj-->
        <mat-form-field fxFlexFill  appearance="fill">
          <mat-label>CNPJ</mat-label>
          <input  matInput 
                  placeholder="CNPJ"
                  formControlName="cnpj"
                  [textMask]="{mask: maskCNPJ, keepCharPositions: true}" 
                  autocomplete="off" />

            <mat-error *ngIf="fgroup.get('cnpj').errors?.required">
              O CNPJ é obrigatório.
            </mat-error>

            <mat-error *ngIf="fgroup.get('cnpj').errors?.cnpj && !fgroup.get('cnpj').errors?.required">
              CNPJ inválido.
            </mat-error>

            <mat-error *ngIf="fgroup.get('cnpj').errors?.cnpjTaken">
              CNPJ já em uso por outra empresa.
            </mat-error>

        </mat-form-field>

        <!--Email-->
        <mat-form-field fxFlexFill  appearance="fill">
          <mat-label>Email Corporativo</mat-label>

          <input  matInput 
              placeholder="Email Corporativo" 
              formControlName="corporativeEmail" 
              autocomplete="off" 
              (blur)="onBlurMail($event.target.value);"
              />

          <mat-error *ngIf="fgroup.get('corporativeEmail').errors?.required">
            O email corporativo é obrigatório.
          </mat-error>

          <mat-error *ngIf="fgroup.get('corporativeEmail').errors?.email && !fgroup.get('corporativeEmail').errors?.required">
            Email inválido
          </mat-error>

          <mat-error *ngIf="fgroup.get('corporativeEmail').errors?.emailAlreadyExists">
            Já existe um usuário cadastrado com este e-mail.
          </mat-error>
        </mat-form-field>

        <!--Nome-->
        <mat-form-field fxFlexFill appearance="fill">
          <mat-label>Nome completo</mat-label>
          <input matInput 
                placeholder="Nome completo" 
                formControlName="fullName" 
                autocomplete="off" />

          <mat-error *ngIf="fgroup.get('fullName').errors?.required">
             Nome completo é obrigatório.
          </mat-error>

          <mat-error *ngIf="fgroup.get('fullName').errors?.minlength">
            O nome completo deve ter no mínimo {{fgroup.get('fullName').errors?.minlength.requiredLength}} caracteres.
          </mat-error>

          <mat-error *ngIf="fgroup.get('fullName').errors?.maxlength">
            O nome completo deve ter no máximo {{fgroup.get('fullName').errors?.maxlength.requiredLength}} caracteres.
          </mat-error>

          <mat-error *ngIf="(fgroup.get('fullName').errors?.fullname || fgroup.get('fullName').errors?.onlyletters )
                           && !fgroup.get('fullName').errors?.minlength && !fgroup.get('fullName').errors?.maxlength">
            Nome completo inválido.
          </mat-error>
        </mat-form-field>
        
        <!--Celular-->
        <mat-form-field fxFlexFill appearance="fill">
          <mat-label>Telefone Celular</mat-label>
          <input matInput 
                 placeholder="Telefone Celular"
                 formControlName="cellphone"
                 [textMask]="{mask: maskTelefone, keepCharPositions: true}" autocomplete="off" />
          
          <mat-error *ngIf="fgroup.get('cellphone').errors?.required">
            Celular é obrigatório.
          </mat-error>

          <mat-error *ngIf="fgroup.get('cellphone').errors?.cellphone && !fgroup.get('cellphone').errors?.required">
            Celular inválido.
          </mat-error>
        </mat-form-field>

        <!--Numero de funcionarios-->
        <mat-form-field fxFlexFill  appearance="fill">
          <mat-label>Número de funcionários</mat-label>
          <input  matInput 
                  placeholder="Número de funcionários" 
                  formControlName="employeesAmount"
                  autocomplete="off" />

          <mat-error *ngIf="fgroup.get('employeesAmount').errors?.required">
            O número de funcionários é obrigatório.
          </mat-error>

          <mat-error *ngIf="fgroup.get('employeesAmount').errors?.pattern">
            Valor inválido para número de funcionários.
          </mat-error>

          <mat-error *ngIf="fgroup.get('employeesAmount').errors?.max && !fgroup.get('employeesAmount').errors?.pattern">
            O valor máximo de funcionários é de 99999.
          </mat-error>

        </mat-form-field>
        
        <!--Site Empresa-->
        <mat-form-field fxFlexFill appearance="fill">
          <mat-label>Site da Empresa</mat-label>
          <input matInput 
                 formControlName="companyWebSite" 
                 placeholder="Site da Empresa"
                 autocomplete="off" />
          
          <mat-error *ngIf="fgroup.get('companyWebSite').errors?.pattern">
            Site inválido
          </mat-error>
        </mat-form-field>

        <button mat-raised-button 
          color="primary"
          [disabled]="fgroup.invalid || fgroup.pending" 
          type="submit">
            CADASTRAR
        </button>
      </form>
    </div>
  </div>

</section>