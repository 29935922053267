import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { DigitalSignatureConfigWorkspace } from 'src/app/shared/model/digital-signature-config-workspace';
import { ToastService } from 'src/app/shared/services';
import { DigitalSignatureConfigService } from 'src/app/shared/services/digital-signature-config.service';

@Component({
  selector: 'setup-digital-signature',
  templateUrl: './setup-digital-signature.component.html',
  styleUrls: ['./setup-digital-signature.component.scss'],
  providers: [DialogService],
})
export class SetupDigitalSignatureComponent implements OnInit {
  public isLoading: boolean;
  public enableForCompany: boolean;
  public workspacesConfig: DigitalSignatureConfigWorkspace[];
  public columns = [
    { field: 'name', label: 'Workspace' },
    { field: 'enabled', label: 'Habilitar Assinatura Digital' },
    { field: 'isUploadEnabled', label: 'Habilitar Documento via Upload' },
  ];

  constructor(
    private digitalSignatureConfigService: DigitalSignatureConfigService,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadCompanyConfig();
  }

  async loadCompanyConfig() {
    this.isLoading = true;
    this.workspacesConfig = await this.digitalSignatureConfigService.getConfigForCompany(this.config.data.companyId);
    this.isLoading = false;
  }

  public toggleForOneWorkspace(event: any, workspaceId: string) {
    const enable = event.checked;
    this.isLoading = true;
    this.digitalSignatureConfigService
      .toggleForOneWorkspace(this.config.data.companyId, workspaceId, enable)
      .then(async () => await this.loadCompanyConfig())
      .catch((error) => {
        this.toastService.error('Oops! Algo deu errado.');
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
        this.toastService.success('Configuração alterada com sucesso!');
      });
  }

  public toggleEnableUpload$(event: any, workspaceId: string) {
    const [companyId, enable] = [this.config?.data?.companyId, event?.checked];
    this.digitalSignatureConfigService
      .toggleEnableUpload$(companyId, workspaceId, enable)
      .pipe(
        take(1),
        tap(() => (this.isLoading = true)),
        switchMap(() => {
          return this.digitalSignatureConfigService.getConfigForCompany$(companyId).pipe(
            tap((response) => {
              this.workspacesConfig = response;
              this.toastService.success('Configuração alterada com sucesso!');
            }),
            catchError((err) => {
              console.log('toggleEnableUpload$ err :>> ', err);
              this.toastService.error('Oops! Algo deu errado.');
              return EMPTY;
            }),
          );
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public enableForAllWorkspaces() {
    this.toggleForAllWorkspaces(true);
  }

  public disableForAllWorkspaces() {
    this.toggleForAllWorkspaces(false);
  }

  private toggleForAllWorkspaces(enable: boolean) {
    this.isLoading = true;
    this.digitalSignatureConfigService
      .toggleForAllWorkspaces(this.config.data.companyId, enable)
      .then(async () => await this.loadCompanyConfig())
      .catch((error) => {
        this.toastService.error('Oops! Algo deu errado.');
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
        this.toastService.success('Configuração alterada com sucesso!');
      });
  }
}
