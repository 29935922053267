import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AddWorkspaceCmd } from '../model/addWorkspaceCmd';
import { UpdateUserWorkspaceCmd } from '../model/workspace/update-user-workspace-cmd.model';
import { Workspace } from '../model/workspace/workspace.model';
import { LoaderService } from '../security/loader.service';

@Injectable()
export class WorkspaceService {
  constructor(private http: HttpClient, private loader: LoaderService) {}

  /**
   * GET: get Workspaces from company
   */
  public GetAllWorkspaces(companyId: string): Observable<Workspace[]> {
    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);

    return this.http.get(environment.apiEndpoint + '/Workspace', { headers: heads }).pipe(
      map((resp: Workspace[]) => {
        this.loader.hide();
        return resp;
      }),
      catchError((err: any) => {
        this.loader.hide();
        console.error('Something get wrong when GetAllWorkspaces on WorkspaceService =>', err);
        return throwError(err);
      }),
    );
  }

  /**
   * GET: get all Users Workspaces Settings from company
   */
  public GetAllUserWorkspaceSettings(companyId: string): Observable<Workspace[]> {
    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return this.http.get(environment.apiEndpoint + '/Workspace/GetAllUserWorkspaceSettings', { headers: heads }).pipe(
      map((resp: any) => {
        this.loader.hide();
        return resp;
      }),
      catchError((err: any) => {
        this.loader.hide();
        console.error('Something get wrong when GetAllUserWorkspaceSettings: ', err);
        return throwError(err);
      }),
    );
  }

  /**
   * POST: Updates workspaces that the user has access to
   */
  public UpdateUserWorkspace(cmd: UpdateUserWorkspaceCmd, companyId: string): Observable<any> {
    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return this.http.post(environment.apiEndpoint + '/Workspace/UpdateUserWorkspace', cmd, { headers: heads }).pipe(
      map((resp: boolean) => {
        this.loader.hide();
        return resp;
      }),
      catchError((err: any) => {
        console.error('Something get wrong when UpdateUserWorkspace on WorkspaceService =>', err);
        this.loader.hide();
        return throwError(err);
      }),
    );
  }

  public IsWorkspaceAdmin(userId: string, companyId: string) {
    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return this.http.get(environment.apiEndpoint + '/Workspace/IsWorkspaceAdmin/' + userId, { headers: heads }).pipe(
      map((resp: boolean) => {
        this.loader.hide();
        return resp;
      }),
      catchError((err: any) => {
        this.loader.hide();
        console.error('Something get wrong when IsWorkspaceAdmin on WorkspaceService =>', err);
        return throwError(err);
      }),
    );
  }

  public UpdateUserWorkspaceAdmin(userId: string, companyId: string, isAdmin: boolean) {
    this.loader.show();
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return this.http
      .get(environment.apiEndpoint + '/Workspace/UpdateUserWorkspaceAdmin/' + userId + '/' + isAdmin, { headers: heads })
      .pipe(
        map((resp: boolean) => {
          this.loader.hide();
          return resp;
        }),
        catchError((err: any) => {
          this.loader.hide();
          console.error('Something get wrong when UpdateUserWorkspaceAdmin on WorkspaceService =>', err);
          return throwError(err);
        }),
      );
  }

  /**
   * GET: gets a Workspace by Id
   */

  public GetWorkspaceById(id: string): Observable<Workspace> {
    return this.http.get(environment.apiEndpoint + '/Workspace/' + id).pipe(
      map((resp: Workspace) => {
        return resp;
      }),
      catchError((err: any) => {
        console.error('Something get wrong when GetWorkspaceById on WorkspaceService =>', err);
        return throwError(err);
      }),
    );
  }

  /**
   * PATCH: a Workspace by Id
   */
  public patchWorkspaceData(companyId: string, workspaceId: string, name: string, cnpj: string) {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    // heads = heads.set('lugarh-workspace-id', workspaceId);
    let url = `${environment.apiEndpoint}/Workspace/${workspaceId}`;
    return this.http.patch(url, { name: `${name}`, cnpj: `${cnpj}` }, { headers: heads });
  }

  public pathAddressData(
    companyId: string,
    workspaceId: string,
    zipCode: string,
    streetName: string,
    number: string,
    complement: string,
    city: string,
    neighborhood: string,
    streetType: string,
  ) {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    let url = `${environment.apiEndpoint}/Workspace/Address/${workspaceId}`;
    return this.http.patch(
      url,
      {
        zipCode: `${zipCode}`,
        streetName: `${streetName}`,
        number: `${number}`,
        complement: `${complement}`,
        city: `${city}`,
        neighborhood: `${neighborhood}`,
        streetType: `${streetType}`,
      },
      { headers: heads },
    );
  }

  public pathExportData(companyId: string, workspaceId: string, key: string, value: string) {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    let url = `${environment.apiEndpoint}/Workspace/ExportData/${workspaceId}`;
    return this.http.patch(url, { key: `${key}`, value: `${value}` }, { headers: heads });
  }

  public deleteExportData(companyId: string, workspaceId: string, key: string) {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    let url = `${environment.apiEndpoint}/Workspace/ExportData/${workspaceId}/${key}`;
    return this.http.delete(url, { headers: heads });
  }

  /**
   * PATCH: a Workspace users
   *
   *
   */

  public patchWorkspaceUsers(companyId: string, cmd: AddWorkspaceCmd): Observable<any> {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    let url = `${environment.apiEndpoint}/Workspace/Users`;
    return this.http.patch(url, cmd, { headers: heads, observe: 'response' });
  }

  public putWokspaceIntegration(obj: { CS: string; CNPJ: string }, workspaceId: string) {
    const url = `${environment?.apiEndpoint}/Company/Integration/${workspaceId}/WebFopag`
    return this.http.put(url, obj);
  }
}
