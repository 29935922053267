import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './share-remove.component.html',
  styleUrls: ['./share-remove.component.scss']
})
export class ShareRemoveComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShareRemoveComponent>) { }

  ngOnInit() {
  }

  close(): void
  {
    this.dialogRef.close(false);
  }

  remove()
  {
    this.dialogRef.close(true);
  }

}
