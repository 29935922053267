import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../shared/services/sidenav.service';
import { Router } from '@angular/router';
import { MenuService } from '../shared/services/menu.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    constructor(public sidenavService: SidenavService, private router: Router, public menuSrv: MenuService) { }

    public get MenuOpened(): boolean {
        return this.sidenavService.isMenuOpenedLeft;
    }

    public openMenu() {
        this.sidenavService.isMenuOpenedLeft = true;
    }

    ngOnInit() {
    }

    public goEnterprise(): void {
        this.router.navigate(['/empresas']);
    }

    public goSettings(): void {
        this.router.navigate(['/settings'])
    }

    public goSchemas(): void {
        this.router.navigate(['/schemas'])
    }

    public goContracts(): void {
        this.router.navigate(['/contratos'])
    }

    public goShareReport(): void {
        this.router.navigate(['/share-report'])
    }

    public goGeneratedReport(): void {
        this.router.navigate(['/generated-report'])
    }
}
