import { Component, OnInit } from '@angular/core';
import { FormsManagementService } from 'src/app/app-company/forms-management/forms-management.service';
import { ListSearchService } from '../../shared/services/list-search.service';

@Component({
  selector: 'list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {

  constructor(public listSearchService: ListSearchService) { }

  public value: string = ''; //input value

  ngOnInit() {

  }

  public onClose(): void {
    this.listSearchService.isMobileSearchActive = false;
  }

  public onInputKeyDown(evt: KeyboardEvent): void {
    if (evt.key === "Enter") {
      this.listSearchService.filterValue = this.value;
      this.listSearchService.LoadCompanies();
    }
  }

  public onCleanInput(): void {
    this.value = "";
    this.listSearchService.filterValue = "";
    this.listSearchService.LoadCompanies();
  }


}
