<p-toast position="top-right"></p-toast>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Importar arquivo .csv" [(visible)]="showDialogUpload" (onHide)="cancelUpload()">
  <p-message
    severity="warn"
    text="Atenção! a tabela a ser importada deverá conter obrigatoriamente os campos da tabela de exemplo abaixo:"
    styleClass="p-mr-2"></p-message>
  <br />
  <br />
  <span><strong>OBS:</strong> Colunas adicionais poderão ser incluídas na planilha, desde que não sejam repetidas.</span>
  <br />
  <span><strong>Exemplo de tabela .csv:</strong></span>
  <p-table responsiveLayout="scroll" [value]="tableExampleData" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header">
      <tr>
        <th>CompanyId</th>
        <th>WorkspaceId</th>
        <th>Source</th>
        <th>Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tableExample>
      <tr>
        <td>{{ tableExample.companyIdText }}</td>
        <td>{{ tableExample.workspaceIdText }}</td>
        <td>{{ tableExample.sourceText }}</td>
        <td>{{ tableExample.nameText }}</td>
      </tr>
    </ng-template>
  </p-table>
  <br />

  <div class="p-field-radiobutton">
    <p-radioButton inputId="optInsert" name="importOption" [value]="eImportOptions.Insert" [(ngModel)]="importOptions"> </p-radioButton>
    <label for="optInsert">Importar os registros da planilha</label>
  </div>
  <br />
  <div class="p-field-radiobutton">
    <p-radioButton inputId="optDeleteInsert" name="importOption" [value]="eImportOptions.DeleteInsert" [(ngModel)]="importOptions">
    </p-radioButton>
    <label class="label-opt-delete-insert" for="optDeleteInsert"
      >Deletar registros de mesmo grupo (Company, Workspace e Source) e importar os registros da planilha</label
    >
  </div>

  <br />
  <p-fileUpload
    #fileUpload
    mode="basic"
    accept=".csv"
    [auto]="false"
    customUpload="true"
    (uploadHandler)="uploadCsvFile($event)"
    [maxFileSize]="10000000"
    label="Importar arquivo"
    chooseLabel="Importar arquivo"
    class="p-mr-2 p-d-inline-block">
  </p-fileUpload>
</p-dialog>

<p-panel header="Gerenciamento de Schemas">
  <ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

  <div class="card">
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <button pButton pRipple label="Adicionar" (click)="notImplemented()" icon="pi pi-plus" class="p-button-success p-mr-2"></button>
      </ng-template>

      <ng-template pTemplate="right">
        <button pButton pRipple label="Importar" (click)="showUploadItem()" icon="pi pi-upload" class="p-button-help"></button>
      </ng-template>
    </p-toolbar>

    <p-table
      #dt
      [value]="autocompleteItens"
      [first]="first"
      [columns]="cols"
      [globalFilterFields]="['id', 'companyId', 'workspaceId', 'name', 'active', 'source']"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id">
      <ng-template pTemplate="caption">
        <form [formGroup]="fgAutocompleteItemV2$ | async">
          <div class="p-d-flex p-ai-center p-jc-between">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" formControlName="controlSearch" placeholder="pesquisar..." />
            </span>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              (click)="notImplemented()"
              class="p-button-rounded p-button-success p-mr-2"></button>
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              (click)="confirmDeleteItem(rowData.id)"
              class="p-button-rounded p-button-danger"></button>
          </td>

          <td *ngFor="let col of columns">
            {{ col.field.includes('.') ? rowData[col.field.split('.')[0]][col.field.split('.')[1]] : rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      #paginator
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rows]="10"
      [totalRecords]="totalRecords$ | async"
      [showCurrentPageReport]="true"
      (onPageChange)="onChangePage($event)"></p-paginator>
  </div>
</p-panel>
