import { map, startWith } from 'rxjs/operators';
import { Workspace } from './../../../shared/model/workspace/workspace.model';
import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/shared/model/userInfo.model';
import { ToastService, UserService, WorkspaceService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AddWorkspaceCmd } from 'src/app/shared/model/addWorkspaceCmd';
import { LoaderService } from 'src/app/shared/security/loader.service';

@Component({
  selector: 'app-users-workspace-company',
  templateUrl: './users-workspace-company.component.html',
  styleUrls: ['./users-workspace-company.component.scss'],
})
export class UsersWorkspaceCompanyComponent implements OnInit {
  //*Vars
  // @Input('workspace')
  public workspace: Workspace;
  public users: Array<UserInfo>;
  public auxWorkspace: Workspace;
  public companyId: string;
  public workspaceId: string;
  public usersByWorkspace: FormGroup;
  public filteredOptions: Observable<Array<UserInfo>>;
  public usersInCompany = new FormControl();
  public usersNotSelected: UserInfo[] = [];
  public usersSelected: UserInfo[] = [];
  public value = 'Clear me';
  public selectable = true;
  isLoading: boolean;
  //*Fim de vars

  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    private activatedRouter: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private toasterService: ToastService,
  ) { }

  ngOnInit(): void {
    setTimeout(async () => {
      this.initWorkspaceId();
      await this.getWorkspaceById();
      await this.ConfigureUserList();
      this.filteredOptions = this.usersInCompany.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.UserNotWorkspace)),
      );
    });
  }

  //Pega id's do workspace e company
  public initWorkspaceId() {
    this.activatedRouter.params.subscribe((params) => (this.workspaceId = params.workId));
    this.activatedRouter.params.subscribe((params) => (this.companyId = params.id));
  }

  //Get no workspace
  public async getWorkspaceById() {
    this.isLoading = true;
    await this.workspaceService
      .GetWorkspaceById(this.workspaceId)
      .toPromise()
      .then((result) => {
        this.workspace = result;       
      });
    this.isLoading = false;
  }

  private _filter(name: string): UserInfo[] {
    const filterValue = name.toLowerCase();
    return this.UserNotWorkspace.filter((users) => users.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private async ConfigureUserList() {
    this.isLoading = true;
    await this.userService
      .GetAllUserFromCompany(this.companyId)
      .then((result) => {
        let usersWorkspace: string[] = [];

        if (this.workspace) {
          usersWorkspace = Object.keys(this.workspace.users);
         
          if (usersWorkspace != null && usersWorkspace.length > 0) {
            this.usersSelected = result.filter((user) => usersWorkspace.indexOf(user.id) > -1);
            this.usersNotSelected = result.filter((user) => usersWorkspace.indexOf(user.id) === -1);
          } else this.usersNotSelected = result;
        }
        this.usersInCompany.setValue('');
      })
      .catch((err) => {
        console.error('Error on ConfigureUserList', err);
      });
    this.isLoading = false;
  }

  public AddUserToList(user) {
    const index = this.usersNotSelected.indexOf(user);

    if (index >= 0) {
      this.usersInCompany.setValue('');
      this.usersNotSelected.splice(index, 1);
      this.usersSelected.push(user);
    } else {
      alert('User já incluso no workspace.');
      this.usersInCompany.setValue('');
    }
  }

  remove(user: UserInfo): void {
    const index = this.usersSelected.indexOf(user);

    if (index >= 0) {
      this.usersSelected.splice(index, 1);
      this.usersNotSelected.push(user);
    }
  }

  public async editWorkspace() {
    this.isLoading = true;
    this.workspaceService
      .patchWorkspaceUsers(this.companyId, this.addUsersWorkspace)
      .toPromise()
      .then(async (result) => {
        if (result.status == 200) {
          this.toasterService.success('Usuários editados com sucesso!');
          console.log("result", result);
          this.getWorkspaceById();
          await setTimeout(async () => {            
            await this.ConfigureUserList();
          }, 4000);
        }        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Get's
  public get UserNotWorkspace(): UserInfo[] {
    return this.usersNotSelected;
  }

  public get UserInWorkspace(): UserInfo[] {
    return this.usersSelected;
  }

  public get actualUserInfo(): UserInfo {
    return this.userService.getUserInfo;
  }

  private get addUsersWorkspace(): AddWorkspaceCmd {
    let cmd: AddWorkspaceCmd = new AddWorkspaceCmd();
    cmd.workspaceId = this.workspaceId;
    cmd.usersIds = this.GenerateUserIdList();
    return cmd;
  }

  private GenerateUserIdList(): string[] {
    let result: string[] = [];
    this.UserInWorkspace.forEach((userInfo) => {
      result.push(userInfo.id);
    });
    return result;
  }
}
