<section fxLayout="row wrap" >
  <header fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
    <div class="header__content" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between">
      <div fxLayoutAlign="center center">
          <!-- <i class="material-icons">menu</i> -->
          <a fxFlex fxLayoutAlign="none center" class="menu__icon"><i class="material-icons" (click)="onSideNavClick()" >menu</i></a>
          <a routerLink="/"><p class="logo__text">LUGARH-ADM</p></a>
          <a routerLink="/"><img src="assets/lugarh_logo.png" /></a>
      </div>

      <!-- Search -->
      <section  class="search__menu__header" #searchInput *ngIf="showSearch == true"
              [class.is__mobile__search__menu__header]="listSearchService.isMobileSearchActive"
               fxFlex="60" fxHide.xs fxHide.sm>
        <list-search></list-search>
      </section>


      <!-- button search -->
      <div class="btn__seacrh" fxHide.md fxHide.lg fxHide.xl *ngIf="showSearch == true" (click)="onBtnSearchClick()"> 
        <i class="material-icons">search</i>
      </div>

      <!-- Option menu header -->
      <div class="option__menu__header" fxLayout="row" fxLayoutAlign="none center" *ngIf="!listSearchService.isMobileSearchActive">
        <!-- ProfileImage -->
        <div (click)="this.menuService.openMenu()">
          <div fxFlex fxLayout="column" fxLayoutAlign="center end">
            <div fxHide.xs fxHide.sm class="user__name"  >
              <!-- <h6 class="truncate" ></h6> -->
              <h6 *ngIf="userService.getUserInfo != undefined">{{userService.getUserInfo.name}}</h6>
            </div>              
          </div>
          <div>
            <div class="user__profile" [ngStyle]="{ 'background-image': 'url(' + profileImage + ')'}">
            </div>
          </div>
        </div>

        <user-menu></user-menu>
      </div>
    </div>
  </header>
</section>
