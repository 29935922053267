import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfig, DialogConfigData } from 'src/app/shared/model/dialog-config.model';
import { WorkspaceService } from 'src/app/shared/services';
import { ModalViewFormComponent } from '../modal-view-form/modal-view-form.component';


@Component({
  selector: 'app-select-workspace',
  templateUrl: './modal-select-workspace.component.html',
  styleUrls: ['./modal-select-workspace.component.scss'],
})
export class ModalSelectWorkspaceComponent implements OnInit {
  public companyId: any;
  public selectedWorkspace: any;
  public workspaces: any[];
  public fullSchema: any;
  
  constructor(
    private workspaceService: WorkspaceService,
    public dialogRef: MatDialogRef<ModalSelectWorkspaceComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfigData,
  ) {}

  ngOnInit(): void {
    this.companyId = this.data.companyId;
    this.fullSchema = this.data.fullSchema;
    this.workspaceService.GetAllWorkspaces(this.data.companyId).toPromise()
    .then((res) => {
      this.workspaces = res;
    })
    .catch((err) => console.log(err));
  }

  public openModalViewForm() {
    const dialogConfig = new DialogConfig<DialogConfigData>({
      data: {
        fullSchema: this.fullSchema,
        companyId: this.companyId,
        workspaceId: this.selectedWorkspace?.id
      },
      width: '50vw',
      height: '80vh',
    });

    this.dialogRef.close();
    this._dialog.open(ModalViewFormComponent, dialogConfig);
  }
}
