<!-- page__header -->
<section class="page__header is-primary">
    <!-- page__heading -->
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center"  class="page__heading">
        <h3 fxFlex="100" class="page__title">Gerenciar Administradores</h3>
        <h6 fxFlex="100" class="page__subtitle">Adicione os usuários para o LugarhADM</h6>
      </div>
</section>

<!-- page__content -->
<section class="page__content">
<div class="container__mobile">
  <div class="config__section">

    <div class="item" (click)="show();">
      <div class="item__header" fxLayout="row" fxLayoutAlign="start center">
        <button color="primary" mat-icon-button>
            <i class="material-icons">add</i>
        </button>
        <p class="adc__user">Adicionar um novo usuário</p>
      </div>
    </div>

    <div class="item is-user" [ngClass]="{'expanded': companyUser.isExpanded}" *ngFor="let companyUser of companyUsers">
      <!-- Item header  -->
      <div class="item__header" fxLayout="row" fxLayoutAlign="start center" (click)="companyUser.isExpanded = !companyUser.isExpanded">
        <div class="user__profile" *ngIf="companyUser.profileImage != undefined && !companyUser.isExpanded" [ngStyle]="{ 'background-image': 'url(' + companyUser.profileImage + ')'}"></div>
        <div class="user__profile" *ngIf="companyUser.profileImage == undefined && !companyUser.isExpanded" [ngStyle]="{ 'background-image': 'url(' + profileImage + ')'}"></div>
        <div fxFlex>
          <h6 class="user__name" *ngIf="companyUser.isExpanded">Informações do contato</h6>
          <h6 class="user__name" [innerHtml]="companyUser.name" *ngIf="!companyUser.isExpanded"></h6>
          <p>
            <span>Administrador</span>
          </p>
        </div>
        <div><i class="material-icons">keyboard_arrow_down</i></div>
      </div>
      <!-- Item content  -->
      <div class="item__content">
        <!-- Info row  -->
        <div class="user__info" fxFlex="90">
          <div fxFlex="1" class="user__info__icon"><i class="material-icons">account_circle</i></div>
          <div fxFlex="99" class="user__info__data">
            <p [innerHtml]="companyUser.name"></p>
            <p [innerHtml]="companyUser.id"></p>
            <p [innerHtml]="companyUser.cellphone"></p>
            <p [innerHtml]="companyUser.email"></p>
          </div>
        </div>

        <div fxFlex="10" fxLayoutAlign="end end" class="user__actions">
            <button (click)="onDeleteUserClicked(companyUser.id)" mat-button mat-raised-button class="is-button__outline" color="danger">
                <i class="material-icons">delete_forever</i>
                  Excluir
            </button>
        </div>

      </div>
    </div>
    
  </div>
</div>
  <mat-paginator 
    [length]="total"  
    [pageSize]="pageSize" 
    [pageIndex]="page"  
    [pageSizeOptions]="[10, 20, 30, 50]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</section>