import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { debounceTime, switchMap, map, first } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({ providedIn: 'root'})
export class EmailAlreadyTakenValidatorService
{
    constructor(private readonly userService: UserService)
    {}

    checkIfEmailIsTaken()
    {
        return (control: AbstractControl) => 
        {
            return control
                  .valueChanges
                  .pipe(debounceTime(300))
                  .pipe(switchMap(userEmail => this.userService.UserExists(userEmail) ))
                  .pipe(map(user => user == null ?  null : { emailAlreadyExists: true }))
                  .pipe(first());
        }
    }
}