<section *ngIf="company == undefined || isLoading" class="content__holder">
  <div class="card form__content" fxLayout="row wrap" fxLayoutAlign="center center">
    <app-loading></app-loading>
  </div>
</section>

<section *ngIf="company != undefined && !isLoading" class="card form__content">

  <div class="card__title">
    Integrações
  </div>

  <!--Does not contain Integration-->
  <div class="card__content" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="!company.hasIntegration && !editingIntegration">
    <h6>Esta empresa não contem integrações.</h6>
    <button mat-raised-button class="is-button__outline" color="accent" (click)="onEditingIntegration()">
      Adicionar nova Integração
    </button>
  </div>
  <div class=" content-cards">
    <!--Contain Integration-->
    <div class="card" *ngIf="hasWebFopagIntegration && !editingIntegration">
      <div class="card__title">
        <h6 class="card__title"><strong>WebFoPag</strong>
          <a (click)="onEditingIntegration();" class="icon__right">
            <i class="material-icons">edit</i>
          </a>
        </h6>
      </div>
      <div class="card__content">
        <!--Integrations-->
        <div>
          <h6><strong>CS: </strong>
            <span [innerHtml]="actualIntegration.CS"></span>
          </h6>

          <h6><strong>CNPJ: </strong>
            <span [innerHtml]="actualIntegration.CNPJ"></span>
          </h6>

        </div>
      </div>
    </div>

    <!--Integration Form-->
    <div class="card" *ngIf="editingIntegration">
      <form class="card__content" [formGroup]="integrationForm">

        <h6><strong>Tipo: </strong>
          <mat-form-field [floatLabel]="false">
            <mat-select matNativeControl formControlName="type" required>
              <mat-option value="WebFopag">WebFopag</mat-option>
            </mat-select>
            <mat-error>Digite o CS.</mat-error>
          </mat-form-field>
        </h6>

        <h6><strong>CS: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput [(ngModel)]="actualIntegration.CS" formControlName="cs" required>
            <mat-error>Digite o CS.</mat-error>
          </mat-form-field>
        </h6>

        <h6><strong>CNPJ: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput [(ngModel)]="actualIntegration.CNPJ" [textMask]="{mask: maskCNPJ}"
              formControlName="cnpj" required>
            <mat-error>{{getCnpjErrorMessage}}</mat-error>
          </mat-form-field>
        </h6>

        <div fxLayoutAlign="end">
          <button mat-raised-button class="is-button__outline md__button__outline" (click)="onEditingIntegration();"
            style="margin-right: 16px;">
            <span *ngIf="!isInProgress">Cancelar</span>
          </button>
          <button mat-raised-button class="is-button__outline" color="accent" (click)="editIntegration();"
            [ngClass]="{'in-progress': isInProgress,  'is-disable': !isFormValid || isCnpjLoading}">
            <span *ngIf="!isInProgress">Salvar</span>
            <span *ngIf="isInProgress">Salvando...</span>
          </button>
        </div>

      </form>
    </div>

    <!--Export data-->
    <div class="card">
      <form class="card__content" [formGroup]="exportForm">
        <div class="card__title">
          Dados de exportação
        </div>
        <div class="export-content">
          <h6><strong>Chave: </strong>
            <mat-form-field [floatLabel]="false">
              <input type="text" matInput [(ngModel)]="actualExportData.keyOne" formControlName="keyOne" required>
              <mat-error>Digite a chave.</mat-error>
            </mat-form-field>
          </h6>
          <h6><strong>Valor: </strong>
            <mat-form-field [floatLabel]="false">
              <input type="text" matInput [(ngModel)]="actualExportData.keyTwo" formControlName="keyTwo" required>
              <mat-error>Digite o Valor.</mat-error>
            </mat-form-field>
          </h6>
          <button mat-raised-button
            [disabled]="this.exportForm.controls.keyOne.value == '' ||  this.exportForm.controls.keyTwo.value == ''"
            (click)="pathExportData()">
            <i class="material-icons">
              add
            </i>
          </button>
        </div>
      </form>
      <!--*ngFor="let data of this.reserveValue"-->
      <div class="export-body" *ngFor="let data of this.reserveValue">
        <div>
          <span><strong>{{data.type}}</strong> : {{data.value}}</span>
        </div>
        <div>
          <!-- <i class="material-icons">
            edit
          </i> -->
          <i class="material-icons" style="color: red; cursor: pointer;" (click)="deleteExportData(data.type)">
            delete
          </i>
        </div>
      </div>
    </div>
  </div>
</section>