export const environment = {
  production: true,

  //Api
  apiEndpoint: 'https://api.lugarh.com.br',

  //empresa Url
  empresa_url: 'https://empresa.lugarh.com.br/',

  /** Identity Server Configuration */
  IdentityServerConfig: {
    acr_values: 'tenant:lugarh-adm',
    authority: 'https://id.bne.com.br/',
    redirect_url: 'http://adm.lugarh.com.br/#/authorize#',
    post_logout_redirect_uri: 'http://adm.lugarh.com.br/Unauthorized',
    client_id: 'LugarhAdm'
  },
  /** FIM Identity Server Configuration */

};
