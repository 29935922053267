import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MenuService } from '../shared/services';
import { ListSearchService } from '../shared/services/list-search.service';

@Component({
    selector: 'app-enterprise-list',
    templateUrl: './app-enterprise-list.component.html',
    styleUrls: ['./app-enterprise-list.component.scss']
})
export class AppEnterpriseListComponent implements OnInit {

    constructor(
        public listSearchService: ListSearchService,
        public menuSrv: MenuService
    ) { }

    ngOnInit() {
        this.menuSrv.currentActiveMenu = 'companies';
        this.listSearchService.LoadCompanies();
    }

    public async CompanyClick(id: string) {
        window.open('#/empresa/' + id, "_blank");
    }

    public onPageChange(event: PageEvent): void {
        this.listSearchService.page = event.pageIndex;
        this.listSearchService.pageSize = event.pageSize;

        this.listSearchService.LoadCompanies();
    }
}