<div style="padding: 15px;">
  <h1 mat-dialog-title><strong>Remoção de colaboradores {{displayStatus}}</strong></h1>
  <div mat-dialog-content>
    <h4><strong>Atenção!</strong></h4>
    <p style="padding-top: 10px;">
      Atenção! Essa operação é irreversível, deseja realmente continuar?
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-button mat-raised-button class="is-button__outline" color="accent" (click)="exit()">Não, eu desisto!</button>
    <button mat-button (click)="remove()">Continuar</button>
  </div>
</div>
