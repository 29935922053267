<section class="content__holder">
  <div class="card form__content">
    <div fxLayout="column wrap" fxLayoutAlign="space-between center">
      


      <!-- início -->
      <div  fxLayout="row" fxFill fxLayoutAlign="end stretch">
        <div class="card__title" fxFill>
            Colaboradores
        </div>
        <a [routerLink]="['','empresa', companyId, 'colaboradores']">
            <i class="material-icons">supervisor_account</i>
        </a>
      </div>
      <!-- fim -->

      <div fxLayout="row" fxLayoutAlign="none start" fxFill>

        <div fxFlex="20">
          <div>
            <h5><strong>Resumo</strong></h5>
          </div>

          <div *ngIf="!loading">
            <h6><strong>Novos: </strong>
              <span [innerHtml]="summary.notStarted | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Em andamento: </strong>
              <span [innerHtml]="summary.started | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Concluídos: </strong>
              <span [innerHtml]="summary.completed | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Inativos: </strong>
              <span [innerHtml]="summary.inactive | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Pendentes: </strong>
              <span [innerHtml]="summary.pendent | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Aguardando Aprovação: </strong>
              <span [innerHtml]="summary.waitingApproval | number:0:'pt-BR'"></span>
            </h6>

            <h6><strong>Total: </strong>
              <span [innerHtml]="summary.total | number:0:'pt-BR'"></span>
            </h6>
          </div>
          <div *ngIf="loading">
            <p>Carregando...</p>
          </div>
        </div>

        <div fxFlex="50" *ngIf="isRemoveVisible">

          <div>
            <h5><strong>Exclusão</strong></h5>
          </div>


          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [(value)]="status">
              <mat-option value="NotStarted" [disabled]="!isNotStartedOptionEnabled">Novos</mat-option>
              <mat-option value="Inactive"   [disabled]="!isInactiveOptionEnabled">Inativos</mat-option>
            </mat-select>
          </mat-form-field>


          <button (click)="showRemove()" [disabled]="isDeleteDisabled" mat-button mat-raised-button
            class="is-button__outline" color="danger">
            <i class="material-icons">delete_forever</i>
            Excluir
          </button>
        </div>

      </div>
    </div>
  </div>
</section>