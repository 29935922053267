<mat-card class="container-load mat-elevation-z4" *ngIf="isLoading">
  <section class="load-content">
    <app-loading></app-loading>
  </section>
</mat-card>

<section *ngIf="!isLoading">
  <div class="card__title">Usuários com acesso</div>

  <div fxFlex="100" class="form__field">
    <h6>Incluir usuários</h6>
    <form>
      <mat-form-field fxFlexFill>
        <input type="text" matInput [formControl]="usersInCompany" [matAutocomplete]="auto" [(ngModel)]="value" />
        <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value = ''">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="AddUserToList($event.option.value)">
          <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
            {{ user.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <div class="form__field">
    <h6>Usuários</h6>
    <mat-chip-list aria-label="Fish selection">
      <mat-chip *ngFor="let users of UserInWorkspace" [selectable]="selectable" (removed)="remove(users)" class="Card__User">
        <mat-icon class="Close__Chip" style="color: white" matChipRemove>close </mat-icon>
        {{ users.name }}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="content-button">
    <button mat-raised-button fxFlexFill color="accent" (click)="editWorkspace()">Editar Workspace</button>
  </div>
</section>
