<section fxLayout="column" fxLayoutAlign="center start" [ngClass]="{'is-expanded__section': MenuOpened == true}" style="overflow-x: hidden;">

    <!-- Menu -->
    <div fxLayout="column" class="content__menu__option"
        [ngClass]="{'is-expanded__content__menu__option': MenuOpened == true}">

        <div class="option__menu" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'companies'"
            [class.is-expanded__option__menu]="MenuOpened == true" (click)="goEnterprise()">
            <i class="material-icons">business</i>
            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                Empresas
            </p>
        </div>

        <div class="option__menu" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'admins'"
            [class.is-expanded__option__menu]="MenuOpened == true" (click)="goSettings()">
            <i class="material-icons">account_circle</i>
            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                Administradores
            </p>
        </div>

        <mat-accordion>
            <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header id="panelImplantacaoHeader">
                    <mat-panel-title>
                        <div class="option__menu" fxLayout="row"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="openMenu()">
                            <i class="material-icons">view_module</i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Implantação
                            </p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <span>
                    <mat-list-item>
                        <div class="submenu__item" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'schemas'"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="goSchemas()">
                            <i class="material-icons">data_array</i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Schemas
                            </p>
                        </div>
                    </mat-list-item>
                </span>
                <span>
                    <mat-list-item>
                        <div class="submenu__item" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'contratos'"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="goContracts()">
                            <i class="material-icons">assignment</i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Contratos
                            </p>
                        </div>
                    </mat-list-item>
                </span>
            </mat-expansion-panel>

            <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                    <mat-panel-title>
                        <div class="option__menu" fxLayout="row"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="openMenu()">
                            <i class="material-icons">my_library_books</i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Relatórios
                            </p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <span>
                    <mat-list-item>
                        <div class="submenu__item" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'share-report'"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="goShareReport()">
                            <i class="pi pi-file-excel" style="font-size: 1.3rem; padding: 2px"></i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Share
                            </p>
                        </div>
                    </mat-list-item>
                </span>
                <span>
                    <mat-list-item>
                        <div class="submenu__item" fxLayout="row" [class.is-active]="menuSrv.currentActiveMenu == 'generated-report'"
                        [class.is-expanded__option__menu]="MenuOpened == true" (click)="goGeneratedReport()">
                            <i class="material-icons">folder_open</i>
                            <p *ngIf="MenuOpened" [ngClass]="{'is-expanded__menu': MenuOpened == true}">
                                Gerados
                            </p>
                        </div>
                    </mat-list-item>
                </span>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>