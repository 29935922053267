import { AbstractControl } from '@angular/forms';

export function ValidateEmail(control: AbstractControl) 
{
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

    if (!regex.test(control.value)) {
        return { email: true };
    }

    return null;
}