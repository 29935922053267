import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { UrlTool } from '../utils/UrlTool';

/**
 * This class implements the CanActivate interface to protect routes thar must be executed only for logged in users
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService) { 
  }

  async canActivate(): Promise<boolean> {

    if (this.authService.isLoggedIn()) {

      // //If data is not completed, try get data
      // if(!this.authService.completedData()){
      //   await this.authService.getAllData()
      // }
      
      // If user is logged in & data is completed, the execution can be done
      
      return true;
    }

    // Building the object to storage the parameters to redirect after the user is logged in
    let redirect = {
      url: window.location.pathname,
      queryParams: UrlTool.queryToObject()
    }

    // Storing request parameters
    localStorage.setItem("after_auth_redirect_url", JSON.stringify(redirect));
    
    // Showing loader
    // this.loaderService.show();
    
    
    // Return false to block the route execution
    return false;
  }
}