<section>
  <div class="modal__close" fxFlexFill fxLayoutAlign="end none">
    <div>
      <button mat-icon-button matTooltip="Cancelar" matTooltipPosition="left" (click)="close()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div fxLayout="column wrap" fxLayoutAlign="space-between stretch"  class="modal__content">
    <h3  fxFlex="100" class="modal__title">Exclusão de Colaboradores</h3>
    <div fxFlex="100" class="body__message">
      Atenção os colaboradores serão enviados para a fila exclusão e serão removidos permanentemente. Deseja proseguir?
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="modal__actions">
    <button (click)="close()" mat-raised-button fxFlex="50" color="accent">
      <span>Não! Desisto disso.</span>
    </button>
    <button mat-raised-button  fxFlex="50" (click)="remove()">
      <span>Sim! Quero remover.</span>
    </button>
  </div>

</section>
