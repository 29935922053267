<section class="modal">
  <mat-stepper linear #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <div class="header">
        <h6 class="title">Empresas de produção</h6>
        <span class="description">Selecione a empresa que deseja implantar o formulário</span>
      </div>
      <div class="loading-content">
        <app-loading *ngIf="isLoading"></app-loading>
      </div>

      <!-- VERIFICAR SE REMOVE OU NÃO O COMENTARIO -->
      <!-- <section *ngIf="!isLoading" class="search_content">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Pesquisa</mat-label>
          <input type="text" placeholder="Escolha uma company" aria-label="Number" matInput [formControl]="myControl"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="AddCompanyToList($event.option.value)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.Name">
              {{option.Name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-icon (click)="AddCompanyToList('clear')">clear</mat-icon>
      </section> -->


      <form *ngIf="!isLoading" [formGroup]="firstFormGroup">
        <div class="content">
          <div *ngFor="let company of paginatedArray">
            <div class="item" (click)="chooseCompany(company.Id, company.Name, stepper)">
              <span>{{company.Name}}</span>
            </div>
          </div>
        </div>

        <!-- VERIFICAR SE REMOVE OU NÃO O COMENTARIO -->
        <!-- <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [pageIndex]="pageIndex"
          [length]="companiesPrd.length" (page)="updatePaginatedArray($event)">
        </mat-paginator> -->
      </form>

    </mat-step>
    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
      <div class="header">
        <h6 class="title">Workspaces da Empresa</h6>
        <span class="description">{{companyName}}</span>
      </div>
      <div class="loading-content">
        <app-loading *ngIf="isLoading"></app-loading>
      </div>
      <form *ngIf="!isLoading" [formGroup]="secondFormGroup">
        <div class="content" *ngIf="workspacesPrd">
          <div *ngFor="let workspace of workspacesPrd">
            <div class="item" [class.selected]="workspace.selected" (click)="chooseWorkspace(workspace)">
              <span>{{workspace.name}}</span>
            </div>
          </div>
        </div>
        <div class="content-button">
          <button class="previous-button" mat-button matStepperPrevious>Voltar</button>
          <button [disabled]="!secondFormGroup.valid" mat-raised-button class="is-button__outline" color="accent"
            (click)="nextStepConfirmation(stepper)">
            Proximo
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <div class="loading-content">
        <app-loading *ngIf="isLoading"></app-loading>
      </div>
      <section *ngIf="!isLoading">
        <div class="header">
          <h6 class="title">Confirmação de dados</h6>
          <span class="description">Verifique se realmente quer implantar o formulário nessa empresa e workspace</span>
        </div>
        <div class="content-form">
          <h6 class="title">Dados do formulário a ser implementado</h6>
          <span><strong>Id:</strong> {{data.formId}}</span>
          <span><strong>Nome:</strong> {{data.formName}}</span>
        </div>
        <div class="content-form">
          <h6 class="title">Dados da Empresa de produção</h6>
          <span><strong>Nome:</strong> {{companyName}}</span>
          <span class="workspaces-list"><strong>Workspace:</strong> {{workspacesName}}</span>
        </div>
        <div class="content-danger">
          <mat-icon>warning</mat-icon>
          <span> <strong>Aviso Importante:</strong> Tenha certeza antes de implantar os formulários em
            <b>PRODUÇÃO</b></span>
        </div>
        <div class="content-button">
          <button class="previous-button" mat-button matStepperPrevious>Voltar</button>
          <button mat-raised-button class="is-button__outline" color="accent" (click)="implenteFormPrd()">
            Implementar formulário
          </button>
        </div>
      </section>

    </mat-step>
  </mat-stepper>
</section>