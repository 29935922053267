import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShareQuery } from '../model/share-query.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(private readonly http: HttpClient) { }

  public query(c: string, q: string, p: number, s: number, f:string): Observable<ShareQuery>
  {
    return this.http.post<ShareQuery>(`${environment.apiEndpoint}/Share/AdmQuery`, 
      {
      Filter: f,
      companyId: c,
      query: q,
      page: p,
      size: s
    });
  }


  public sendToDeletionQueue(shares: string[], companyId: string): Observable<any>
  {
    return this.http.post<any>(`${environment.apiEndpoint}/Share/SendToDeletionQueue`,
    {
      shares: shares,
      companyId: companyId
    });
  }
}
