import { MatDialogConfig } from '@angular/material/dialog';

export class DialogConfig<T = any> implements MatDialogConfig {
  data?: T | DialogConfigData;

  data1: { [key: string]: any };

  width?: string;
  height?: string;

  autoFocus?: boolean;
  disableClose?: boolean;

  constructor(field: {
    data?: T | DialogConfigData;
    width?: string;
    height?: string;
    autoFocus?: boolean;
    disableClose?: boolean;
  }) {
    this.data = field.data || null;
    this.width = field.width || 'fit-content';
    this.height = field.height || 'fit-content';
    this.autoFocus = field.autoFocus || false;
    this.disableClose = field.disableClose || true;
  }
}

export class DialogConfigData {
  [key: string]: any;
  header?: string;
  subHeader?: string;
}
