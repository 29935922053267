import { ToastService } from 'src/app/shared/services/toast.service';
import { Workspace } from './../../shared/model/workspace/workspace.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkspaceService } from '../../../app/shared/services/workspace.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateCNPJ } from 'src/app/shared/forms/validators/cnpj.validator';
import { UserService } from 'src/app/shared/services/user.service';
import { UserInfo } from 'src/app/shared/model/userInfo.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select/select';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/shared/security/loader.service';


@Component({
  selector: 'app-workspace-company',
  templateUrl: './app-workspace-company.component.html',
  styleUrls: ['./app-workspace-company.component.scss']
})
export class AppWorkspaceCompanyComponent implements OnInit {

  public users: UserInfo[];
  public isLoading: boolean = false;
  public isRefresh: boolean = false;
  public companyId: string;
  public workspaceId: string;
  public workspace: Workspace;
  public workspaceForm: FormGroup;
 
  //form
  public companyUpdating: boolean = false;
  public isCnpjLoading: boolean = false;


  constructor(
    private activatedRouter: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private formBuilder: FormBuilder,
    private toasterService: ToastService,
    private loader: LoaderService,
  ) { }

  async ngOnInit() {
    this.initWorkspaceId();
    this.getWorkspaceById();
    this.configForm();   
  }

  public initWorkspaceId() {
    this.activatedRouter.params.subscribe(params => this.workspaceId = params.workId);
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
  }

  public async getWorkspaceById() {
    this.loader.show();
    this.workspace = await this.workspaceService.GetWorkspaceById(this.workspaceId).toPromise();
    this.workspaceForm.controls.name.setValue(this.workspace?.name);
    this.workspaceForm.controls.cnpj.setValue(this.workspace?.cnpj);
    this.loader.hide();
  }

  public showFormCompany() {
    this.companyUpdating = !this.companyUpdating;
  }

  public verifyCNPJ() {
    if (this.workspaceForm.controls.cnpj.valid == false) {
      console.log('cnpj1', this.workspaceForm.controls.cnpj.valid);
    }
    else if (this.workspaceForm.controls.cnpj.valid == true) {
      console.log('cnpj2', this.workspaceForm.controls.cnpj);
    }
  }

  configForm() {
    this.workspaceForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required, Validators.minLength(3)
      ]),
      cnpj: new FormControl('', [
        Validators.required, ValidateCNPJ
      ])
    });
    this.workspaceForm.controls.name.setValue(this.workspace?.name);
    this.workspaceForm.controls.cnpj.setValue(this.workspace?.cnpj);

  }

  public async patchWorkspace() {
    this.isLoading = true;
    let name = this.workspaceForm.get('name').value;
    let cnpj = this.workspaceForm.get('cnpj').value;    
    try {
      await this.workspaceService.patchWorkspaceData(this.companyId, this.workspaceId, name, cnpj).toPromise();
      setTimeout(() => {
        this.getWorkspaceById();
      }, 4000);
      this.isLoading = false;
      this.toasterService.success('Workspace alterado com sucesso');
    } catch (error) {
      console.log(error);
      this.toasterService.error('Algo aconteceu, tente novamente');
    }
  }

}
