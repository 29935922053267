import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Workspace } from 'src/app/shared/model/workspace/workspace.model';
import { ToastService, WorkspaceService } from 'src/app/shared/services';

@Component({
  selector: 'app-workspace-export',
  templateUrl: './workspace-export.component.html',
  styleUrls: ['./workspace-export.component.scss'],
})
export class WorkspaceExportComponent implements OnInit {
  //vars
  public workspace: Workspace;
  public companyId: string;
  public workspaceId: string;
  public exportForm: FormGroup;
  public keyArray: Array<any>;
  public keys: any;
  isLoading: boolean;

  constructor(
    private activatedRouter: ActivatedRoute,
    private toasterService: ToastService,
    private formBuilder: FormBuilder,
    private workspaceService: WorkspaceService,
  ) { }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe((params) => (this.companyId = params.id));
    this.activatedRouter.params.subscribe((params) => (this.workspaceId = params.workId));
    this.getExportData();
    this.initialForm();
  }

  public initialForm() {
    this.exportForm = this.formBuilder.group({
      keyOne: [''],
      keyTwo: [''],
    });
  }

  public async getExportData() {
    this.isLoading = true;
    await this.workspaceService
      .GetWorkspaceById(this.workspaceId)
      .toPromise()
      .then((obj) => {
        this.workspace = obj;
      });
    let data = this.workspace.data.exportCompany;
    if (data) {
      this.keyArray = Object.keys(data).map((key) => ({ type: key, value: data[key] }));
    } else {
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
  }
  public async pathExportData() {
    this.isLoading = true;
    let keyOne = this.exportForm.get('keyOne').value;
    let keyTwo = this.exportForm.get('keyTwo').value;
    if (this.workspace.data.exportCompany === undefined || this.workspace.data.exportCompany === null) {
      this.keys = null;
    }
    if (this.workspace.data.exportCompany) {
      this.keys = new Set(Object.keys(this.workspace?.data?.exportCompany));
    }
    if (this.keys != null || this.keys != undefined) {
      if (!this.keys.has(keyOne)) {
        await this.pathExportResquest(keyOne, keyTwo);
      }
      if (this.keys.has(keyOne)) {
        this.toasterService.error('Chave já existente');
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
    } else {
      await this.pathExportResquest(keyOne, keyTwo);
    }
  }


  private async pathExportResquest(keyOne: string, keyTwo: string) {
    await this.workspaceService
      .pathExportData(this.companyId, this.workspaceId, keyOne, keyTwo)
      .toPromise()
      .then((result) => {
        this.getExportData();
        this.toasterService.success('Chave criada com sucesso.');
      })
      .catch((err) => {
        console.log(err);
        this.toasterService.error('Tente novamente');
      });
    return;
  }

  public async deleteExportData(key: string) {
    await this.workspaceService
      .deleteExportData(this.companyId, this.workspaceId, key)
      .toPromise()
      .then((result) => {
        this.getExportData();
        this.toasterService.success(`${key} deletada com sucesso !`);
      })
      .catch((err) => {
        this.toasterService.error('Houve algum erro, tente novamente.');
      });
  }
}
