import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContractConfigResumedView } from 'src/app/shared/model/contract-config-view.model';
import { MappingField } from 'src/app/shared/model/mapping-field.model';
import { MenuService } from 'src/app/shared/services';
import { ContractConfigService } from 'src/app/shared/services/contract-config.service';

import { CreateContractComponent } from './create-contract/create-contract.component';

@Component({
  selector: 'app-contract-configs',
  templateUrl: './contract-configs.component.html',
  styleUrls: ['./contract-configs.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class ContractConfigsComponent implements OnInit, OnDestroy {
  ref: DynamicDialogRef;
  isLoading: boolean;
  showDialogUpload: boolean;
  contractList: Array<ContractConfigResumedView> = [];
  contractListFiltered: Array<ContractConfigResumedView> = [];
  columns = [
    { field: 'name', label: 'Nome' },
    { field: 'description', label: 'Descrição' },
    { field: 'companyName', label: 'Empresa' },
    { field: 'createdAt', label: 'Criado em' },
    { field: 'updatedAt', label: 'Atualizado em' },
  ];

  filteredCompanies: any[];
  selectedCompany: any;
  workspaces: any[];
  selectedWorkspaces: any[];
  sourceFields: MappingField[];
  targetFields: MappingField[];
  companyList = [];

  constructor(
    public menuService: MenuService,
    private contractConfigService: ContractConfigService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private _confirmationService: ConfirmationService,
  ) {}
  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  ngOnInit(): void {
    this.menuService.currentActiveMenu = 'contratos';
    this.loadTable();
  }

  loadTable() {
    this.isLoading = true;
    const objListSet = new Set();
    this.contractConfigService.getAll().then(
      (items) => {
        this.contractList = items;
        this.contractListFiltered = items;

        this.companyList = items
          .map((x) => {
            return { key: x.companyName, value: x.companyName };
          })
          .filter((item) => {
            const objIsDuplicated = objListSet.has(item.value);
            objListSet.add(item.value);
            return !objIsDuplicated;
          })
          .sort((a, b) => this.compare(a.value, b.value, true));

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Oops! Algo deu errado.',
        });
        console.error(error);
      },
    );
  }

  cancelUpload() {
    this.showDialogUpload = false;
  }

  showDialog() {
    this.ref = this.dialogService.open(CreateContractComponent, {
      header: 'Configurar novo contrato',
      width: '50vw',
      contentStyle: { 'max-height': '75vh', 'overflow': 'auto' },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((data) => {
      if (data.reload) this.loadTable();
    });
  }

  showDialogUpdate(id: string) {
    this.ref = this.dialogService.open(CreateContractComponent, {
      data: {
        id: id,
      },
      header: 'Editar contrato',
      width: '50vw',
      contentStyle: { 'max-height': '75vh', 'overflow': 'auto' },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((data) => {
      if (data.reload) this.loadTable();
    });
  }

  disableContract(event: Event, id: string) {
    this._confirmationService.confirm({
      target: event.target,
      message: 'Deseja desativar o contrato?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.contractConfigService
          .disableContractConfig(id)
          .then(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Contrato desativado com sucesso!',
            });
            this.loadTable();
          })
          .catch((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Oops! Algo deu errado.',
            });
            console.log(error);
          });
      },
      reject: () => {},
    });
  }

  onCompanySelected(event) {
    const selectedCompany = event.value;
    if (!selectedCompany || selectedCompany === '') this.contractListFiltered = this.contractList;

    this.contractListFiltered = this.contractList.filter((x) => x.companyName === selectedCompany);
  }

  onClearCompanyFilter(event) {
    this.contractListFiltered = this.contractList;
  }

  // * Sort Array
  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
