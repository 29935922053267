import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidadeOnlyLetters } from 'src/app/shared/forms/validators/onlyLetters.validator';
import { ValidateEmail } from 'src/app/shared/forms/validators/email.validator';
import { ValidateCellphone } from 'src/app/shared/forms/validators/cellphone.validator';
import { EmailAlreadyTakenValidatorService } from './validators/email-already-taken.validator.service';
import { ValidateFullname } from 'src/app/shared/forms/validators/fullname.validator';
import { ValidateCNPJ } from 'src/app/shared/forms/validators/cnpj.validator';
import { CnpjAlreadyTakenValidatorService } from './validators/cnpj-already-taken.validator.service';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { AddCompanyCmd } from 'src/app/shared/model/add-company-cmd.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserInfo } from 'src/app/shared/model/userInfo.model';
import { Router } from '@angular/router';


const NAME_MAX_LENGTH: number = 70;
const NAME_MIN_LENGTH: number = 4;

@Component({
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  fgroup: FormGroup;
  checkingEmail: boolean = false;

  maskTelefone = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  maskCNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  constructor(private readonly formBuilder: FormBuilder, 
              private readonly emailAlreadyTaken: EmailAlreadyTakenValidatorService,
              private readonly cnpjTakenService: CnpjAlreadyTakenValidatorService,
              private readonly loaderService: LoaderService,
              private readonly companyService: CompanyService,
              private readonly toast: ToastService,
              private readonly router: Router,
              private userService: UserService) { }

  ngOnInit() 
  {
    this.fgroup = this.formBuilder.group({
      fullName: ['', 
        [
          Validators.required,
          Validators.maxLength(NAME_MAX_LENGTH),
          Validators.minLength(NAME_MIN_LENGTH),
          ValidateFullname, 
          ValidadeOnlyLetters
        ]
      ],
      corporativeEmail: ['', 
        [
          Validators.required, 
          ValidateEmail
        ],
        [
          //this.emailAlreadyTaken.checkIfEmailIsTaken()
        ]
      ],
      companyName: ['', 
        [
          Validators.required, 
          Validators.minLength(NAME_MIN_LENGTH),
          Validators.maxLength(NAME_MAX_LENGTH)
        ]
      ],
      employeesAmount: ['', 
        [
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.max(99999)
        ]
      ],
      cnpj: ['', 
        [
          Validators.required, 
          ValidateCNPJ
        ],
        [
          this.cnpjTakenService.checkIfCnpjIsTaken()
        ]
      ],
      cellphone: ['', 
        [
          Validators.required, 
          ValidateCellphone
        ]
      ],
      companyWebSite: ['', 
        [
          Validators.pattern(/^([a-z0-9])(([a-z0-9-]{1,61})?[a-z0-9]{1})?(\.[a-z0-9](([a-z0-9-]{1,61})?[a-z0-9]{1})?)?(\.[a-zA-Z]{2,4})+$/)
        ]
      ]
    });
  }

  onBlurMail(value: string): void{
    this.loaderService.show();
    this.userService.UserExists(value).subscribe(
      (usr: UserInfo) => 
      {
          if(usr != null)
          {
            this.fgroup.get('fullName').setValue(usr.name);
            this.fgroup.get('cellphone').setValue(usr.cellphone);
            this.fgroup.get('fullName').disable();
            this.fgroup.get('cellphone').disable();
          }else{
            this.fgroup.get('fullName').enable();
            this.fgroup.get('cellphone').enable();
          }
          this.loaderService.hide();
      },
      err =>
      {
        this.loaderService.hide();
      });
  }

  submit()
  {
    this.loaderService.show();

    const cmd: AddCompanyCmd = {
      cellphone : this.fgroup.get('cellphone').value,
      cnpj      : this.fgroup.get('cnpj').value,
      company   : this.fgroup.get('companyName').value,
      email     : this.fgroup.get('corporativeEmail').value,
      name      : this.fgroup.get('fullName').value
    };
  

    this.companyService.AddNewCompany(cmd)
    .subscribe((resp) => 
      {
        this.router.navigate(['', 'empresa', resp.id])
        this.toast.success('Cadastro realizado com sucesso.');
        this.loaderService.hide();
      },
      (err) => 
      {
        this.toast.error('Houve um erro ao efetuar o cadastro!');
        this.loaderService.hide();
      });  
  }

}
