import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesPanelComponent } from './employees-panel/employees-panel.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button'; 
import { MatIconModule }  from '@angular/material/icon';
import { RemoveEmployeesDialogComponent } from './remove-employees-dialog/remove-employees-dialog.component'; 
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EmployeesPanelComponent, RemoveEmployeesDialogComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    RouterModule
  ],
  exports: [ EmployeesPanelComponent ],
  entryComponents: [ RemoveEmployeesDialogComponent ]
})
export class EmployeesModule { }
