<p-toast position="top-right"></p-toast>
<p-confirmPopup></p-confirmPopup>

<p-panel header="Gerenciamento de Contratos - Integração Clicksign">
  <ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

  <div class="card">
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <button
          pButton
          pRipple
          label="Novo contrato"
          (click)="showDialog()"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"></button>
      </ng-template>
    </p-toolbar>

    <p-table
      #dt
      [value]="contractListFiltered"
      [rows]="10"
      [paginator]="true"
      [columns]="columns"
      [globalFilterFields]="['id', 'name', 'description']"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [showCurrentPageReport]="true">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="pesquisar..." />
            <p-dropdown
              (onChange)="onCompanySelected($event)"
              (onClear)="onClearCompanyFilter($event)"
              placeholder="Filtrar por Empresa"
              showClear="true"
              panelStyleClass="mx-2"
              [options]="companyList"
              [(ngModel)]="selectedCompany"
              optionLabel="key"
              optionValue="value">
            </p-dropdown>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.label }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <button
              (click)="showDialogUpdate(rowData.id)"
              pButton
              pRipple
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"></button>
            <button
              (click)="disableContract($event, rowData.id)"
              pButton
              pRipple
              icon="pi pi-ban"
              class="p-button-rounded p-button-danger"></button>
          </td>
          <td>
            {{ rowData.name }}
          </td>
          <td>
            {{ rowData.description }}
          </td>
          <td>
            {{ rowData.companyName }}
          </td>
          <td>
            {{ rowData.createdAt | date: 'dd/MM/yyyy HH:mm':'GMT-3' }}
          </td>
          <td>
            {{ rowData.updatedAt | date: 'dd/MM/yyyy HH:mm':'GMT-3' }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          No total existem {{ contractList ? contractList.length : 0 }} Itens.
        </div>
      </ng-template>
    </p-table>
  </div>
</p-panel>
