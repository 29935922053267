import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { ToastService, UserService, WorkspaceService } from 'src/app/shared/services';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { ValidateCellphone } from '../../shared/forms/validators/cellphone.validator';
import { ValidateCpf } from '../../shared/forms/validators/cpf.validator';
import { ValidateEmail } from '../../shared/forms/validators/email.validator';
import { ValidateFullname } from '../../shared/forms/validators/fullname.validator';
import { ValidadeOnlyLetters } from '../../shared/forms/validators/onlyLetters.validator';
import { Profile } from '../../shared/model/authorization/profile.model';
import { Company } from '../../shared/model/company.model';
import { UserInfo } from '../../shared/model/userInfo.model';
import { WorkspaceInfoSettings } from '../../shared/model/workspace/workspace-info-settings.model';
export interface Work {
  name: string;
}

@Component({
  selector: 'app-add-user-company',
  templateUrl: './add-user-company.component.html',
  styleUrls: ['./add-user-company.component.scss']
})

export class AddUserCompanyComponent implements OnInit {
  //Options
  public profiles: Profile[] = [];
  public filteredOptions: Observable<Work[]>;
  public optionsSelected: WorkspaceInfoSettings[] = [];
  public optionsNotSelected: WorkspaceInfoSettings[] = [];
  public options: WorkspaceInfoSettings[] = [];

  //Form Group
  public userForm: FormGroup;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public authorizationForm: FormGroup;

  //Form Control
  public workNameControl = new FormControl();

  //Global Variables
  public isLinear = false;
  public floatLabel: string = 'never';
  public modalAction: ModalAction = 0;
  public Email__In_Use: string = "";
  public filterName: string = "";
  public isWorkspaceAdmin: boolean = false;
  public addAllWorkspaces: boolean = false;

  //Global Variables Validators
  public isInProgress: boolean = false;
  public _is_loading_email: boolean = false;
  public _is_email_already_inUse: boolean = false;

  //Validators Length
  public readonly NAME_MAX_LENGTH: number = 70;
  public readonly NAME_MIN_LENGTH: number = 3;
  public readonly EMAIL_MAX_LENGTH: number = 50;
  public readonly OCCUPATION_MAX_LENGTH: number = 70;

  //Objects
  private company: Company;
  private workspaceGeral: WorkspaceInfoSettings;

  //Others

  constructor(
    public dialogRef: MatDialogRef<AddUserCompanyComponent>,
    public dialog: MatDialog,
    public activatedRouter: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _loader: LoaderService,
    private _userService: UserService,
    private _toastService: ToastService,
    private _workspaceService: WorkspaceService,
    private _authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.start();
  }

  //#region Start Component
  private start() {
    this.filteredOptions = this.workNameControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.optionsNotSelected)
      );

    this._createForm();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    if (this.data.id) {
      this.modalAction = 1;
      this.fillForm(this.data);
    }
    else {

      this.LoadWorkspaces();
    }

    this.LoadProfiles();

  }

  private async _createForm() {

    this.userForm = this._formBuilder.group({
      name: new FormControl('', [
        Validators.required, Validators.minLength(this.NAME_MIN_LENGTH), Validators.maxLength(this.NAME_MAX_LENGTH), ValidateFullname, ValidadeOnlyLetters
      ]),
      cellphone: new FormControl('', [
        Validators.required, ValidateCellphone
      ]),
      email: new FormControl('', [
        Validators.required, ValidateEmail, Validators.maxLength(this.EMAIL_MAX_LENGTH)
      ]),
      cpf: new FormControl('', ValidateCpf),//, ValidateCpf),
      birthdate: new FormControl('', [Validators.required/*, NgxAgeValidator(14, 120)*/]),
      occupation: new FormControl('', [
        Validators.maxLength(this.OCCUPATION_MAX_LENGTH), Validators.required]),
      gender: new FormControl('', Validators.required)
    });
  }

  private async fillForm(user: UserInfo) {
    this.userForm.controls.email.setValue(user.email);
    this.verifyEmail();

    await this.VerifyUserIsWorkspaceAdmin(user.id);
    this.LoadWorkspaces();
  }

  public verifyEmail(): void {
    let email = this.userForm.controls.email;

    if (email.hasError('email') || email.hasError('required'))
      return;

    this._is_loading_email = true;

    this._userService.UserExists(email.value).subscribe(
      (data: UserInfo) => {
        this.configureUsers(data);
      },
      err => {
        this._toastService.error('Não foi possível carregar dados de usuário');
      });
  }

  private configureUsers(data: UserInfo) {
    if (data != null) {
      try {
        let email = this.userForm.controls.email;
        this._is_email_already_inUse = true;

        this.userForm.controls.email.setValue(email.value);
        if (email.value) {
          this.userForm.controls.email.disable();
        }

        this.userForm.controls.name.setValue(data.name);
        if (data.name)
          this.userForm.controls.name.disable();

        this.userForm.controls.cellphone.setValue(data.cellphone);
        if (data.cellphone)
          this.userForm.controls.cellphone.disable();

        let date = '';
        if (data.birthdate) {
          var pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})/;
          var arrayDate = data.birthdate.match(pattern);
          if (arrayDate != null && arrayDate.length > 0)
            date = `${arrayDate[3]}-${arrayDate[2]}-${arrayDate[1]}`
          else
            date = data.birthdate;
        }
        this.userForm.controls.birthdate.setValue(date);
        if (data.birthdate)
          this.userForm.controls.birthdate.disable();

        this.userForm.controls.cpf.setValue(data.cpf);
        if (data.cpf)
          this.userForm.controls.cpf.disable();

        this.userForm.controls.occupation.setValue(data.occupation);
        if (data.occupation)
          this.userForm.controls.occupation.disable();

        this.userForm.controls.gender.setValue(data.gender);
        if (data.gender)
          this.userForm.controls.gender.disable();

        this.Email__In_Use = email.value;

        this.userForm.markAsDirty();

        this._is_loading_email = false;

        if (this.data.id != null) {
          this.userForm.enable();
          this.userForm.controls.email.disable();
        }

      } catch (ex) {
        this._is_loading_email = false;
        if (this.data.id) {
          this.enableInputs();
        }
        this._toastService.error("Erro ao carregar dados do Usuário.")
      }
    }
    else
      this.enableInputs();

    this._is_loading_email = false;
  }

  private LoadWorkspaces(): void {
    this.workspaceGeral = null;

    this._workspaceService.GetAllWorkspaces(this.data.companyId).subscribe((ret: any) => {
      ret.forEach(element => {
        this.options.push({ id: element?.id, name: element?.name, nickname: element?.nickname, settings: {} });
      });

      this.workspaceGeral = this.options.find((item) => item.name.toLowerCase().includes('geral'));

      let optionsWithoutGeral = this.options.filter((item) => !item.name.toLowerCase().includes('geral'));
      this.optionsNotSelected = _.cloneDeep(optionsWithoutGeral);
      if (this.data.id) {
        this.optionsSelected = this.data.workspacesSettings;
        this.optionsSelected.forEach(element => {
          const index = this.optionsNotSelected.findIndex(x => x.id == element.id);
          if (index >= 0)
            this.optionsNotSelected.splice(index, 1);
        });
      }

      if (this.addAllWorkspaces) {
        let optionsToChoice = [...this.optionsNotSelected];
        optionsToChoice.forEach((workspace) => {
          this.add(workspace);
        });
      }

      this.workNameControl.setValue("");
    },
      err => {
        this._toastService.error("Erro ao carregar lista de workspaces. Contacte a equipe de suporte");
        this.dialogRef.close(false);
      }
    );
  }


  public thirdTitle: string = "Escolha qual perfil do usuário (caso tenha habilitado a ferramenta de permissionamento)";

  private LoadProfiles(): void {

    this.authorizationForm = this._formBuilder.group({
      profileOfUser: new FormControl('')
    });

    this._authorizationService.GetAllProfiles(this.data.companyId).subscribe(ret => {
      this.profiles = [];
      if (ret) {
        ret.forEach(element => {
          this.profiles.push({ id: element.id, name: element.name, users: element.users })
        });
  
        if (this.profiles != null && this.profiles.length > 0) {
          let profileOfUser = this.profiles.find(p => p.users.find(u => u == this.data.id) != null);
  
          if (profileOfUser != null) {
            this.authorizationForm.controls.profileOfUser.setValue(profileOfUser.id);
          }
        } else {
          this.thirdTitle = "Empresa não habilitada para o novo permissionamento";
  
        }
      }
    },
      err => {
        this._toastService.error("Erro ao carregar lista de perfis. Contacte a equipe de suporte");
        console.log(err)
      }
    );
  }

  private async VerifyUserIsWorkspaceAdmin(userId: string) {
    await this._workspaceService.IsWorkspaceAdmin(userId, this.data.companyId).toPromise().then(ret => {
      if (ret) {
        this.isWorkspaceAdmin = ret;
      }
    }).catch((err) => {
      this._toastService.error("Erro ao verificar se usuário é nível Admin. Contacte a equipe de suporte");
      this.dialogRef.close(false);
    });
  }
  //#endregion Start Component

  //#region Autocomplete & Filter
  public displayFn(work: Work): string {
    return work && work.name ? work.name : '';
  }

  private _filter(name: string): Work[] {
    const filterValue = name.toLowerCase();
    return this.optionsNotSelected.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  //#endregion Autocomplete & Filter

  //#region Input Enable/Disable and Resets
  private disableInputs(): void {
    this.userForm.disable();
  }

  private enableInputs(): void {
    this.userForm.enable();
    this._is_email_already_inUse = false;
  }

  public resetAll(): void {
    this.userForm.reset();
    this.Email__In_Use = "";
    this.enableInputs();
  }
  //#endregion Input Enable/Disable and Resets

  //#region ButtonActions
  public onInputUserClick(): void {
    if (this.data.id)
      this.UpdateUserWorkspaces(this.data.id)
    else
      this.CreateUserWorkspaces()
  }

  public onAddAllWorkspacesClick(event: MatCheckboxChange): void {
    if (event.checked) {
      this.optionsNotSelected = [];
      this.optionsSelected = _.cloneDeep(this.options);
    } else {
      this.optionsSelected = [];
      let optionsWithoutGeral = this.GetOptionsWithoutGeral(this.options);
      this.optionsNotSelected = _.cloneDeep(optionsWithoutGeral);
    }
    this.workNameControl.setValue("");
    this.addAllWorkspaces = event.checked;
  }

  private GetOptionsWithoutGeral(options: WorkspaceInfoSettings[]) {
    return options.filter((item) => !item.name.toLowerCase().includes('geral'));
  }

  private CreateUserWorkspaces(): void {
    this.userForm.disable();
    this.isInProgress = true;
    let userObj = this.userForm.value;

    userObj.name = this.toTitleCase(userObj.name);

    this._userService.AddNewUser(userObj, this.data.companyId).then((data) => {
      if (!data.status) {
        this._toastService.error("Dados já pertencem à um usuário vinculado à empresa.");
        this.userForm.enable();
        this.isInProgress = false;
        return;
      }
      else if (this.optionsSelected == null || this.optionsSelected.length <= 0) {
        this._toastService.error("Usuário não posui workspaces selecionados.");
        this.userForm.enable();
        this.isInProgress = false;
        return;
      }

      if (this.optionsSelected.length > 0) {
        this._workspaceService.UpdateUserWorkspace(
          { userId: data.user.id, workspaceInfoSettings: this.optionsSelected }, this.data.companyId
        ).subscribe(rst => {
          if (this.authorizationForm.controls.profileOfUser.value != "")
            this._authorizationService.AddUserInProfile(data.user.id, this.authorizationForm.controls.profileOfUser.value, this.data.companyId);
          
          this._toastService.success("Usuário e Workspaces adicionados com sucesso.");
        }, err => {
          this._toastService.warning("Usuário adicionado, porém houve falha ao incluir Workpsaces.");
          console.log(err);
        });

        this._workspaceService.UpdateUserWorkspaceAdmin(data.user.id, this.data.companyId, this.isWorkspaceAdmin)
        .subscribe(rst => {
          this._toastService.success("Dados Atualizados com sucesso.");
          this.dialogRef.close(true);
        }, err => {
          this._toastService.error("Ops, ocorreu um erro ao atualizar dados do usuário.");
          console.log(err);
          this.dialogRef.close(false);
        });
      }
    },
      (reason: any) => {
        this.isInProgress = false;
        if (reason !== null && reason !== undefined && reason.hasOwnProperty('error')) {
          if (reason.error !== null && reason.error !== undefined && reason.error.hasOwnProperty('message'))
            this._toastService.error(reason.error.message);
          else
            this._toastService.error('Ops, ocorreu um erro ao cadastrar usuário. Consulte o suporte para mais informações');
          this.userForm.enable();
        }
      });
  }

  private UpdateUserWorkspaces(userId: string): void {
    this.userForm.disable();
    this.isInProgress = true;

    let userObj = this.userForm.value;

    userObj.name = this.toTitleCase(userObj.name);
    userObj.id = this.data.id;

    //Update User Info
    this._userService.UpdateUserInfo(userObj).then((data) => {
      this._toastService.success("Dados Atualizados com sucesso.");
    },
      (reason: any) => {
        this.isInProgress = false;
        this._toastService.error('Ops, ocorreu um erro ao editar usuário.');
        console.error("Error on Update User info", reason);
        this.dialogRef.close(false);
      });

    //Update User Workspace
    this._workspaceService.UpdateUserWorkspace(
      { userId: userId, workspaceInfoSettings: this.optionsSelected }, this.data.companyId
    ).subscribe(rst => {
      this._toastService.success("Dados Atualizados com sucesso.");
      this.dialogRef.close(true);
    }, err => {
      this._toastService.error("Ops, ocorreu um erro ao atualizar dados do usuário.");
      this.dialogRef.close(false);
    });

    this._workspaceService.UpdateUserWorkspaceAdmin(userId, this.data.companyId, this.isWorkspaceAdmin)
      .subscribe(rst => {
        this._toastService.success("Dados Atualizados com sucesso.");
        this.dialogRef.close(true);
      }, err => {
        this._toastService.error("Ops, ocorreu um erro ao atualizar dados do usuário.");
        this.dialogRef.close(false);
      });

    if (this.authorizationForm.controls.profileOfUser.value != "")
      this._authorizationService.AddUserInProfile(this.data.id, this.authorizationForm.controls.profileOfUser.value, this.data.companyId);
  }

  public ChangeWorkspaceAdminStatus(value: MatSlideToggleChange) {
    const { checked } = value;
    this.isWorkspaceAdmin = checked;
  }
  //#endregion ButtonActions

  //#region Add/Remove Workspace from List
  public add(workspace: WorkspaceInfoSettings): void {
    let index = this.optionsNotSelected.findIndex(w => w.id === workspace.id);
    if (index >= 0) {
      // Reset the input value
      this.workNameControl.setValue("");
      this.optionsNotSelected.splice(index, 1);
      this.optionsSelected.push(workspace);
    } else {
      alert("Workspace já incluso na lista.")
    }

    if (this.optionsSelected.findIndex(o => o.id == this.workspaceGeral.id) < 0)
      this.optionsSelected.push(this.workspaceGeral);

    if (this.optionsNotSelected.length == 0)
      this.addAllWorkspaces = true;
  }

  public remove(option: WorkspaceInfoSettings): void {
    this.addAllWorkspaces = false;
    let index = this.optionsSelected.findIndex(w => w.id === option.id);
    if (index >= 0) {
      this.optionsSelected.splice(index, 1);

      if (!option.name.toLowerCase().includes('geral')) {
        this.optionsNotSelected.push(option);
      }
    }
  }
  //#endregion Add/Remove Workspace from List

  //#region Gets and Sets
  // Title, subtitle and description
  public get text(): any {
    return {
      title: this.modalTitle,
      subtitle: this.modalSubtitle,
      editWork: this.modalEditwork,
      error: {
        name: this.nameErrorMessage
      }
    }
  }

  public get modalTitle(): string {
    return this.modalAction == 0 ? "Adicionar Usuário" : "Editar Usuário"
  }

  public get modalSubtitle(): string {
    return this.modalAction == 0 ? "Forneça os dados para adicionar usuário" : "Forneça os dados para editar usuário"
  }

  public get modalEditwork(): string {
    return this.modalAction == 0 ? "Adicione os workspaces que este usuário terá acesso" : "Edite os workspaces que este usuário terá acesso"
  }

  public get hasEboxIntegration(): boolean {
    const hasIntegration = this.company.hasIntegration && this.company.integrations != null && this.company.integrations['Ebox'];
    return hasIntegration;
  }

  //modal strings
  public get mask(): any {
    return {
      telefone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    }
  }

  public get isInvalid(): boolean {
    if (this.userForm.invalid)
      return true;
    else if (this._is_loading_email)
      return true;
    else
      return false;
  }

  public get hasOptionSelected(): boolean {
    if (!this.optionsSelected)
      return false;

    const optionsWithoutGeral = this.GetOptionsWithoutGeral(this.optionsSelected);
    return (optionsWithoutGeral.length > 0)
  }

  //Privates Get/Set

  private get nameErrorMessage(): string {
    return this.userForm.controls.name.hasError('required') ? 'Preencha o nome completo.' :
      this.userForm.controls.name.hasError('minlength') ? `Entre com no mínimo ${this.NAME_MIN_LENGTH} caracteres.` :
        this.userForm.controls.name.hasError('maxlength') ? `O número máximo de caracteres foi antingido.` :
          this.userForm.controls.name.hasError('fullname') ? 'Deve ser um nome completo.' :
            this.userForm.controls.name.hasError('onlyletters') ? 'Caracteres especiais não são permitidos.' : '';
  }
  //#endregion Gets and Sets

  //#region Others
  public toTitleCase(s) {
    return s.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
    );
  }
  //#endregion Others
}

enum ModalAction {
  ADD = 0,
  EDIT = 1
}
