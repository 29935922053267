import { AbstractControl } from '@angular/forms';

declare var require: any;
const validarCpf = require('validar-cpf');

export function ValidateCpf(control: AbstractControl) {
    if (control.value) {
        if (!ValidateCpfMethod(control.value))
            return { cpf: true };
    }
    return null;
}

export function ValidateCpfMethod(str: string): boolean {
    if (!validarCpf(str))
        return false;
    return true;
}