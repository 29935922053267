import { AddUserComponent } from './add-user/add-user.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { UserInfo } from '../../shared/model/userInfo.model';
import { UserService } from '../../shared/services/user.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { MenuService } from 'src/app/shared/services';

@Component({
    selector: 'user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

    readonly _userListLocalStorageItemName: string = 'UserService:user_list';

    isExpanded = true;
    profileImage: string = './assets/empty-profile.jpg';
    companyUsers: UserInfo[] = new Array<UserInfo>();

    public total: number = 1000;
    public pageSize: number = 10;
    public page: number = 0;

    isEditingDetails: boolean = false;
    onDetailsEditClick() {
        if (this.isEditingDetails == false) {
            this.isEditingDetails = true;
        }
        else {
            this.isEditingDetails = false;
        }
    }

    isEditingPermissions: boolean = false;
    onPermissionsEditClick() {
        if (this.isEditingPermissions == false) {
            this.isEditingPermissions = true;
        }
        else {
            this.isEditingPermissions = false;
        }
    }

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private readonly toast: ToastService,
        private readonly loaderService: LoaderService,
        public menuService: MenuService
    ) { }

    ngOnInit() {
        this.menuService.currentActiveMenu = 'admins';
        this.ConfigureUserList();
    }

    public show(): void {
        let dialogRef = this.dialog.open(AddUserComponent, { disableClose: true })
        dialogRef.afterClosed()
            .subscribe(() =>
                this.ConfigureUserList()
            );
    }

    public ConfigureUserList(): void {
        this.loaderService.show();
        this.userService.GetUserListAdmin(this.pageSize, this.page + 1).then((promise) => {
            this.companyUsers = promise.users;
            this.total = promise.total;
        }
        )
            .finally(() => this.loaderService.hide());
    }

    public onDeleteUserClicked(id: string) {
        this.loaderService.show();
        this.userService.DeleteUserAdmin(id)
            .then((success) => {
                if (success) {
                    this.ConfigureUserList();
                } else {
                    this.toast.error('Houve um erro ao remover o usuário.');
                }
            },
                (err) => {
                    console.log(err);
                    this.toast.error('Houve um erro ao remover o usuário.');
                }
            )
            .finally(() => this.loaderService.hide());
    }

    public onPageChange(event: PageEvent): void {
        console.log(event);
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;

        this.ConfigureUserList();
    }
}
