import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to control the global loader spinner
 */
@Injectable()
export class LoaderService {
  public loaderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  show() {
    this.loaderStatus.next(true);
  }

  hide() {
    this.loaderStatus.next(false);
  }
  
}
