import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './app-loading-modal.component.html',
  styleUrls: ['./app-loading-modal.component.scss']
})
export class AppLoadingModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
