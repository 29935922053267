import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-redirect',
  templateUrl: './app-redirect.component.html',
  styleUrls: ['./app-redirect.component.scss']
})


export class AppRedirectComponent {

  private _error: any;
  public redirectTitle: string;
  public redirectText: string;
  public redirectButtonText: string;

  @Output() closedModal = new EventEmitter<Document>();

  constructor(
    public dialog: MatDialog,
    public router: Router) { }

  ngOnInit() { }
  
  /**
   * Modifies the modal message according to the error the server
   */
  public show(error: any)
  {
    var instanceModal = this.dialog.open(AppRedirectComponent,{disableClose:true});
    instanceModal.componentInstance._error = error;
    
    if (error === 401) {
      instanceModal.componentInstance.redirectTitle = 'Sua sessão expirou!';
      instanceModal.componentInstance.redirectText = 'Devido a um período de inatividade a sua sessão expirou. Efetue o login para acessar o sistema novamente.';
      instanceModal.componentInstance.redirectButtonText = 'ir para o login';
    }
    else if (error === 403) {
      instanceModal.componentInstance.redirectTitle = 'Oops!';
      instanceModal.componentInstance.redirectText = 'Parece que você não tem permissão para acessar essa página.';
      instanceModal.componentInstance.redirectButtonText = 'voltar';
    }
    else{ //default
      instanceModal.componentInstance.redirectTitle = 'Oops!';
      instanceModal.componentInstance.redirectText = 'Tivemos alguns problemas ao carregar suas informações. Clique no botão abaixo para tentar novamente.';
      instanceModal.componentInstance.redirectButtonText = 'tentar novamente';
    }

    instanceModal.afterClosed().subscribe(() =>{
      this.dialog.closeAll();
      this.closedModal.emit();
    });
  }

  /**
   * Modal button action
   */
  public redirect(): void  {
       this.dialog.closeAll();    
  }
}
