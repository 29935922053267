<section>
  <div class="modal__close" fxFlexFill fxLayoutAlign="end none">
    <div>
      <button mat-icon-button matTooltip="Cancelar" matTooltipPosition="left" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center"  class="modal__content">
    <h3 fxFlex="100" class="modal__title">Adicionar Usuário</h3>
    <h6 fxFlex="100" class="modal__subtitle">Forneça os dados para adicionar usuários</h6>
    <form [formGroup]="userForm" fxFlex="100">
      
      <div class="form__field">
        <p class="label">Email Pessoal</p>
        <mat-form-field fxFlexFill [floatLabel]="floatLabel">
          <input type="email" matInput placeholder="exemplo@gmail.com" value="{{Email__In_Use}}" (blur)="verifyEmail();" formControlName="email" formControlName="email" required autocomplete="off">
          <span class="form__loading">
              <mat-spinner *ngIf="_is_loading"color="primary" diameter="32" strokeWidth="4" mode="indeterminate"></mat-spinner>
              <i style="cursor:pointer" (click)="resetAll();" *ngIf="_is_email_already_inUse" class="material-icons">close</i>
          </span>
          <mat-error>
            Preencha um e-mail válido.
          </mat-error>
        </mat-form-field>
      </div>
      
      <div class="form__field">
        <p class="label">Nome Completo</p>
        <mat-form-field fxFlexFill [floatLabel]="floatLabel">
          <input type="text" matInput formControlName="name" placeholder="Nome completo" [maxlength]="NAME_MAX_LENGTH" autocomplete="off"/>
          <mat-error *ngIf="userForm.controls.name.invalid  && userForm.controls.name.touched">{{getNameErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      
      <div class="form__field">
        <p class="label">Celular</p>
        <mat-form-field fxFlexFill [floatLabel]="floatLabel">
          <input type="tel" matInput formControlName="cellphone" placeholder="(xx) x xxxx-xxxx" [textMask]="{mask: maskTelefone, keepCharPositions: true}" autocomplete="off" >
          <mat-error>
            Preencha um celular válido.
          </mat-error>
        </mat-form-field>
      
      
      </div>

      <div class="form__field">
          <p class="label">Senha</p>
          <mat-form-field fxFlexFill [floatLabel]="floatLabel">
            <input type="text" matInput formControlName="password" placeholder="******" autocomplete="off" >
            <mat-error>
              A senha deve possuir letras e números.
            </mat-error>
          </mat-form-field>
      </div>

    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="modal__actions">
    <button mat-raised-button fxFlexFill color="accent" (click)='onInputUserClick();' [ngClass]="{'in-progress': isInProgress,  'is-disable': userForm.invalid}" >
      <span *ngIf="!isInProgress">Adicionar usuário</span>
      <span *ngIf="isInProgress" disabled>Adicionando</span>
    </button>

  </div>

</section>
