import { Component, OnInit } from '@angular/core';
import { EReportStatus } from 'src/app/shared/enums/e-report-status.enum';
import { ControlReportModel } from 'src/app/shared/model/report/control-report.model';
import { MenuService } from 'src/app/shared/services';
import { ReportService } from 'src/app/shared/services/report/report.service';

@Component({
  selector: 'app-generated-report',
  templateUrl: './generated-report.component.html',
  styleUrls: ['./generated-report.component.scss']
})
export class GeneratedReportComponent implements OnInit {
  controlReports: Array<ControlReportModel> = [];
  isLoading: boolean = false;
  eReportStatus: EReportStatus;

  eReportStatusArr: Array<{ name: string, status: EReportStatus }> = [
    {
      name: 'Não iniciado',
      status: EReportStatus.NotStarted
    },
    {
      name: 'Em progresso',
      status: EReportStatus.InProgress
    },
    {
      name: 'Com erro',
      status: EReportStatus.Error
    },
    {
      name: 'Concluído',
      status: EReportStatus.Concluded
    },
  ];

  constructor(private reportService: ReportService, public menuService: MenuService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.menuService.currentActiveMenu = 'generated-report';
    this.reportService.getControlReports().subscribe(reports => {
      this.controlReports = reports;
      this.isLoading = false;
    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }
}
