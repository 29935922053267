import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeesSummary } from '../models';
import { HttpResponse, HttpEvent, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  protected basePath = environment.apiEndpoint;
  private defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) 
  {
    this.basePath = environment.apiEndpoint;
  }


    public getSummary(lugarhCompanyId?: string, observe?: 'body', reportProgress?: boolean): Observable<EmployeesSummary>;
    public getSummary(lugarhCompanyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmployeesSummary>>;
    public getSummary(lugarhCompanyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmployeesSummary>>;
    public getSummary(lugarhCompanyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;
        if (lugarhCompanyId !== undefined && lugarhCompanyId !== null) 
        {
            headers = headers.set('lugarh-company-id', String(lugarhCompanyId));
        }

        return this.httpClient.get<EmployeesSummary>(`${this.basePath}/Share/Summary`,
            {
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    public remove(lugarhCompanyId?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public remove(lugarhCompanyId?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public remove(lugarhCompanyId?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public remove(lugarhCompanyId?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;
        if (lugarhCompanyId !== undefined && lugarhCompanyId !== null) 
        {
            headers = headers.set('lugarh-company-id', String(lugarhCompanyId));
        }

        return this.httpClient.delete<number>(`${this.basePath}/Share/Remove/${status}`,
            {
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
