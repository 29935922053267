import { PropertyConfig } from './property-config.model';
import { TemplateConfig } from './template-config.model';

export class SchemaField {
  public name: string = null;
  public type: string = null;
  public mask: string = null;
  public enum: Array<string> = null;
  public loadFields: Array<string> = null;
  public valueKeyName: Array<string> = null;
  public title: string = null;
  public widget: string = null;
  public pattern: string = null;
  public minimum: number = null;
  public required: boolean = null;
  public minLength: number = null;
  public maxLength: number = null;
  public default: string = null;
  public titleMap: TitleMap[] = null;
  public triggers: Array<Trigger> = null;
  public validationMessages: ValidationMessages = null;
  [key: string]: any;
}

export class Trigger {
  function: string = null;
  parameters: Array<string> = [];
}

export class TitleMap {
  public value?: string;
  public name?: string;
}

export class ValidationMessages {
  public pattern?: string;
  public required?: string;
  public min_length?: number;
  public max_length?: number;

  constructor(field?: { pattern?: string; required?: string; minLength?: number; maxLength?: number }) {
    this.pattern = field?.['pattern'] || '';
    this.required = field?.['required'] || '';
    this.min_length = field?.['minLength'] || 0;
    this.max_length = field?.['maxLength'] || 0;
  }
}

export class SchemaProperties {
  // * STRINGS
  public static readonly MASK = new SchemaProperties({ name: 'mask' }, '', null);
  static readonly WIDGET = new SchemaProperties({ name: 'widget' }, '', null);
  static readonly DEFAULT = new SchemaProperties({ name: 'default' }, '', null);
  static readonly PATTERN = new SchemaProperties({ name: 'pattern' }, '', null);
  static readonly TYPE = new SchemaProperties({ name: 'type', order: 1, label: 'Tipo', icon: 'pi-tag', fixed: true }, '', null);
  static readonly NAME = new SchemaProperties({ name: 'name', order: 0, label: 'Nome', icon: 'pi-id-card', fixed: true }, '', null);
  static readonly TITLE = new SchemaProperties({ name: 'title', order: 2, label: 'Título', icon: 'pi-question', fixed: true }, '', null);

  // * NUMBERS
  static readonly MINIMUM = new SchemaProperties({ name: 'minimum' }, 0, null);
  static readonly MIN_LENGTH = new SchemaProperties({ name: 'minLength' }, 0, null);
  static readonly MAX_LENGTH = new SchemaProperties({ name: 'maxLength' }, 0, null);

  // * BOOLEAN
  static readonly REQUIRED = new SchemaProperties(
    { name: 'required', order: 3, label: 'Obrigatoriedade', icon: 'pi-exclamation-triangle', fixed: true },
    false,
    null,
  );

  // * ARRAYS
  static readonly ENUM = new SchemaProperties(
    { name: 'ENUM', order: 1 },
    new Array<string>(),
    new TemplateConfig({ template: 'ENUM', subtitle: 'Informações do campo ENUM:' }),
  );

  static readonly LOAD_FIELDS = new SchemaProperties(
    { name: 'LOAD_FIELDS', order: 1 },
    new Array<string>(),
    new TemplateConfig({ template: 'LOAD_FIELDS', subtitle: 'Informações do campo LOAD FIELDS:' }),
  );

  static readonly VALUE_KEY_NAME = new SchemaProperties(
    { name: 'VALUE_KEY_NAME', order: 1 },
    new Array<string>(),
    new TemplateConfig({ template: 'VALUE_KEY_NAME', subtitle: 'Informações do campo VALUE KEY NAME:' }),
  );

  // * OBJECT
  static readonly TRIGGERS = new SchemaProperties(
    { name: 'TRIGGERS', order: 3 },
    new Trigger(),
    new TemplateConfig({ template: 'TRIGGERS', subtitle: 'Informações do campo TRIGGERS:' }),
  );

  static readonly TITLE_MAP = new SchemaProperties(
    { name: 'TITLE_MAP', order: 2 },
    new TitleMap(),
    new TemplateConfig({ template: 'TITLE_MAP', subtitle: 'Informações do campo TITLE MAP:' }),
  );

  static readonly VALIDATION_MESSAGES = new SchemaProperties(
    { name: 'VALIDATION_MESSAGES', order: 0 },
    new ValidationMessages(),
    new TemplateConfig({ template: 'VALIDATION_MESSAGES', subtitle: 'Informações do campo VALIDATION MESSAGES:' }),
  );

  // * ----------------------------------------------------
  // * Others - Do not interfere with the list

  static readonly FIXED_PROPERTIES = new SchemaProperties(
    { name: 'FIXED_PROPERTIES', type: 'list' },
    null,
    new TemplateConfig({ template: 'FIXED_PROPERTIES', title: 'Informações obrigatórias do campo:' }),
  );
  static readonly DYNAMIC_PROPERTIES = new SchemaProperties(
    { name: 'DYNAMIC_PROPERTIES', type: 'list' },
    null,
    new TemplateConfig({ template: 'DYNAMIC_PROPERTIES', title: 'Propriedades Principais:' }),
  );

  static readonly ADVANCED_PROPERTIES = new SchemaProperties(
    { name: 'ADVANCED_PROPERTIES', type: 'list' },
    null,
    new TemplateConfig({ template: 'ADVANCED_PROPERTIES', title: 'Propriedades Avançadas:' }),
  );

  // * ----------------------------------------------------

  // ! CONSTRUCTOR
  // * Private to disallow creating other instances of this type
  private constructor(private _propertyConfig: PropertyConfig, public value: any, public context?: TemplateConfig) {}

  // * ----------------------------------------------------
  // * FUNCTIONS

  public get name() {
    return this._propertyConfig?.name;
  }

  public get propertyConfig() {
    return this._propertyConfig;
  }

  // ! revisar
  public setValue(model: any) {
    this.value = this.propertyConfigList(model);
  }

  public setContextList(model: any) {
    this.context.list = this.propertyConfigList(model);
  }

  public setDropdownOptions(model: any, list: Array<any> = []) {
    let properties = list.length <= 0 ? this.propertyConfigList(model) : list;
    this.context.data.dropdownOptions.list = properties;
    this.context.data.dropdownOptions.totalProperties = properties.length;
    this.context.data.dropdownValues = this.context.data.dropdownValues ?? [];
  }

  public get getDropdownOptions() {
    return this.context.data.dropdownOptions;
  }

  propertyConfigList(model: any) {
    let properties = Object.keys(model ?? {})
      .map((instance) => {
        let prop = model[instance];
        let value = prop.value ?? prop;
        let name = `${this.name}-${instance.toLowerCase()}`;
        let typeOfValue = Array.isArray(value) ? 'array' : typeof value;

        if (instance === instance.toLocaleUpperCase()) {
          let obj = { ...prop._propertyConfig };
          obj.name = name;

          if (!obj.type) {
            obj.type = typeOfValue;
          }

          if (!obj.label) {
            obj.label = instance.toLowerCase();
          }

          return obj;
        } else {
          return { name: name, type: typeOfValue, label: instance.toLowerCase() };
        }
      })
      .filter((item: PropertyConfig) => {
        if (item.type != 'list') {
          switch (this.name) {
            case SchemaProperties.DYNAMIC_PROPERTIES.name:
              return !item?.fixed && !(item?.type == 'object' || item?.type == 'array');
            case SchemaProperties.FIXED_PROPERTIES.name:
              return !!item && !!item?.fixed;
            case SchemaProperties.ADVANCED_PROPERTIES.name:
              return item?.type == 'object' || item?.type == 'array';
            default:
              return item?.name;
          }
        }
      })
      .sort((a, b) => a.order - b.order);
    this.context.items = [1];
    this.context.data.dropdownValues = [];
    console.log('properties :>> ', properties);
    return properties ?? [];
  }

  public resetValuesToDefault() {
    if (this.context) {
      this.context = new TemplateConfig({
        template: this.context.template,
        title: this.context.title,
        subtitle: this.context.subtitle,
      });
    }
  }
}
