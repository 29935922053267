import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextMaskModule } from 'angular2-text-mask';
import { JsonSchemaFormModule, MaterialDesignFrameworkModule } from 'lugarh-json-schema-form';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { environment } from 'src/environments/environment';

import { AuthorizeComponent } from './app-authorize/app-authorize-component';
import { AppCompanyWorkspacesComponent } from './app-company-workspaces/app-company-workspaces.component';
import { AppWorkspaceAddressComponent } from './app-company-workspaces/app-workspace-company/app-workspace-address/app-workspace-address.component';
import { AppWorkspaceCompanyComponent } from './app-company-workspaces/app-workspace-company/app-workspace-company.component';
import { UsersWorkspaceCompanyComponent } from './app-company-workspaces/app-workspace-company/users-workspace-company/users-workspace-company.component';
import { WorkspaceExportComponent } from './app-company-workspaces/app-workspace-company/workspace-export/workspace-export.component';
import { AppCompanyInfoComponent } from './app-company/app-company-info/app-company-info.component';
import { AppCompanyComponent } from './app-company/app-company.component';
import { AppIntegrationsComponent } from './app-company/app-integrations/app-integrations.component';
import { AppQuotasComponent } from './app-company/app-quotas/app-quotas.component';
import { CompanyAddressComponent } from './app-company/company-address/company-address.component';
import { CompanyFormComponent } from './app-company/company-form/company-form.component';
import { ExportLayoutComponent } from './app-company/export-layout/export-layout.component';
import { ProfileScopeComponent } from './app-company/profile-scope/profile-scope.component';
import { SetupDigitalSignatureComponent } from './app-company/setup-digital-signature/setup-digital-signature.component';
import { AppEnterpriseListComponent } from './app-enterprise-list/app-enterprise-list.component';
import { ListSearchComponent } from './app-enterprise-list/list-search/list-search.component';
import { AppMaterialModule } from './app-material.module';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AddUserComponent } from './app-menu/user-management/add-user/add-user.component';
import { UserManagementComponent } from './app-menu/user-management/user-management.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContractConfigsComponent } from './components/contract-configs/contract-configs.component';
import { CreateContractComponent } from './components/contract-configs/create-contract/create-contract.component';
import { GeneratedReportComponent } from './components/report/generated-report/generated-report.component';
import { ShareReportComponent } from './components/report/share-report/share-report.component';
import { ModalAddSchemaFieldComponent } from './components/schema-config/modals/modal-add-schema-field/modal-add-schema-field.component';
import { ModalSelectWorkspaceComponent } from './components/schema-config/modals/modal-select-workspace/modal-select-workspace.component';
import { ModalViewFormComponent } from './components/schema-config/modals/modal-view-form/modal-view-form.component';
import { SchemaConfigComponent } from './components/schema-config/schema-config.component';
import { SchemaManagerComponent } from './components/schema-manager/schema-manager.component';
import { EmployeesModule } from './employees/employees.module';
import { AppHeaderComponent } from './shared/app-header/app-header.component';
import { UserMenuComponent } from './shared/app-header/user-menu/user-menu.component';
import { AppLoadingModalComponent } from './shared/app-loading-modal/app-loading-modal.component';
import { AppLoadingComponent } from './shared/app-loading/app-loading.component';
import { AppRedirectComponent } from './shared/app-redirect/app-redirect.component';
import { AppToastComponent } from './shared/app-toast/app-toast.component';
import { AuthInterceptor } from './shared/security/auth.interceptor';
import { LoaderService } from './shared/security/loader.service';
import { WorkspaceService } from './shared/services';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { AuthService } from './shared/services/auth.service';
import { AuthorizationService } from './shared/services/authorization.service';
import { CompanyService } from './shared/services/company.service';
import { queryCepService } from './shared/services/consult-cep.service';
import { ListSearchService } from './shared/services/list-search.service';
import { MenuService } from './shared/services/menu.service';
import { PlanService } from './shared/services/plan.service';
import { ShareExportService } from './shared/services/share-export.service';
import { SidenavService } from './shared/services/sidenav.service';
import { ToastService } from './shared/services/toast.service';
import { UserService } from './shared/services/user.service';
import { SharesModule } from './shares/shares.module';
import { AddUserCompanyComponent } from './users-companys/add-user-company/add-user-company.component';
import { UserCompanyComponent } from './users-companys/user-company/user-company.component';
import { UserDeletedComponent } from './users-companys/user-deleted/user-deleted.component';
import { CheckboxModule } from 'primeng/checkbox';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsManagementComponent } from './app-company/forms-management/forms-management.component';
import { ImplementFormModalComponent } from './app-company/forms-management/implement-form-modal/implement-form-modal.component';
import { WorkspaceCsComponent } from './app-company-workspaces/app-workspace-company/workspace-cs/workspace-cs.component';



export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppRedirectComponent,
    AuthorizeComponent,
    ListSearchComponent,
    UserMenuComponent,
    AppMenuComponent,
    AppEnterpriseListComponent,
    AppCompanyComponent,
    ProfileScopeComponent,
    AppLoadingModalComponent,
    AppIntegrationsComponent,
    AppQuotasComponent,
    AppLoadingComponent,
    AppCompanyInfoComponent,
    UserManagementComponent,
    AddUserComponent,
    AppToastComponent,
    CompanyFormComponent,
    UserCompanyComponent,
    UserDeletedComponent,
    AddUserCompanyComponent,
    SchemaManagerComponent,
    ContractConfigsComponent,
    CreateContractComponent,
    ShareReportComponent,
    GeneratedReportComponent,
    SetupDigitalSignatureComponent,
    ExportLayoutComponent,
    CompanyAddressComponent,
    AppCompanyWorkspacesComponent,
    AppWorkspaceCompanyComponent,
    AppWorkspaceAddressComponent,
    SchemaConfigComponent,
    WorkspaceExportComponent,
    ModalAddSchemaFieldComponent,
    UsersWorkspaceCompanyComponent,
    ModalViewFormComponent,
    ModalSelectWorkspaceComponent,
    FormsManagementComponent,
    ImplementFormModalComponent,
    WorkspaceCsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatInputModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTableModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatListModule,
    TextMaskModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    EmployeesModule,
    SharesModule,
    MatCardModule,
    MatSortModule,
    MatStepperModule,
    CdkAccordionModule,
    MatExpansionModule,
    AppMaterialModule,

    // PrimeNg Modules
    PanelModule,
    ButtonModule,
    CardModule,
    TableModule,
    ToolbarModule,
    InputTextModule,
    FileUploadModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    RadioButtonModule,
    AutoCompleteModule,
    DropdownModule,
    MultiSelectModule,
    PickListModule,
    DynamicDialogModule,
    CalendarModule,
    TabViewModule,
    ConfirmPopupModule,
    TagModule,
    BadgeModule,
    PaginatorModule,
    StepsModule,
    CheckboxModule,

    [
      NgxMaskModule.forRoot(),
    ],
    // Others
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      fullScreenBackdrop: true,
    }),
    TextMaskModule,
    MaterialDesignFrameworkModule,
    JsonSchemaFormModule.forRoot({ apiUrl: environment.apiEndpoint })
  ],
  providers: [
    AppRedirectComponent,
    AppHeaderComponent,
    ListSearchService,
    MenuService,
    SidenavService,
    AuthGuardService,
    AuthService,
    UserService,
    CompanyService,
    queryCepService,
    ShareExportService,
    PlanService,
    LoaderService,
    WorkspaceService,
    AuthorizationService,
    ConfirmationService,
    DialogService,
    ConfirmPopupModule,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ToastService,
    AppToastComponent
  ],
  entryComponents: [
    AddUserComponent,
    AppRedirectComponent,
    AppToastComponent,
    UserDeletedComponent,
    AddUserCompanyComponent,
    ContractConfigsComponent,
    ImplementFormModalComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
