<ngx-loading
  [show]="isLoading"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>
<p-toast position="top-right"></p-toast>

<div style="display: flex">
  <button
    (click)="enableForAllWorkspaces()"
    type="button"
    pButton
    pRipple
    label="Habilitar em todos os workspaces"
    icon="pi pi-check"
    class="mx-2"
  ></button>
  <button
    (click)="disableForAllWorkspaces()"
    type="button"
    pButton
    pRipple
    label="Desabilitar em todos os workspaces"
    icon="pi pi-ban"
    class="mx-2 p-button-warning"
  ></button>
</div>

<p-table
  #dt
  [value]="workspacesConfig"
  [rows]="10"
  [paginator]="true"
  [columns]="columns"
  [globalFilterFields]="['name', 'description']"
  responsiveLayout="scroll"
  [rowHover]="true"
  dataKey="id"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="pesquisar..."
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.label }}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <div class="workspace-label">
          <span>{{ rowData?.name }}</span>
          <span class="nickname">{{ rowData?.nickname }}</span>
        </div>
      </td>
      <td>
        <p-inputSwitch
          (onChange)="toggleForOneWorkspace($event, rowData.id)"
          [(ngModel)]="rowData.enabled"
        ></p-inputSwitch>
      </td>
      <td>
        <p-inputSwitch
          (onChange)="toggleEnableUpload$($event, rowData.id)"
          [(ngModel)]="rowData.isUploadEnabled"
        ></p-inputSwitch>
      </td>
    </tr>
  </ng-template>
</p-table>
