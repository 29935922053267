import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CompanySourcetModel } from 'src/app/shared/model/report/company-source.model';
import { ShareReportCmd } from 'src/app/shared/model/report/share-report.cmd';
import { ShareSourceModel } from 'src/app/shared/model/report/share-source.model';
import { MenuService } from 'src/app/shared/services';
import { ReportService } from 'src/app/shared/services/report/report.service';

@Component({
  selector: 'app-share-report',
  templateUrl: './share-report.component.html',
  styleUrls: ['./share-report.component.scss']
})
export class ShareReportComponent implements OnInit {

  isLoading: boolean = false;
  isLoadingCompany: boolean = false;
  isLoadingShare: boolean = false;

  rangeDates: Date[];
  sourceCompanies: CompanySourcetModel[] = [];
  targetCompanies: CompanySourcetModel[] = [];

  targetShares: ShareSourceModel[] = [];

  constructor(public menuService: MenuService,
    private reportService: ReportService, private messageService: MessageService, private router: Router) { }

  ngOnInit() {
    this.menuService.currentActiveMenu = 'share-report';

    this.isLoadingCompany = true;
    this.isLoadingShare = true;

    this.reportService.getCompaniesSource().subscribe(companies => {
      this.sourceCompanies = companies;
      this.isLoadingCompany = false;
    }, error => {
      this.showErrorMessage();
      console.error(error);
      this.isLoadingCompany = false;
    });
  }

  generateShareReport() {
    this.isLoading = true;

    let startDate: Date | null = null
    let endDate: Date | null = null;

    if (this.rangeDates && this.rangeDates.length >= 2) {
      startDate = this.rangeDates[0];
      endDate = this.rangeDates[1];
    }

    let shareReportCmd: ShareReportCmd = {
      startDate: startDate,
      endDate: endDate,
      companyIds: this.targetCompanies.map(x => x.id),
      shareIds: this.targetShares.map(x => x.id),
    };

    this.reportService.generateShareReport(shareReportCmd).subscribe(result => {
      console.log(result);
      if (result.success) {
        this.showSuccessMessage();
        setTimeout(() => {
          this.router.navigate(['/generated-report']);
          this.isLoading = false;
        }, 3000);
      } else {
        this.showErrorMessage();
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.showErrorMessage();
    });
  }

  showSuccessMessage() {
    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Gerando relatório...' });
  }

  showWarnMessage(message: string) {
    this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: message });
  }

  showErrorMessage() {
    this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Algo deu errado.' });
  }
}
