<section class="gradient-blue" fxLayout="row" fxLayoutAlign="center center">
    <div class="redirect__info">
      <h1 class="redirect__title" [innerHtml]="redirectTitle"></h1>
      <h6 class="redirect__text"  [innerHtml]="redirectText"></h6>
      <div fxLayout="row" fxLayoutAlign="center center" class="page__action">
          <button fxFlex="70" mat-raised-button color="accent" [innerHtml]="redirectButtonText" (click)="redirect();"></button>
      </div>
    </div>

</section>
