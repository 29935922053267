import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilKeyChanged, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';
import { EImportOptions } from 'src/app/shared/enums/e-import-option.enum';
import { AutocompleteItemV2 } from 'src/app/shared/model/autocomplete-item-v2.model';
import { MenuService } from 'src/app/shared/services';
import { SchemaService } from 'src/app/shared/services/schema.service';
import { Helper } from 'src/app/shared/utils/Helper';

@Component({
  selector: 'app-schema-manager',
  templateUrl: './schema-manager.component.html',
  styleUrls: ['./schema-manager.component.scss'],
})
export class SchemaManagerComponent implements OnInit {
  isLoading: boolean = false;
  first: number = 0;
  autocompleteItens: Array<AutocompleteItemV2> = [];
  cols: Array<{ header: string; field: string }> = [];

  @ViewChild(FileUpload) fileUpload!: FileUpload;
  @ViewChild('dt') table: Table;
  @ViewChild('paginator') paginator: Paginator;

  showDialogUpload: boolean = false;
  tableExampleData: Array<{
    companyIdText: string;
    workspaceIdText: string;
    sourceText: string;
    nameText: string;
  }> = [
    {
      companyIdText: 'Id da empresa',
      workspaceIdText: 'Id do workspace',
      sourceText: 'Fonte (Ex: cargos, departamentos etc)',
      nameText: 'Nome (será exibido como rótulo do selectbox)',
    },
  ];
  importOptions: EImportOptions | null = null;
  eImportOptions = EImportOptions;

  // Observable
  public totalRecords$ = new Observable<any>();
  public fgAutocompleteItemV2$ = new Observable<FormGroup>();

  // FormGroup
  private _fgAutocompleteItemV2: FormGroup;

  constructor(
    private _schemaService: SchemaService,
    private _messageService: MessageService,
    public menuService: MenuService,
    private _formBuilder: FormBuilder,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.menuService.currentActiveMenu = 'schemas';
    this._createFormGroup();
    this.onChangePage({ page: 0 });
    this.totalRecords$ = this._getTotalDocsAutocompleteItemV2$;
  }

  private _createFormGroup() {
    this._fgAutocompleteItemV2 = this._formBuilder.group({
      controlSearch: new FormControl(''),
    });

    this.fgAutocompleteItemV2$ = this._getUpdatedFormGroup$;
  }

  private get _getUpdatedFormGroup$(): Observable<FormGroup> {
    return this._fgAutocompleteItemV2.valueChanges.pipe(
      debounceTime(300),
      distinctUntilKeyChanged('controlSearch'),
      tap(() => (this.isLoading = true)),
      switchMap((controls) => {

        const observables$ = {
          0: this._schemaService.getDocsByPageAutocompleteItemV2(1),
          1: this._schemaService.getDocByIdAutocompleteItemV2(controls?.controlSearch),
        };

        return observables$[Number(!!controls?.controlSearch)]?.pipe(
          take(1),
          tap((response: any) => this.bindTable(Array?.isArray(response) ? response : [response])),
          catchError((err) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: `Oops! Não foi possível ${controls?.controlSearch ? 'encontrar o' : 'carregar os'} schema...`,
            });
            console.log('err :>> ', err);
            return of(this._fgAutocompleteItemV2);
          }),
        );
      }),
      tap(() => (this.isLoading = false)),
      startWith({}),
      mergeMap(() => of(this._fgAutocompleteItemV2)),
    );
  }

  private get _getTotalDocsAutocompleteItemV2$(): Observable<any> {
    this.isLoading = true;
    return this._schemaService.getTotalDocsAutocompleteItemV2().pipe(
      catchError((err) => {
        this.isLoading = false;
        this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Não foi possível obter o total de schemas...' });
        return throwError(err);
      }),
    );
  }

  public onChangePage(event) {
    this.isLoading = true;
    this._schemaService
      .getDocsByPageAutocompleteItemV2(++event.page)
      .pipe(
        tap((response) => {
          this.bindTable(response);
          this.isLoading = false;
        }),
        catchError((err) => {
          this.isLoading = false;
          this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Não foi possível carregar os schemas...' });
          return throwError(err);
        }),
      )
      .subscribe();
  }

  uploadCsvFile(event) {
    if (this.importOptions == null) {
      this._messageService.add({ severity: 'warn', summary: 'Erro', detail: 'Selecione uma opção de importação.' });
      return;
    }

    this.isLoading = true;
    let files = event.files;
    if (files.length > 0) {
      let file = files[0] as File;
      this._schemaService.importCsvAutocompleteItemV2(file, this.importOptions).subscribe(
        (autocompleteItens) => {
          this.fileUpload.clear();
          this.onChangePage({ page: 0 });
          this.isLoading = false;
          this.showDialogUpload = false;
          this.importOptions = null;
          this._messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Arquivo importado com sucesso!' });
        },
        (error) => {
          this.fileUpload.clear();
          this.showDialogUpload = false;
          this.importOptions = null;
          this.isLoading = false;
          this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Algo deu errado.' });
          console.error(error);
        },
      );
    }
  }

  cancelUpload() {
    this.fileUpload.clear();
    this.importOptions = null;
    this.showDialogUpload = false;
  }

  deleteItem(itemId: string) {
    this.isLoading = true;
    this._schemaService.deleteAutocompleteItemV2(itemId).subscribe(
      (result) => {
        if (result) {
          this._messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Arquivo deletado com sucesso!' });
          this.onChangePage({ page: 0 });
        } else {
          this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Algo deu errado.' });
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Oops! Algo deu errado.' });
        console.error(error);
      },
    );
  }

  showUploadItem() {
    this.showDialogUpload = true;
  }

  confirmDeleteItem(itemId: string) {
    this._confirmationService.confirm({
      message: 'Tem certeza que deseja deletar esse item?',
      acceptLabel: 'Sim',
      header: 'Deletar item',
      rejectLabel: 'Não',
      accept: () => {
        this.deleteItem(itemId);
      },
    });
  }

  bindTable(autocompleteItens: Array<AutocompleteItemV2>) {
    this.autocompleteItens = autocompleteItens;
    if (this.autocompleteItens.length > 0) {
      let allCols: Array<{ header: string; field: string }> = [];
      this.autocompleteItens.forEach((element) => {
        let cols = this.extractKeysFields(element);
        cols.forEach((col) => {
          let containsCol = allCols.find((x) => x.header == col.header && x.field == col.field) != null;
          if (!containsCol) allCols.push(col);
        });
      });
      this.cols = allCols;
    }
  }

  extractKeysFields(obj: any) {
    let self = this;
    let keysFields: Array<{ header: string; field: string }> = [];

    Object?.keys(obj || {})?.forEach((property) => {
      if (typeof obj[property] == 'object') {
        let keys = self?.extractKeysFields(obj[property]);
        keys?.forEach((innerItem) => {
          keysFields.push({
            header: innerItem.header,
            field: `${property}.${innerItem.field}`,
          });
        });
      } else {
        keysFields?.push({
          header: Helper.firsToUpperCase(property),
          field: property,
        });
      }
    });

    return keysFields;
  }

  notImplemented() {
    this._messageService.add({ severity: 'warn', summary: 'Oops!', detail: 'Essa funcionalidade ainda não foi implementada.' });
  }
}
