import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, finalize, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { WorkspaceService } from 'src/app/shared/services';
import { CompanyService } from 'src/app/shared/services/company.service';

import { ValidateCNPJ } from '../../../shared/forms/validators/cnpj.validator';
import { LoaderService } from '../../../shared/security/loader.service';

@Component({
  selector: 'workspace-cs',
  templateUrl: './workspace-cs.component.html',
  styleUrls: ['./workspace-cs.component.scss'],
})
export class WorkspaceCsComponent implements OnInit {
  public readonly maskCNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public isViewMode = true;
  public fgWorkspaceCs: FormGroup;

  public fcCNPJ$ = new Observable<any>();
  public workspaceIntegration$ = new Observable<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _loaderService: LoaderService,
    private _companyService: CompanyService,
    private _activatedRouter: ActivatedRoute,
    private _workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.fgWorkspaceCs = this._formBuilder.group({
      fcCS: ['', [Validators.required]],
      fcCNPJ: ['', [Validators.required, ValidateCNPJ]],
    });

    this.fcCNPJ$ = this._getFcCNPJUpdated$;
    this.workspaceIntegration$ = this._getWorkspaceIntegrationUpdated$;
  }

  public cancelSubmit() {
    this.toggleViewMode();
  }

  public toggleViewMode(): void {
    this.isViewMode = !this.isViewMode;
  }

  public getErrorMessage(controlName: string): string {
    const errorMessages = {
      cnpj: 'CNPJ informado é inválido, por favor, tente novamente.',
      exist: 'CNPJ informado já esta cadastrado, por favor, tente novamente.',
      required: 'Preenchimento obrigatório.',
    };

    const control = this.fgWorkspaceCs?.get(controlName);
    const typeError = Object?.keys(control?.errors || {})?.find((key) => !!control?.errors[key]);

    return errorMessages[typeError] || '';
  }

  public submitFormData() {
    this._activatedRouter?.params
      ?.pipe(
        tap(() => this._loaderService.show()),
        map((params) => ({
          send: {
            CS: this.fgWorkspaceCs?.value?.fcCS,
            CNPJ: this.fgWorkspaceCs?.value?.fcCNPJ,
          },
          workspaceId: params?.workId,
        })),
        switchMap((data) =>
          this._workspaceService.putWokspaceIntegration(data?.send, data?.workspaceId).pipe(
            catchError((err) => {
              console.error('submitFormData error :>> ', err);
              return of();
            }),
          ),
        ),
        delay(1000),
        tap(() => this.toggleViewMode()),
        switchMap(() => this._getWorkspaceIntegrationUpdated$),
        finalize(() => this._loaderService.hide()),
      )
      .subscribe();
  }

  private get _getFcCNPJUpdated$() {
    return this.fgWorkspaceCs.get('fcCNPJ')?.valueChanges?.pipe(
      tap(() => {
        const oldErrors = this.fgWorkspaceCs?.get('fcCNPJ')?.errors;
        this.fgWorkspaceCs.get('fcCNPJ')?.setErrors({ ...oldErrors, loading: true });
      }),
      debounceTime(500),
      filter((data) => !!data?.replace(/\D/g, '')),
      tap(() => this._loaderService.show()),
      switchMap((fcCNPJ) =>
        this._companyService?.verifyCnpjObservable(fcCNPJ)?.pipe(
          tap((response) => {
            const oldErrors = this.fgWorkspaceCs.get('fcCNPJ')?.errors;
            this.fgWorkspaceCs.get('fcCNPJ')?.setErrors(response ? { exist: true } : oldErrors?.cnpj ? oldErrors : null);
          }),
          catchError(() => {
            this.fgWorkspaceCs.get('fcCNPJ')?.setErrors({ cnpj: true });
            return of('fcCNPJ');
          }),
          mergeMap(() => of('fcCNPJ')),
          finalize(() => this._loaderService.hide()),
        ),
      ),
      startWith('fcCNPJ'),
    );
  }

  private get _getWorkspaceIntegrationUpdated$() {
    return this._activatedRouter?.params?.pipe(
      tap(() => this._loaderService.show()),
      switchMap(({ id, workId }) => {
        return combineLatest([this._companyService.getOne(id), this._workspaceService.GetWorkspaceById(workId)]).pipe(
          switchMap(([company, workspace]) => {
            const hasIntegration = !!company?.integrations.hasOwnProperty('WebFopag') || !!company?.integrations['WebFopag'];

            this.fgWorkspaceCs.get('fcCS')?.setValue(workspace?.integrations['webFopag']?.cs, { emitEvents: false });
            this.fgWorkspaceCs.get('fcCNPJ')?.setValue(workspace?.integrations['webFopag']?.cnpj, { emitEvents: false });

            if (hasIntegration && !workspace?.integrations['webFopag']) {
              workspace.integrations['webFopag'] = {};
              workspace.integrations['webFopag'].cs = company?.integrations['WebFopag']?.CS;
              workspace.integrations['webFopag'].cnpj = company?.integrations['WebFopag']?.CNPJ;
            }

            return of([hasIntegration, workspace?.integrations['webFopag']]);
          }),
          map((data) => ({ canShow: data[0], data: data[1] })),
          finalize(() => this._loaderService.hide()),
        );
      }),
      startWith([]),
    );
  }
}
