import { AbstractControl } from '@angular/forms';


export function ValidateCNPJ(control: AbstractControl): any
{
    const regex = /\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}/g;

    if (!regex.test(control.value)) {
        return { cnpj: true };
    }

    const _array_cnpj    = get_array_int(control.value);
    const _original_cnpj = get_array_int(control.value);

    const _first_step = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    
    let _step_total = 0;
    let _index = 0;
    
    _first_step.forEach(num => {
        _step_total += (num * _array_cnpj[_index]);
        _index++;
    });
    
    let _rest = (_step_total % 11);
    let _validator = (_rest < 2) ? 0 : 11 - _rest;

    _array_cnpj[12] = _validator;

    const _second_step = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    _step_total = 0;
    _index = 0;
    
    _second_step.forEach(num => {
        _step_total += (num * _array_cnpj[_index]);
        _index++;
    });

    _rest = (_step_total % 11);
    _validator = (_rest < 2) ? 0 : 11 - _rest;
    _array_cnpj[13] = _validator;

    if (!arrays_equals(_original_cnpj, _array_cnpj)) {
        return { cnpj: true };
    }

    return null;
}


function get_array_int(formatted_cnpj: string): Array<number>
{
    let _array: Array<number> = new Array<number>();

    const regex = /\d/g;
    const str = formatted_cnpj;
    let m;

    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            _array.push(parseInt(match));
        });
    }

    return _array;
}

function arrays_equals(arr1, arr2): boolean
{
    if(arr1.length !== arr2.length)
        return false;
    for(var i = arr1.length; i--;) {
        if(arr1[i] !== arr2[i])
            return false;
    }

    return true;
}