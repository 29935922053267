export const environment = {
  production: false,

  //Api
  apiEndpoint: 'https://apihom.lugarh.com.br',

  //empresa Url
  empresa_url: 'https://hom-empresa.lugarh.com.br/',

  /** Identity Server Configuration */
  IdentityServerConfig: {
    acr_values: 'tenant:lugarh-adm',
    authority: 'https://testing-id.bne.com.br/',
    redirect_url: 'https://hom.adm.lugarh.com.br/#/authorize#',
    post_logout_redirect_uri: 'https://hom.adm.lugarh.com.br/Unauthorized',
    client_id: 'LugarhAdm'
  },
  /** FIM Identity Server Configuration */

};
