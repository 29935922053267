import { Component, OnInit, Input } from '@angular/core';
import { PlanService } from '../../shared/services/plan.service';
import { Plan } from '../../shared/model/plan.model';

@Component({
  selector: 'app-quotas',
  templateUrl: './app-quotas.component.html',
  styleUrls: ['./app-quotas.component.scss']
})
export class AppQuotasComponent implements OnInit {

  constructor(
    private planService: PlanService,
  ) { }

  public isLoading: boolean = false;

  //Quotas
  public quota: any;
  public plan: Plan;
  public newShareQuota: number;
  public newAttachmentQuota: number;

  public quotaLoading: boolean = false;
  public editShareQuota: boolean = false;
  public editAttachmentQuota: boolean = false;
  public quotaBtnLoading: boolean = false;

  public companyId: string;
  @Input('companyId')
  public set setCompanyId(value: string)
  {
    this.companyId = value;
    this.LoadQuotas();   
  }

  ngOnInit() {
  }

  public ShareQuotaUpdating() {
    this.editShareQuota = !this.editShareQuota;
  }

  public AttachmentQuotaUpdating() {
    this.editAttachmentQuota = !this.editAttachmentQuota;
  }

  public onUpdateShareQuotaKeyDown(evt: any): void{
    this.newShareQuota = parseInt(evt.target.value);
    if(evt.key === "Enter")
    {
      event.preventDefault();
      this.SaveShareQuota();
    }
  }

  public onUpdateAttachmentQuotaKeyDown(evt: any): void{
    this.newAttachmentQuota = parseInt(evt.target.value);
    if(evt.key === "Enter")
    {
      event.preventDefault();
      this.SaveAttachmentQuota();
    }
  }

  //Save new share quota company
  public async SaveShareQuota(){
    this.isLoading = true;
    if(await this.planService.UpdateShareQuotasEntity(this.companyId, this.newShareQuota))
    {
      this.editShareQuota = false;
      await this.LoadQuotas();
    }
    this.isLoading = false;
    this.editShareQuota = false;
  }

  
  //Save new attachment quota
  public async SaveAttachmentQuota(){
    this.isLoading = true;
    if(await this.planService.UpdateAttachmentQuotasEntity(this.companyId, this.newAttachmentQuota))
    {
      this.editAttachmentQuota = false;
      await this.LoadQuotas();
    }
    this.isLoading = false;
    this.editAttachmentQuota = false;
  }

  //Reload company quotas
  public async LoadQuotas() {
    this.quota = await this.planService.GetQuotasLoggedCompany(this.companyId);
  }
  public get PlanName() :string {
    return this.quota.ShareQuota.avaiable != 10 ? "Personalizado" : "Gratis";  
  }

}
