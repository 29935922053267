import { AbstractControl } from '@angular/forms';

export function ValidatePassword(control: AbstractControl): any {
    
    if (control.value != null) {

        var str = control.value.trim().split(/\s+/g);
        if(!String(str).match("[A-z]") || !String(str).match("[0-9]")){
            return { password: true };
        }
    }
    return null;
}