import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Company } from 'src/app/shared/model/company.model';

import { CompanyService } from '../../shared/services/company.service';
import { ValidateCNPJ } from '../../shared/forms/validators/cnpj.validator';

@Component({
  selector: 'app-company-info',
  templateUrl: './app-company-info.component.html',
  styleUrls: ['./app-company-info.component.scss']
})
export class AppCompanyInfoComponent implements OnInit {

  constructor(
    private companyService: CompanyService,
    private formBuilder: FormBuilder
  ) { }

  public company: Company;
  @Input('company')
  public set setCompanyId(value: Company)
  {
    this.company = value;
    this.configForm();
  }

  public isLoading: boolean = false;
  public isRefresh:boolean = false;

  //form
  public companyUpdating: boolean = false;
  public isCnpjLoading: boolean = false;
  public companyForm: FormGroup;

  ngOnInit() {
  }

  public async changeBlockStatus(block: boolean){
    this.isLoading = true;
    await this.companyService.BlockCompany(this.company.id, block);
    this.company.blocked = block;
    this.isLoading = false;    
  }

  public async RefreshCompanyInfos(){
    this.isRefresh = true;
    await this.companyService.ReindexOne(this.company.id);
    await this.requestLoadCompany();
    this.isRefresh = false;
  }

  public showFormCompany(){
    this.companyUpdating = !this.companyUpdating;
  }

  //#region Edit Company Form
  public async EditCompany(){
    if(!this.isFormValid || this.isCnpjLoading)
      return; 
    this.isLoading = true;
    var data = {
      name: this.companyForm.controls.name.value,
      cnpj: this.companyForm.controls.cnpj.value
    }
    this.companyService.UpdateOne(this.company.id, data).then((resp) =>{
      this.company = resp;
      this.isLoading = false;
      this.companyUpdating = false;
    });
  }

  public verifyCNPJ(){
    var cnpj = this.companyForm.controls.cnpj;
    if(cnpj.value != this.company.cnpj && !cnpj.hasError('cnpj') && !cnpj.hasError('required'))
    {
      this.isCnpjLoading = true;
      this.companyService.verifyCnpj(cnpj.value).then((resp)=>{
        if(resp == true){
          this.companyForm.controls.cnpj.setErrors({"exists": true })
        }
        this.isCnpjLoading = false;
      });
    }      
  }

  public get getCnpjErrorMessage(): string {
    return this.companyForm.controls.cnpj.hasError('required') ? 'Preencha o CNPJ da empresa.' :
      this.companyForm.controls.cnpj.hasError('cnpj') ? 'O CNPJ é inválido.' :
        this.companyForm.controls.cnpj.hasError('exists') ? 'Este CNPJ já está cadastrado no Lugarh.' : '';
  }

  public get isFormValid(): boolean{
    if(!this.companyForm.invalid && (this.companyForm.controls.cnpj.value != this.company.cnpj || this.companyForm.controls.name.value != this.company.name) )
    return true;
  return false;
  }
  //#endregion Edit Company Form

  private requestLoadCompany()
  {
    this.companyService.getOne(this.company.id).subscribe(company =>
    {
      this.company = company;
      this.configForm();
    });
  }

  private configForm(){
    this.companyForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required, Validators.minLength(3)
      ]),
      cnpj: new FormControl('', [
        Validators.required, ValidateCNPJ
      ])
    });
    this.companyForm.controls.name.setValue(this.company.name);
    this.companyForm.controls.cnpj.setValue(this.company.cnpj);
  }

}
