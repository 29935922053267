<section>
  <!--head-->
  <div class="modal__close" fxFlexFill fxLayoutAlingn="end none">
    <div style="display: flex;
    justify-content: flex-end;">
      <button mat-icon-button matTooltip="Cancelar" matTooltipPosition="right" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="modal__content">
    <h3 fxFlex="100" class="modal__title">{{text.title}}</h3>
    <h6 fxFlex="100" class="modal__subtitle">{{text.subtitle}}</h6>
    <mat-horizontal-stepper [linear]="false" #stepper class="Stepper__Style">
      <!--body-->
      <mat-step [stepControl]="firstFormGroup">
        <!--first data group-->
        <h6 fxxFlex="100" style="margin: 10px 5px 15px"><strong> Dados do usuário</strong></h6>
        <form [formGroup]="userForm" fxFlex="100">
          <ng-template matStepLabel>Dados do usuário</ng-template>
          <div class="content">
            <div class="form__field">
              <p class="label">Email Corporativo</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="email" placeholder="Email" matInput value="{{Email__In_Use}}" (blur)="verifyEmail();" [maxlength]="EMAIL_MAX_LENGTH" formControlName="email">
                <span class="form__loading">
                  <mat-spinner *ngIf="_is_loading_email" color="primary" diameter="32" strokeWidth="4" mode="indeterminate"></mat-spinner>
                  <i style="cursor:pointer" (click)="resetAll();" *ngIf="_is_email_already_inUse && modalAction == 0 " class="material-icons">close</i>
                </span>
                <mat-error>
                  Preencha um e-mail válido.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form__field">
              <p class="label">Nome Completo</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="text" placeholder="Nome Completo" matInput [maxlength]="NAME_MAX_LENGTH" formControlName="name">
                <mat-error *ngIf="userForm.controls.name.invalid  && userForm.controls.name.touched" [innerHtml]="text.error.name"></mat-error>
              </mat-form-field>
            </div>
            <div class="form__field">
              <p class="label">Celular</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="tel" placeholder="Celular" matInput [textMask]="{mask: mask.telefone, keepCharPositions: true}" formControlName="cellphone">
                <mat-error>Preencha um celular válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="form__field">
              <p class="label">Data de Nascimento</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="date" matInput formControlName="birthdate" placeholder="00/00/0000" autocomplete="off" max="9999-12-31">
                <mat-error>
                  Preencha com uma data válida.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form__field">
              <p class="label">CPF</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="text" placeholder="CPF" matInput [textMask]="{mask: mask.cpf, keepCharPositions: true}" formControlName="cpf">
                <mat-error>Preencha um CPF válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="form__field ">
              <p class="label">Função</p>
              <mat-form-field fxFlexFill [floatLabel]="floatLabel">
                <input type="text" placeholder="Função" matInput [maxlength]="OCCUPATION_MAX_LENGTH" formControlName="occupation">
                <mat-error>Preencha com uma função válida.</mat-error>
              </mat-form-field>
            </div>
            <div class="form__field " fxLayout="column">
              <p class="label">Sexo</p>
              <mat-radio-group fxLayoutAlign="space-evenly none" formControlName="gender">
                <mat-radio-button class="radioButton" color="primary" value="M">Masculino</mat-radio-button>
                <mat-radio-button class="radioButton" color="primary" value="F">Feminino</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" class="modal__actions">
            <button mat-button matStepperNext mat-raised-button fxFlexFill [disabled]="isInvalid" color="accent">Proxima
              Etapa</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Workspace">
        <form>
          <mat-label style="padding:10px 0 20px;"><strong>{{text.editWork}}</strong></mat-label>
          <div class="workspace">
            <div class="inputGroup">
              <mat-form-field fxFlexFill>
                <mat-label>Nome do workspace</mat-label>
                <input type="text" matInput [formControl]="workNameControl" [matAutocomplete]="auto" [(ngModel)]="filterName">
                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="filterName=''">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='add($event.option.value)'>
                  <mat-option *ngFor="let workspace of filteredOptions | async" [value]="workspace">
                    <div class="workspace-label">
                      <span>{{workspace?.name}}</span>
                      <span class="nickname">{{workspace?.nickname}}</span>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="mat-check">
              <mat-checkbox color="primary" (change)="onAddAllWorkspacesClick($event)"  [(ngModel)]="addAllWorkspaces" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
              <div class="labelCheck">Adicionar todos workspaces</div>
            </div>
            <div class="Card__Group">
              <mat-chip-list class="OverflowChipList">
                <mat-chip class="Card__User" *ngFor="let workspace of optionsSelected" (removed)="remove(workspace)">
                  <mat-icon class="Close__Chip" style="color: white;" matChipRemove>cancel</mat-icon>
                  <div class="workspace-label">
                    <span>{{workspace?.name}}</span>
                    <span class="nickname-small">{{workspace?.nickname}}</span>
                  </div>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="workspaceAdmin">
            <mat-slide-toggle (change)="ChangeWorkspaceAdminStatus($event);" color="primary" style="cursor: pointer;" [checked]="isWorkspaceAdmin">Usuário é nível Admin nos Workspaces?
            </mat-slide-toggle>
          </div>
          <div class="button">
            <div fxLayout="row" fxLayoutAlign="center" class="modal__actions ">
              <button mat-raised-button fxFlexFill color="accent" [disabled]="isInvalid || !hasOptionSelected || isInProgress" (click)='onInputUserClick();'>
                <span *ngIf="!isInProgress && !this.data.id">Adicionar usuário</span>
                <span *ngIf="isInProgress && !this.data.id" disabled>Adicionando</span>
                <span *ngIf="!isInProgress && this.data.id">Editar usuário</span>
                <span *ngIf="isInProgress && this.data.id" disabled>Editando</span>
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup" label="Perfil de Usuário">
        <form [formGroup]="authorizationForm">
          <mat-label style="padding:10px 0 20px;"><strong>{{thirdTitle}}</strong></mat-label>

          <mat-radio-group fxLayoutAlign="space-evenly none" formControlName="profileOfUser">
            <mat-radio-button *ngFor="let profile of profiles" class="radioButton" color="primary" value="{{profile.id}}">{{profile.name}}</mat-radio-button>
          </mat-radio-group>

          <div class="button">
            <div fxLayout="row" fxLayoutAlign="center" class="modal__actions ">
              <button mat-raised-button fxFlexFill color="accent" [disabled]="isInvalid || !hasOptionSelected || isInProgress" (click)='onInputUserClick();'>
                <span *ngIf="!isInProgress && !this.data.id">Adicionar usuário</span>
                <span *ngIf="isInProgress && !this.data.id" disabled>Adicionando</span>
                <span *ngIf="!isInProgress && this.data.id">Editar usuário</span>
                <span *ngIf="isInProgress && this.data.id" disabled>Editando</span>
              </button>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>





