import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../model/userInfo.model';
import { Observable } from 'rxjs';
import { UserQuery } from '../model/user-query.model';
import { UpdateUserInfoCmd } from '../model/update-user-info.cmd';

@Injectable()
export class UserService {
    
  readonly _userInfoLocalStorageItemName: string = 'UserService:user_info';
  readonly _userListLocalStorageItemName: string = 'UserService:user_list';

  constructor(private http: HttpClient) { }

  private _setUserInfo(value: UserInfo) : void  {
      localStorage.setItem(this._userInfoLocalStorageItemName, JSON.stringify(value));
  }

  public get getUserInfo(): UserInfo {
      var data = localStorage.getItem(this._userInfoLocalStorageItemName);
      if (data != "undefined")
          return JSON.parse(localStorage.getItem(this._userInfoLocalStorageItemName));
      else
          return null;
  }

  public async GetLoggedUserInfo() : Promise<UserInfo>    {
      return new Promise<UserInfo>((resolve, reject) => {
          this.http.get(environment.apiEndpoint + '/User/GetLoggedUserInfo')
            .subscribe((resp: UserInfo) => {
              this._setUserInfo(resp);
              resolve(resp);
            },
            err => {
              console.log('Something went wrong when getting list of companies!', err);
              reject(err);
          });
      });
  }

  public async GetUserInfo(id: string) : Promise<UserInfo>    {
      return new Promise<UserInfo>((resolve, reject) => {
          this.http.get(environment.apiEndpoint + '/User/GetUserInfo/' + id)
            .subscribe((resp: UserInfo) => {
              resolve(resp);
            },
            err => {
              console.log('Something went wrong when getting list of companies!', err);
              reject(err);
          });
      });
  }

  public async GetUserListAdmin(pageSize: number, page: number) : Promise<UserQuery>{
    return new Promise<UserQuery>((resolve, reject) => {
        this.http.get(environment.apiEndpoint + '/User/GetAllAdmin?pageNumber=' + page + '&pageSize=' + pageSize)
        .subscribe((resp: UserQuery) => {
            resolve(resp);
            localStorage.setItem(this._userListLocalStorageItemName, JSON.stringify(resp.users));
        },
        err => {
            console.log('Something went wrong when getting list of Users!', err);
            reject(err);
        });
    });       
  }

  public async GetAllUserFromCompany(lugarhCompanyId : string): Promise<UserInfo[]> {

    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', lugarhCompanyId);
    return new Promise<UserInfo[]>((resolve, reject) => {
      this.http.get(environment.apiEndpoint + '/User/GetAll', {headers : heads})
          .subscribe((resp: UserInfo[]) => {
            resolve(resp);
            return resp;
          },
          err => {
            console.error('Something went wrong when getting list of Users!', err);
            reject(err);
          });
    });
  }

  public UserExists(email: string) : Observable<UserInfo>    
  {
      return this.http.get<UserInfo>(`${environment.apiEndpoint}/User/UserExists/${email}`);
  }

  public async AddUserCompany(userId: string, companyId: string) : Promise<boolean>    {
    return new Promise<boolean>((resolve, reject) => {
        this.http.post(environment.apiEndpoint + '/Company/AddUser/' + companyId + '/' + userId, null)
          .subscribe((resp: UserInfo) => {
            resolve(true);
          },
          err => {
            if(err.status == 400)
            {
              console.log(err.message, err);
              resolve(false)
            }
            else{
              console.log('Something went wrong when getting list of companies!', err);
              reject(err);
            }
        });
    });
  }

  public async RemoveUserCompany(userId: string, companyId: string) : Promise<boolean> {
    let headers = new HttpHeaders();
    headers = headers.set('lugarh-company-id', companyId);

    return new Promise<boolean>((resolve, reject) => {
        this.http.post(environment.apiEndpoint + '/Company/RemoveUser/' + companyId + '/' + userId, null, { headers })
          .subscribe((resp: UserInfo) => {
            resolve(true);
          },
          err => {
            if(err.status == 404)
            {
              console.log(err.message, err);
              resolve(false)
            }
            else{
              console.log('Something went wrong when getting list of companies!', err);
              reject(err);
            }
        });
    });
  }

  public async AddUserAdmin(cmd: any) : Promise<boolean>    {
    return new Promise<boolean>((resolve, reject) => {
        this.http.post(environment.apiEndpoint + '/User/CreateUserAdmin/', cmd)
          .subscribe((resp: any) => {
            resolve(true);
          },
          err => {
            if(err.status == 400)
            {
              console.log(err.message, err);
              resolve(false)
            }
            else{
              console.log('Something went wrong when getting list of companies!', err);
              reject(err);
            }
        });
    });
  }

  public async DeleteUserAdmin(id: string) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.delete(`${environment.apiEndpoint}/User/DeleteUserAdmin/${id}`)
        .subscribe(() => {
          resolve(true);
        },
        err => {
          if (err.status == 400) {
            resolve(false);
          } else {
            reject(err);
          }
        });
    });
  }

  public async AddNewUser(data: any, companyId: string): Promise<any> {
    let heads = new HttpHeaders();
    heads = heads.set('lugarh-company-id', companyId);
    return new Promise<any>((resolve, reject) => {
        this.http.post(environment.apiEndpoint + '/User/AddUserToCompany', data, {headers: heads})
          .subscribe((resp: any) => {
              resolve(resp);
              return resp;
          },
          err => {
              reject(err);
          });
    });
  }

  public async UpdateUserInfo(data: UpdateUserInfoCmd): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.http.post(environment.apiEndpoint + '/User/UpdateUserInfo', data)
          .subscribe((resp: any) => {
              resolve(resp);
              return resp;
          },
          err => {
              reject(err);
          });
    });
  }

}