import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  public showUserMenu: boolean = false;
  constructor(
    public menuService: MenuService, 
    public userService: UserService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  public loggout()
  {
    this.authService.logout();
  }
}
