<div class="card">
  <div class="workspace__list">
    <div class="card__title">
      Workspaces
    </div>
  </div>
  <mat-card>
    <table class="workspaces__table">
      <tr class="workspaces__table__header">
        <td>
          <p>Nome</p>
        </td>
        <td>
          <p>CNPJ</p>
        </td>
        <td>
          <p>Colaboradores</p>
        </td>
        <td>
          <p>Ações</p>
        </td>
      </tr>
      <tr *ngFor="let workspace of workspaces" class="workspace">
        <td>
          <p class="workspace__td" [innerHtml]="workspace.name"></p>
        </td>
        <td>
          <p class="workspace__td --cnpj" *ngIf="workspace.cnpj">{{ workspace.cnpj }}</p>
          <p class="workspace__td --NotCnpj" *ngIf="!workspace.cnpj">Nao há cnpj cadastrado</p>
        </td>
        <td _ngcontent-ofy-c18="">
          <span class="names" [innerHtml]="workspace.sharesCount"></span>
        </td>
        <td>
          <div>
            <mat-icon title="Editar Workspace" (click)="WorkClick(workspace.id)">create</mat-icon>
            <mat-icon class="trash" *ngIf="workspace.root == false"
              title="Você não pode excluir um workspace que tenha colaboradores adicionados.">delete</mat-icon>
            <mat-icon (click)="formsManagement(workspace.id)">post_add</mat-icon>
          </div>
        </td>
      </tr>
    </table>
    <!-- <mat-paginator #exportPaginator [length]="total" [pageSize]="size" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
              </mat-paginator> -->
  </mat-card>
</div>