import { EventEmitter, Injectable, Injector, Output, Directive } from '@angular/core';
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
import { EmployerAuth } from 'loginemployer/dist/employerAuth';
import { Settings } from 'loginemployer/dist/settings';
import { JwtHelper } from './../utils/JWTHelper';
import { UserService } from './user.service';


/**
 * Service to manage user authentication
 */
@Directive()
@Injectable()
export class AuthService {

    @Output() onCompleteAuthentication = new EventEmitter<boolean>();
    private readonly lugarhId = 'lugarhAdmAuth';
    private static _instance: AuthService;
    private tokenInfo: TokenInfo;
    private auth = new EmployerAuth(this.settings); //creates iframe

    constructor(private userService: UserService){
        AuthService._instance = this;
        this.loadTokenInfoFromLocalStorage();
        this.eventListener();
    }

    private get settings(): any{
		let id = this.lugarhId;
        let authorityUri = environment.IdentityServerConfig.authority;
        let clientId = environment.IdentityServerConfig.client_id;
        let redirectUri = environment.IdentityServerConfig.redirect_url;
        let logoutRedirectUri = environment.IdentityServerConfig.post_logout_redirect_uri;
        let scope = 'openid profile';
        let acrValues= environment.IdentityServerConfig.acr_values;

        return new Settings(id, authorityUri, clientId, redirectUri, logoutRedirectUri, scope, acrValues);
	}

	private loadTokenInfoFromLocalStorage(): void
	{
		let tokenInfo_str: string = localStorage.getItem("token_info");
		if(tokenInfo_str !== "" && tokenInfo_str !== null && tokenInfo_str !== undefined)
			this.tokenInfo = JSON.parse(tokenInfo_str);
		else
			this.tokenInfo = null;
	}

	private eventListener(){
		window.addEventListener('message', function receiveMessage(event)
		{
            //console.log('window.addEventListener', event.data);

			switch(event.data.Event)
			{
				case 'OnNewToken': //Ocorre quando um novo token é gerado ou quando o iFrame é registrado
					if(event.data.Data != null && AuthService._instance.tokenExpired)
					{
                        AuthService._instance.fillToken(event.data.Data);
                        AuthService._instance.onCompleteAuthentication.emit();
					}
					break;

				case 'Registered'://Ocorre quando o javascript do iFrame é registrado e está disponível para a AppCliente
				if(window.location.hash){
					var hash = window.location.hash.substring(1);
						AuthService._instance.SendMessage({ 'Event': 'Hash', 'Data': hash });
					}
					else if (AuthService._instance.tokenExpired == true){
						localStorage.clear();
						AuthService._instance.SendMessage({ 'Event': 'Authorize', 'Data': '' });
					}
					break;

				case 'NeedAuthorize': //Ocorre quando a url para ser feito o login no Identity Server é construída

					window.location.href = event.data.Data;
					break;

				case 'NeedSignOut': //Ocorre quando a url para ser feito o logout no Identity Server é construída
					if(event.data.Data != undefined)
						window.location.href = event.data.Data;
					break;

				case 'SilentSignOutCompleted':
					break;
			}
		});
	}

    public async verifyDataUserIsComplete(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            this.onCompleteAuthentication.subscribe(async () => {
				await this.userService.GetLoggedUserInfo();
                resolve(true);
            });

        });
    }

    private fillToken(token: any)
	{
		this.tokenInfo = new TokenInfo();

		if(/access_token=(.*?)&/g.exec(token) == null)
		{
			console.log('well, this is temporary, right?')
			this.SendMessage({ 'Event': 'Authorize', 'Data': '' });
			return;
		}

		this.tokenInfo.access_token = /access_token=(.*?)&/g.exec(token)[1];
		let access_token_decode = JwtHelper.decodeToken(this.tokenInfo.access_token);
		this.tokenInfo.expires_in = (access_token_decode.exp) ? access_token_decode.exp : 0;
		this.tokenInfo.id_token = /id_token=(.*?)&/g.exec(token)[1];
		this.tokenInfo.scope = /scope=(.*?)&/g.exec(token)[1];
		this.tokenInfo.token_type = /token_type=(.*?)&/g.exec(token)[1];

		localStorage.setItem('token', token);
		localStorage.setItem('token_info', JSON.stringify(this.tokenInfo));
	}

    public isLoggedIn(): boolean {
        return this.tokenInfo != undefined && !this.tokenExpired;
    }

	public logout(): void{
		localStorage.clear();
		this.SendMessage({ 'Event': 'SignOut', 'Data': '' });
	}

	public getAuthorizationHeaderValue(): string {	
		return `${this.tokenInfo.token_type} ${this.tokenInfo.access_token}`;
	}

    public get tokenExpired(): boolean
    {
        if(this.tokenInfo)
        {
            var current_time = new Date().getTime() / 1000;
            var _expired: boolean = this.tokenInfo.expires_in < current_time;
            return _expired
        }
        else
            return true;
    }

    private SendMessage(message : any){
        var elem = document.getElementById(this.lugarhId);
        (<HTMLIFrameElement> elem).contentWindow.postMessage(message, '*');
    }
}

export class TokenInfo
{
	public id_token: any
	public access_token: any;
	public token_type: any;
	public expires_in: any;
	public scope: any;
	public session_state: any
}
