<div class="card">
    <form class="card__content" [formGroup]="addressForm">
        <div class="card__title">
            <mat-icon (click)="showFormCompany();"> edit </mat-icon>
            Endereço
        </div>
        <div class="adress">
            <div class="adress-content">
                <h6><strong>CEP: </strong>
                    <span *ngIf="!companyUpdating">{{address?.zipCode}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput id="cep" formControlName="zipCode"
                            [textMask]="{ mask: custom_mask, keepCharPositions: true }">
                        <mat-error>Digite o cep.</mat-error>
                    </mat-form-field>
                </h6>
                <h6><strong>Tipo: </strong>
                    <span *ngIf="!companyUpdating">{{address?.streetType}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="50" formControlName="streetType">
                    </mat-form-field>
                </h6>
            </div>
            <div class="adress-content">
                <h6><strong>Endereço: </strong>
                    <span *ngIf="!companyUpdating">{{address?.streetName}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="50" formControlName="streetName" required>
                        <mat-error>Digite o endereço.</mat-error>
                    </mat-form-field>
                </h6>
                <h6><strong>N°: </strong>
                    <span *ngIf="!companyUpdating">{{address?.number}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="10" formControlName="number" required>
                        <mat-error>Digite o N°.</mat-error>
                    </mat-form-field>
                </h6>
            </div>
            <div class="adress-content">
                <h6><strong>Complemento: </strong>
                    <span *ngIf="!companyUpdating">{{address?.complement}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="20" formControlName="complement">
                    </mat-form-field>
                </h6>
                <h6><strong>Bairro: </strong>
                    <span *ngIf="!companyUpdating">{{address?.neighborhood}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="50" formControlName="neighborhood" required>
                        <mat-error>Digite o bairro.</mat-error>
                    </mat-form-field>
                </h6>
            </div>
            <div class="adress-content">
                <h6><strong>Cidade: </strong>
                    <span *ngIf="!companyUpdating">{{address?.city}}</span>
                    <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
                        <input type="text" matInput maxlength="50" formControlName="city" required>
                        <mat-error>Digite a cidade.</mat-error>
                    </mat-form-field>
                </h6>
            </div>

        </div>
        <button *ngIf="companyUpdating" mat-raised-button class="is-button__outline" color="accent" [disabled]="this.addressForm.controls.zipCode.status == 'INVALID' ||
       this.addressForm.controls.city.value == '' || 
       this.addressForm.controls.streetName.value == ''||
       this.addressForm.controls.neighborhood.value == '' ||
       this.addressForm.controls.number.value == ''" (click)="pathAddressData()">
            SALVAR
        </button>
    </form>
</div>