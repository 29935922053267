<section *ngIf="company == undefined || isLoading" class="content__holder">
  <div class="card form__content" fxLayout="row wrap" fxLayoutAlign="center center">
    <app-loading></app-loading>
  </div>
</section>

<section *ngIf="company != undefined && !isLoading" id="quota" class="content__holder">

  <div id="companyData" class="card form__content">

    <!--Title Content-->
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="is-relative title__content">
      <mat-slide-toggle (click)="changeBlockStatus(!company.blocked);" style="cursor: pointer;"
        [checked]="company.blocked"></mat-slide-toggle>
      <div class="card__title">
        Dados da Empresa
        <i class="material-icons" (click)="RefreshCompanyInfos()" [ngClass]="{'roll__icon' : isRefresh}"
          style="margin-left: 16px; color: black; cursor:pointer">
          refresh
        </i>
      </div>
      <a (click)="showFormCompany();" style="cursor: pointer;">
        <i class="material-icons">edit</i>
      </a>
    </div>
    <div class="content-cards">
      <div class="card form__content">
        <div class="card__title">Empresa</div>
        <!--Card Content-->
        <form [formGroup]="companyForm" class="card__content">

          <h6>
            <strong>Id: </strong>
            <span>{{company.id}}</span>
          </h6>

          <h6 class="input__company"><strong>CNPJ: </strong>
            <span *ngIf="!companyUpdating"> {{company.cnpj}}</span>
            <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
              <input type="text" matInput value="{{company.cnpj}}" (blur)="verifyCNPJ()" formControlName="cnpj"
                required>
              <span class="form__loading">
                <mat-spinner *ngIf="isCnpjLoading" color="primary" diameter="32" strokeWidth="4" mode="indeterminate">
                </mat-spinner>
              </span>
              <mat-error>{{getCnpjErrorMessage}}</mat-error>
            </mat-form-field>
          </h6>

          <h6><strong>Razão Social: </strong>
            <span *ngIf="!companyUpdating">{{company.name}}</span>
            <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
              <input type="text" matInput value="{{company.name}}" formControlName="name" required>
              <mat-error>Digite a Razão Social.</mat-error>
            </mat-form-field>
          </h6>

          <!-- <h6><strong>Plano:</strong> {{company.mainPlan}}</h6> -->
          <h6>
            <strong>Data de Criação: </strong>
            <span>{{company.createdOn | date:'dd/MM/yyyy - HH:mm'}}</span>
          </h6>

          <div *ngIf="companyUpdating" fxLayoutAlign="end">
            <button mat-raised-button class="is-button__outline" color="accent" (click)="EditCompany(cnpj, name);"
              [ngClass]="{'in-progress': isInProgress,  'is-disable': !isFormValid || isCnpjLoading}">
              <span *ngIf="!isInProgress">Salvar</span>
              <span *ngIf="isInProgress">Salvando...</span>
            </button>
          </div>
        </form>
      </div>
      <app-company-address></app-company-address>
    </div>
  </div>
</section>
<!--address-->