<ng-container *ngIf="isViewMode">
  <ng-container *ngTemplateOutlet="viewMode"></ng-container>
</ng-container>

<ng-container *ngIf="!isViewMode">
  <ng-container *ngTemplateOutlet="editMode"></ng-container>
</ng-container>

<!-- * View Mode TEMPLATE -->
<ng-template #viewMode>
  <ng-container *ngIf="workspaceIntegration$ | async as obj">
    <div class="card-container view" *ngIf="obj?.canShow">
      <div class="header">
        <span class="title">WebFopag</span>
        <span class="material-icons action" (click)="toggleViewMode()">edit</span>
      </div>

      <div class="card-content">
        <div class="property">
          <span>CS:</span>
          <span class="value">{{ obj?.data?.cs || 'Não informado' }}</span>
        </div>
        <div class="property">
          <span>CNPJ:</span>
          <span class="value">{{ obj?.data?.cnpj || 'Não informado' }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- * Edit Mode TEMPLATE  -->
<ng-template #editMode>
  <div class="card-container edit">
    <span class="card-title">Workspace CS (WebFopag)</span>
    <form class="form" [formGroup]="fgWorkspaceCs">
      <!-- ? Tipo -->
      <!-- <div class="form-field">
        <span>Tipo</span>
        <mat-form-field [floatLabel]="false">
          <mat-select matNativeControl formControlName="fcType" required>
            <mat-option value="WebFopag">WebFopag</mat-option>
          </mat-select>
          <mat-error>Digite o Tipo.</mat-error>
        </mat-form-field>
      </div> -->

      <!-- * CS -->
      <div class="form-field">
        <span>CS:</span>
        <mat-form-field [floatLabel]="false">
          <input type="text" matInput formControlName="fcCS" required />
          <mat-error>{{ getErrorMessage('fcCS') }}</mat-error>
        </mat-form-field>
      </div>

      <!-- * CPNJ -->
      <div class="form-field">
        <span>CNPJ:</span>
        <mat-form-field [floatLabel]="false">
          <input
            type="text"
            matInput
            [textMask]="{ mask: maskCNPJ, guide: false, modelClean: true }"
            [formControlName]="fcCNPJ$ | async"
            required />
          <mat-error>{{ getErrorMessage('fcCNPJ') }}</mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="card-actions">
      <button mat-raised-button (click)="submitFormData()" [disabled]="fgWorkspaceCs?.invalid">salvar</button>
      <button mat-raised-button (click)="toggleViewMode()">cancelar</button>
    </div>
  </div>
</ng-template>
