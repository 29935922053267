import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeesService } from '../services/employees.service';
import { LoaderService } from 'src/app/shared/security/loader.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  templateUrl: './remove-employees-dialog.component.html',
  styleUrls: ['./remove-employees-dialog.component.scss']
})
export class RemoveEmployeesDialogComponent implements OnInit {

  public displayStatus: string = '';

  constructor(
    public dialogRef: MatDialogRef<RemoveEmployeesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { companyId: string, status: string },
    private _service: EmployeesService, private _loader: LoaderService,
    private _toast: ToastService) {}

  ngOnInit() 
  {
    if(this.data.status === 'Inactive')
      this.displayStatus = 'inativos';
    else if(this.data.status === 'NotStarted')
      this.displayStatus = 'novos';
  }

  public exit(): void
  {
    this.dialogRef.close({ "status": '' });
  }
   
  public remove(): void 
  {
    this._loader.show();
   
    this._service.remove(this.data.companyId, this.data.status).subscribe(
      rst => 
      {
        this.dialogRef.close({ "status": this.data.status });
        const msg: string = rst > 1 ? `${rst} colaboradores foram adicionados à fila de remoção.` :  `1 colaborador foi adicionado à fila de remoção.`
        this._toast.success(msg);
        this._loader.hide();
      },
      err =>
      {
        this.dialogRef.close({ "status": '' });
        this._toast.error(`Não foi possível adicionar os colaboradores a fila de remoção.`);
        this._loader.hide();
      }
    );
   

  }
}
