import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Workspace } from '../shared/model/workspace/workspace.model';
import { CompanyService, ToastService } from '../shared/services';
import { WorkspaceService } from '../shared/services/workspace.service';

@Component({
  selector: 'app-company-workspaces',
  templateUrl: './app-company-workspaces.component.html',
  styleUrls: ['./app-company-workspaces.component.scss']
})
export class AppCompanyWorkspacesComponent implements OnInit {

  public companyId: string;
  public workspaces: Workspace[];

  constructor(
    private workspaceService: WorkspaceService,
    private activatedRouter: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initCompanyId();
    this.getAllWorkspaceCompany();
  }

  public initCompanyId() {
    this.activatedRouter.params.subscribe(params => this.companyId = params.id);
  }

  public async getAllWorkspaceCompany() {
    this.workspaces = await this.workspaceService.GetAllWorkspaces(this.companyId).toPromise();

  }

  public async WorkClick(id: string) {
    window.open('#/empresa/' + this.companyId + '/workspace/' + id, "_blank");
  }

  public async formsManagement(id: string) {
    window.open('#/empresa/' + this.companyId + '/workspace/' + id + '/formularios', "_blank");
  }

}
