import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ShareExportLayoutView } from '../model/share-export-layout-view.model';
import { AttachExportConfigCmd } from '../model/attach-export-config-cmd.model';

@Injectable()
export class ShareExportService {
    
  constructor(private readonly http: HttpClient) { }

  public GetAllLayouts(): Promise<ShareExportLayoutView[]> {
    let url = `${environment.apiEndpoint}/ShareExport/Layouts`;
    return this.http.get<ShareExportLayoutView[]>(url).toPromise();
  }

  public async AttachExportConfig(cmd: AttachExportConfigCmd, companyId: string) : Promise<Object> {
    let headers = new HttpHeaders();
    headers = headers.set('lugarh-company-id', String(companyId));
    return this.http
        .post(environment.apiEndpoint + '/ShareExport/AttachExportConfig', cmd, {headers: headers})
        .toPromise();
  }

}