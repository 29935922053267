<div class="p-fluid" fxLayout="column" fxLayoutAlign="center center">
    <div class="modal__close" fxFlexFill fxLayoutAlign="end none">
        <div>
          <button mat-icon-button matTooltip="Cancelar" matTooltipPosition="left" mat-dialog-close>
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>

    <div class="my-3">
        <h5>Workspace:</h5>
        <p-dropdown [options]="workspaces" [(ngModel)]="selectedWorkspace" optionLabel="name" optionDisabled="inactive"></p-dropdown>
    </div>

    <div>
        <button
        pButton
        class="is-button__outline"
        (click)="openModalViewForm()"
        [disabled]="!fullSchema"
        label="OK"></button>
    </div>
</div>

