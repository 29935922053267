<app-loading-modal *ngIf="isLoading"></app-loading-modal>
<section>
    <app-header></app-header>
    <mat-sidenav-container class="sidenav__container" (backdropClick)="onBackdropClick()">
        <mat-sidenav #snav class="sidenav__component"
            [ngClass]="{'is-expanded': sidenavService.isMenuOpenedLeft == true}"
            [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches">
            <app-menu></app-menu>
        </mat-sidenav>
        <mat-sidenav-content class="page-wrapper">
            <div class="page-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</section>