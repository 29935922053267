import { Injectable, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { CompanyService } from './company.service';
import { LoaderService } from '../security/loader.service';
import { Company } from '../model/company.model';
import { FormsManagementService } from 'src/app/app-company/forms-management/forms-management.service';


@Injectable()
export class ListSearchService {
    private mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    public isMobileSearchActive: boolean = false;

    public companies: Company[] = new Array<Company>();
    public total: number = 1000;

    public pageSize: number = 10;
    public page: number = 0;

    public filterValue: string = "";

    constructor(media: MediaMatcher, private companyService: CompanyService, private loaderService: LoaderService, private formsManagementService: FormsManagementService) {
        this.mobileQuery = media.matchMedia('(max-width: 959px)');
        this._mobileQueryListener = () => {
            //console.log('[]==>', this.mobileQuery.matches);
            this.isMobileSearchActive = (!this.mobileQuery.matches) ? this.mobileQuery.matches : this.isMobileSearchActive;
        };
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    public async LoadCompanies() {
        this.loaderService.show();
        var companies = await this.companyService.GetCompanies(this.pageSize, this.page + 1, this.filterValue);
        this.companies = companies.companies;
        this.total = companies.total;
        this.loaderService.hide();
    }

    public async LoadCompaniesPrd() {
        this.loaderService.show();
        var companies = await this.formsManagementService.getCompanysProduction(this.pageSize, this.page + 1, this.filterValue);
        this.companies = companies.companies;
        this.total = companies.total;
        this.loaderService.hide();
    }
}
