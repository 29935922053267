import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Router } from '@angular/router';




@Directive()
@Injectable()
export class  SidenavService
{

  @Output()
  public  isMenuOpenedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _router : Router){}

    public isMenuOpenedLeft: boolean = true;



    public changeIsMenuOpened(sidenav:string) : void
    {
      if(sidenav == 'left'){
        this.isMenuOpenedLeft = !this.isMenuOpenedLeft;
        this.isMenuOpenedEvent.emit(this.isMenuOpenedLeft);
      }

    }

    public actualUrl(): string{
        var route = this._router.url.split('/')[1];
        if (route.indexOf('?') > -1)
           route = route.substr(0, route.indexOf('?'));
        return route;
    }



}
