import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanySourcetModel as CompanySourceModel } from '../../model/report/company-source.model';
import { ControlReportModel } from '../../model/report/control-report.model';
import { ShareReportCmd } from '../../model/report/share-report.cmd';
import { ShareSourceModel } from '../../model/report/share-source.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private httpClient: HttpClient) { }

    getCompaniesSource() {
        let url = `${environment.apiEndpoint}/report/CompanySource`;
        return this.httpClient.get<Array<CompanySourceModel>>(url);
    }

    getSharesSource() {
        let url = `${environment.apiEndpoint}/report/ShareSource`;
        return this.httpClient.get<Array<ShareSourceModel>>(url);
    }

    generateShareReport(shareReportCmd: ShareReportCmd) {
        let url = `${environment.apiEndpoint}/report/generateShareReport`;
        return this.httpClient.post<{ success: boolean }>(url, shareReportCmd);
    }

    getControlReports() {
        let url = `${environment.apiEndpoint}/report/ControlReport`;
        return this.httpClient.get<Array<ControlReportModel>>(url);
    }
}
