<ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<section>
  <div class="container" fxLayout="column" fxLayoutAlign="center none">
    <div fxFlex="100" class="content__compapny" fxLayout="row wrap" fxLayoutAlign="center none">
      <app-company-info *ngIf="company != undefined" [company]="company" fxFlex="100">
      </app-company-info>

      <app-profile-scope *ngIf="company != undefined" fxFlex="100"> </app-profile-scope>

      <app-quotas *ngIf="company != undefined" [companyId]="company.id" fxFlex="100"> </app-quotas>

      <employees-panel *ngIf="company" [companyId]="company?.id" fxFlex="100"> </employees-panel>

      <!-- Plan Atual -->
      <!-- <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" >
        <div id="companyData" class="card"  *ngIf="company != undefined">
          <div id="plan" *ngIf="plan != undefined">
            <div class="card__title">Dados do Plano Atual</div>
            <div class="card__content">
              <h6><strong>Nome: </strong>{{plan.name}}</h6>
              <h6><strong>Quantidade de Usuários: </strong> {{plan.qtdUsers}}</h6>
            </div>
          </div>
        </div>
      </div> -->

      <app-integrations *ngIf="company != undefined" [company]="company" fxFlex="100">
      </app-integrations>

      <app-export-layout *ngIf="company != undefined" [company]="company" fxFlex="100">
      </app-export-layout>
      <app-company-workspaces fxFlex="100"> </app-company-workspaces>
    </div>

    <div fxLayout="row">
      <div class="card company__users space p-3" fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="space-around baseline">
          <span class="title" fxLayoutAlign="start center">
            <i class="material-icons">account_circle</i>
            <h5>Usuários com acesso na empresa</h5>
          </span>
          <p class="subtitle">
            Adicione novos usuários ou edite as informações de quem tem acesso a empresa.
          </p>

          <button
            mat-raised-button
            color="primary"
            class="button__medium button__outline"
            [routerLink]="['', 'empresa', companyId, 'user-company']">
            GERENCIAR USUÁRIOS
          </button>

          <button
            mat-raised-button
            color="primary"
            class="button__medium button__outline"
            *ngIf="enableAuthorization == 0"
            (click)="onEnableAuthorizationClick()">
            HABILITAR NOVO PERMISSIONAMENTO
          </button>

          <button
            mat-raised-button
            color="primary"
            class="button__medium button__outline"
            *ngIf="enableAuthorization == 1"
            (click)="onDisableAuthorizationClick()">
            DESABILITAR NOVO PERMISSIONAMENTO
          </button>
        </div>
        <div>
          <img class="img" src="\assets\config\gerenciar-usuaios.png" alt="imagem" />
        </div>
      </div>

      <div class="card company__users space" fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="space-around baseline">
          <span class="title" fxLayoutAlign="start center">
            <i class="material-icons">article</i>
            <h5>Assinatura digital Clicksign</h5>
          </span>
          <p class="subtitle">
            Habilite ou desabilite as funcionalidades de assinatura digital Clicksign para a empresa
            e os workspaces.
          </p>

          <button
            mat-raised-button
            color="primary"
            class="button__medium button__outline"
            (click)="showSetupDigitalSignatureDialog()">
            CONFIGURAR ASSINATURA DIGITAL
          </button>
        </div>
        <div>
          <img class="img" src="\assets\config\gerenciar-usuaios.png" alt="imagem" />
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <i class="material-icons">widgets</i>
            <span>Gerenciar campos do arquivo Schema</span>
          </mat-card-title>
          <mat-card-subtitle>
            Adicione, exclua ou edite as informações dos campos de dados de contratação
          </mat-card-subtitle>
          <img
            mat-card-image
            src="\assets\config\gerenciar-usuaios.png"
            alt="Acordo entre duas pessoas" />
        </mat-card-header>

        <mat-card-actions align="start">
          <button
            mat-raised-button
            color="primary"
            class="action-button"
            [routerLink]="['', 'empresa', companyId, 'schema-config']">
            Gerenciar Campos
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</section>
