import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '../../shared/security/loader.service';
import { CompanyService, ToastService, UserService } from '../../shared/services';

@Component({
  selector: 'app-user-deleted',
  templateUrl: './user-deleted.component.html',
  styleUrls: ['./user-deleted.component.scss']
})
export class UserDeletedComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserDeletedComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private toasterService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

  //Remove user from company
  public async RemoveUserCompany() {
    this.loaderService.show();
    this.userService.RemoveUserCompany(this.data.userId, this.data.companyId).then(rst => {
      this.toasterService.success("Usuário Removido com Sucesso.");
      this.loaderService.hide();
      this.dialogRef.close(true);
    }, 
    err => {
      this.toasterService.error("Falha ao remover usuário.");
      console.log(err);
      this.loaderService.hide();
      this.dialogRef.close(false);
    });
  }
}
