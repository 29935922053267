<div class="card">
  <form class="card__content" [formGroup]="adressForm">
    <div class="card__title">
      Endereço
    </div>
    <div class="adress">     
      <div class="adress-content">
        <h6><strong>CEP: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput id="cep"  [(ngModel)]="actualAdressFormData.zipCode" formControlName="zipCode" [textMask]="{ mask: custom_mask, keepCharPositions: true }">
            <mat-error>Digite o cep.</mat-error>
          </mat-form-field>
        </h6>
        <h6><strong>Tipo: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput maxlength="50" [(ngModel)]="actualAdressFormData.streetType" formControlName="streetType">
          </mat-form-field>
        </h6>
      </div>
      <div class="adress-content">
        <h6><strong>Endereço: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput  maxlength="50"  [(ngModel)]="actualAdressFormData.streetName" formControlName="streetName"
              required>
            <mat-error>Digite o endereço.</mat-error>
          </mat-form-field>
        </h6>
        <h6><strong>N°: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput  maxlength="10"  [(ngModel)]="actualAdressFormData.number" formControlName="number" required>
            <mat-error>Digite o N°.</mat-error>
          </mat-form-field>
        </h6>
      </div>
      <div class="adress-content">
        <h6><strong>Complemento: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput  maxlength="20" formControlName="complement">
          </mat-form-field>
        </h6>
        <h6><strong>Bairro: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput  maxlength="50"  [(ngModel)]="actualAdressFormData.neighborhood" formControlName="neighborhood"
              required>
            <mat-error>Digite o bairro.</mat-error>
          </mat-form-field>
        </h6>
      </div>
      <div class="adress-content">
        <h6><strong>Cidade: </strong>
          <mat-form-field [floatLabel]="false">
            <input type="text" matInput  maxlength="50"  [(ngModel)]="actualAdressFormData.city" formControlName="city" required>
            <mat-error>Digite a cidade.</mat-error>
          </mat-form-field>
        </h6>
      </div>

    </div>
    <button mat-raised-button class="is-button__outline" color="accent" [disabled]="this.adressForm.controls.zipCode.status == 'INVALID' ||
     this.adressForm.controls.city.value == '' || 
     this.adressForm.controls.streetName.value == ''||
     this.adressForm.controls.neighborhood.value == '' ||
     this.adressForm.controls.number.value == ''" (click)="pathAddressData()">
      SALVAR
    </button>
  </form>
</div>