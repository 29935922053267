import { CompanyService } from 'src/app/shared/services/company.service';
import { FormsManagementService } from './forms-management.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UiForm } from './model/form.model';
import { MatDialog } from '@angular/material/dialog';
import { ImplementFormModalComponent } from './implement-form-modal/implement-form-modal.component';
import { Company } from 'src/app/shared/model/company.model';

@Component({
  selector: 'app-forms-management',
  templateUrl: './forms-management.component.html',
  styleUrls: ['./forms-management.component.scss']
})
export class FormsManagementComponent implements OnInit {

  isLoading: boolean;
  companyId: string;
  workId: string;
  displayedColumns: string[] = ['id', 'name', 'description', 'action'];
  dataSource: MatTableDataSource<UiForm>;
  company: Company;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private formsManagementService: FormsManagementService,
    private route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private companyService: CompanyService
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.companyId = params['id']);
    this.route.params.subscribe(params => this.workId = params['workId']);
    this.getForms();
    this.getCompany();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private async getForms(): Promise<void> {
    this.isLoading = true;
    let forms = await this.formsManagementService.getForms(this.companyId, this.workId);    
    this.dataSource = new MatTableDataSource(forms);
    this.isLoading = false;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }

  private async getCompany(): Promise<any> {   
    this.company = await this.companyService.getOne(this.companyId).toPromise();  
  }

  public implentForms(id: string, name: string) {
    let dialogRef = this.dialog.open(ImplementFormModalComponent, {
      width: '550px',
      data: {
        formId: id,
        formName: name,
        companyId: this.companyId,
        workId: this.workId,
        company: this.company
      }
    });
  }

}


