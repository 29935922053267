<ngx-loading [show]="isLoading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="create__contract__container">
    <div class="two__field__container">
      <div style="flex: 0.4">
        <h6>Nome do contrato</h6>
        <input
          class="p-inputtext-sm"
          type="text"
          pInputText
          formControlName="name"
          maxlength="30" />
      </div>

      <div style="flex: 0.6">
        <h6>Descrição</h6>
        <input
          class="p-inputtext-sm"
          type="text"
          pInputText
          formControlName="description"
          maxlength="100" />
      </div>
    </div>

    <div class="two__field__container" formControlName="company" tabindex="0">
      <div style="flex: 1">
        <h6>Empresa</h6>
        <p-autoComplete
          formControlName="company"
          [panelStyle]="{ width: '100%' }"
          [style]="{ width: '100%' }"
          [(ngModel)]="selectedCompany"
          [suggestions]="filteredCompanies"
          (onSelect)="onSelectCompany($event)"
          (completeMethod)="filterCompany($event)"
          field="name"
          [dropdown]="true">
          <ng-template let-company pTemplate="item">
            <div class="company-item">
              <div>{{ company.name }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <div style="flex: 1">
        <h6>Workspaces</h6>
        <p-multiSelect
          formControlName="workspaceList"
          [panelStyle]="{ width: '100%' }"
          [style]="{ width: '100%' }"
          [showToggleAll]="false"
          [showHeader]="false"
          [virtualScroll]="true"
          itemSize="2"
          [options]="workspaces"
          [(ngModel)]="selectedWorkspaces"
          defaultLabel="Todos"
          optionLabel="name"
          selectedItemsLabel="{0} workspaces selecionados"></p-multiSelect>
      </div>
    </div>

    <div formcontrolname="selectedFile" tabindex="0">
      <h6 *ngIf="!isEditing">Selecione o arquivo .docx</h6>
      <h6 *ngIf="isEditing">O arquivo .docx não pode ser alterado</h6>
      <button
        *ngIf="isEditing"
        type="button"
        (click)="downloadTemplate()"
        pButton
        pRipple
        label="Baixar arquivo"
        icon="pi pi-download"></button>
      <p-fileUpload
        *ngIf="!isEditing"
        [disabled]="isEditing"
        chooseLabel="Selecionar"
        [customUpload]="true"
        cancelLabel="Remover"
        name="demo[]"
        (onUpload)="onUpload($event)"
        (onSelect)="onSelectFile($event)"
        (onRemove)="onRemoveFile($event)"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        maxFileSize="5000000"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [styleClass]="{ 'ng-invalid ng-dirty': 1 === 1 }">
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <div
        *ngIf="(form.controls['selectedFile'].dirty || form.controls['selectedFile'].touched) && form.controls['selectedFile'].errors?.['required']">
        <span style="color: darkred">O arquivo é obrigatório.</span>
      </div>
    </div>

    <h6>Selecione abaixo os campos do documento</h6>
    <p-pickList
      [showTargetControls]="false"
      [showTargetFilter]="false"
      [showSourceControls]="false"
      [source]="sourceFields"
      [target]="targetFields"
      sourceHeader="Disponíveis"
      targetHeader="Selecionados"
      [dragdrop]="true"
      [responsive]="true"
      [sourceStyle]="{ height: '30rem' }"
      [targetStyle]="{ height: '30rem' }"
      filterBy="dstName,label"
      sourceFilterPlaceholder="Pesquisar campo"
      invalidFileSizeMessageSummary="{0}: Tamanho inválido"
      invalidFileSizeMessageDetail="Tamanho máximo do upload é de {0}"
      invalidFileTypeMessageSummary="{0}: Formato inválido"
      invalidFileTypeMessageDetail="Formatos permitidos: {0}"
      invalidFileLimitMessageDetail="Máximo de arquivos: {0}"
      invalidFileLimitMessageSummary="Número máximo de arquivos excedido">
      <ng-template let-field pTemplate="item">
        <div class="file-item">
          <div class="image-container"></div>
          <div class="file-list-detail">
            <h6 class="mb-2">
              <span ng-non-bindable>{{</span>{{ field.dstName }}<span ng-non-bindable>}}</span>
            </h6>
            <i class="pi pi-tag field-description-icon"></i>
            <span class="field-description" *ngIf="!field?.label">Sem descrição</span>
            <span class="field-description">{{ field.label }}</span>
          </div>
        </div>
      </ng-template>
    </p-pickList>
  </div>
  <div class="p-dialog-footer">
    <span class="p-buttonset">
      <button
        type="button"
        (click)="closeDialog()"
        pButton
        pRipple
        label="Cancelar"
        icon="pi pi-times"></button>
      <button
        type="submit"
        pButton
        pRipple
        label="Salvar"
        icon="pi pi-check"
        class="p-button-info"></button>
    </span>
  </div>
</form>
