import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Item } from 'src/app/shared/model/schema-config/schema-full-config.model';

import { DialogConfig, DialogConfigData } from '../../shared/model/dialog-config.model';
import { SchemaField } from '../../shared/model/schema-config/shema.model';
import { SchemaDataService } from '../../shared/services/schema-data/schema-data.service';
import { SchemaFieldService } from '../../shared/services/schema-data/schema-field.service';
import { ModalAddSchemaFieldComponent } from './modals/modal-add-schema-field/modal-add-schema-field.component';
import { ModalSelectWorkspaceComponent } from './modals/modal-select-workspace/modal-select-workspace.component';

@Component({
  selector: 'app-schema-config',
  templateUrl: './schema-config.component.html',
  styleUrls: ['./schema-config.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class SchemaConfigComponent implements OnInit {
  // * Variables
  public companyId: string;
  public loading: boolean = true;
  public displayModal: boolean;
  public addLabel = `Adicionar Campo`;
  public saveLabel = `Salvar Dados`;
  public exportLabel = `Exportar JSON`;
  public viewFormLabel = `Visualizar formulário`;
  public createDefaultSchemaLabel = `Criar campos padrão`;
  public isCompanyWithoutSchema = false;

  private _defaultSchema = `01c6cdb6-e059-4c8f-8396-3e5fab18d259`;

  // * Arrays
  public arrSchemaFields: Array<SchemaField> = new Array<SchemaField>();

  constructor(
    private _schemaService: SchemaDataService,
    private _activatedRoute: ActivatedRoute,
    public _schemaFieldService: SchemaFieldService,
    private _dialog: MatDialog,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.arrSchemaFields = [];
    this._activatedRoute.params.subscribe((params) => (this.companyId = params.id));

    // Chamada para obter o schema completo com fields, forms, etc (objeto final)
    this._schemaService.getFullSchema(this.companyId, this._defaultSchema).then((result) => {
      this._schemaFieldService.schemaFullConfig = result;
    });

    this._schemaService.getSchemaProperties(this.companyId, this._defaultSchema).then((schemaObjs: Array<SchemaField>) => {
      this.arrSchemaFields = schemaObjs;
      this._schemaFieldService.resetSchemaFields();
      this._schemaFieldService.appendSchemaField(schemaObjs);
      this.loading = false;
    })
    .catch(error => {
      if (error.status === 404) {
        this.loading = false;
        this._messageService.add({ severity: 'warn', summary: 'Empresa não possui dados de contratação configurados', detail: '' });
        this.isCompanyWithoutSchema = true;
      } else {
        throw error;
      }
    });
  }

  public openModalAddSchemaField() {
    // * Modal config
    const dialogConfig = new DialogConfig<DialogConfigData>({
      data: {
        header: `Criar dados do campo de contratação`,
        fields: this._schemaFieldService.getSchemaFields(),
      },
      width: '50vw',
      height: '80vh',
    });

    const dialogRef = this._dialog.open(ModalAddSchemaFieldComponent, {
      ...dialogConfig,
      panelClass: 'modal-schema-obj',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.arrSchemaFields = this._schemaFieldService.getSchemaFields();
    });
  }

  public openModalEditSchemaField(fieldName: string) {
    // * Reset values
    this._schemaFieldService.resetSchemaFields();
    this._schemaFieldService.appendSchemaField(this.arrSchemaFields);

    // * Modal config
    let editingField = this.arrSchemaFields.find((x) => x.name === fieldName);
    const dialogConfig = new DialogConfig<DialogConfigData>({
      data: {
        header: `Criar dados do campo de contratação`,
        fields: this._schemaFieldService.getSchemaFields(),
        editingField,
        fieldIndex: this.arrSchemaFields.indexOf(editingField),
      },
      width: '50vw',
      height: '80vh',
    });

    const dialogRef = this._dialog.open(ModalAddSchemaFieldComponent, {
      ...dialogConfig,
      panelClass: 'modal-schema-obj',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.arrSchemaFields = this._schemaFieldService.getSchemaFields();
    });
  }

  public exportFullSchemaJson() {
    this.createSchemaConfigModel();
    let hiddenElement = document.createElement('a');

    const jsonResult = JSON.stringify(this._schemaFieldService.schemaFullConfig, null, 4);
    hiddenElement.href = 'data:attachment/text,' + encodeURI(jsonResult);
    hiddenElement.target = '_blank';
    hiddenElement.download = this._defaultSchema + '.json';
    hiddenElement.click();
  }

  public onRowReorder(event) {
    this._schemaFieldService.setSchemaFields(this.arrSchemaFields);
  }

  public arrayMove(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  public openModalSelectWorkspace() {
    this.createSchemaConfigModel();
    const dialogConfig = new DialogConfig<DialogConfigData>({
      data: {
        fullSchema: this._schemaFieldService.schemaFullConfig,
        companyId: this.companyId,
      },
      width: '50vw',
      height: '30vh',
    });

    this._dialog.open(ModalSelectWorkspaceComponent, dialogConfig);
  }

  private createSchemaConfigModel() {
    this._schemaFieldService.schemaFullConfig.schema.properties = {};
    this._schemaFieldService.schemaFullConfig.form = [];

    let fields = this._schemaFieldService.getSchemaFields();
    fields.forEach((field) => this.addFieldToSchemaModel(field));
  }

  private addFieldToSchemaModel(schemaField: SchemaField) {
    /* Adicionar campo nas properties da model final que irá para API */
    const schemaFieldProperty = {};
    let fieldKeys = Object.keys(schemaField);
    fieldKeys.forEach((key) => {
      /* Adiciona somente os campos q não estiverem null
        Campos null dão erro no schema
        */
      if (schemaField[key]) {
        schemaFieldProperty[key] = schemaField[key];
      }
    });
    this._schemaFieldService.schemaFullConfig.schema.properties[schemaField.name] = schemaFieldProperty;

    /* Adicionar campo no form da model final para poder ser visualizado */
    /* Verifica último item do form para tentar colocar o campo na segunda coluna da linha existente */
    let form = this._schemaFieldService.schemaFullConfig.form;
    const index = form.length > 0 ? form.length - 1 : 0;
    let latestRow = form[index];
    if (latestRow && latestRow.items.length == 1) {
      let newColumn = {
        'key': schemaField.name,
        'flex': '2 2 46%',
        'flex-flow': 'row',
      } as Item;

      latestRow.items.push(newColumn);
    } else if (!latestRow || latestRow.items.length == 2) {
      /* Se já tem 2 campos na linha, ou se não tem nenhuma linha, adiciona uma nova */
      let newColumn = {
        key: schemaField.name,
        flex: '1 1 47.5%',
      } as Item;

      let newRow = {
        'type': 'div',
        'display': 'flex',
        'flex-flow': 'row wrap',
        'fxFlexAlign': 'center none',
        'items': [],
      };
      newRow.items.push(newColumn);
      form.push(newRow);
    }
  }

  public saveSchema() {
    this.createSchemaConfigModel();
    this._schemaService
      .saveSchemaConfig(this.companyId, this._defaultSchema, this._schemaFieldService.schemaFullConfig)
      .then(() => {
        this._messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Configurações salvas com sucesso!' });
      })
      .catch((err) => {
        console.log(err);
        this._messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar configurações' });
      });
  }

  public deleteField(event: Event, name: string) {
    this._confirmationService.confirm({
      target: event.target,
      message: 'Deseja remover o campo?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._schemaFieldService.removeSchemaField(name);
        this.arrSchemaFields = this._schemaFieldService.getSchemaFields();
      },
      reject: () => {},
    });
  }

  public customSort(event: any) {
    // método vazio para não fazer ordenação na tabela, manter ordenação padrão de acordo com a ordem da tela
  }

  public createDefaultSchema() {
    this.loading = true;
    this._schemaService.createDefaultSchema(this.companyId)
    .then(() => {
      this.init();
    })
    .catch((err) => {
      this._messageService.add({ severity: 'warn', summary: 'Ocorreu um erro ao criar os dados de contratação padrão', detail: '' });
      this.loading = false;
    })
  }
}
