import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppRedirectComponent } from '../app-redirect/app-redirect.component';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { FormsManagementService } from 'src/app/app-company/forms-management/forms-management.service';

@Injectable()
/**
 * Interceptor to intercept the http requests and include the token in the Header
 */
export class AuthInterceptor implements HttpInterceptor {

  private _authService: AuthService = null;
  private _redirect: AppRedirectComponent = null;
  private static _instance: AuthInterceptor;
  private token: string
  constructor(private injector: Injector, private router: Router, private formsManagementService: FormsManagementService) {
    AuthInterceptor._instance = this;
    this.formsManagementService.onChangeAmbient.subscribe(value => {
      this.token = value;      
    });
  }

  /**
   * Gets OidcSecurityService instance. It must be done this way to avoid cyclic dependency.
   */
  private get authService(): AuthService {
    if (this._authService == null)
      this._authService = this.injector.get(AuthService);

    return this._authService;
  }


  /**
   * Gets AppRedirectComponent instance. It must be done this way to avoid cyclic dependency.
   */
  private get redirect(): AppRedirectComponent {
    if (this._redirect == null)
      this._redirect = this.injector.get(AppRedirectComponent);

    return this._redirect;
  }

  /** Interception implementation */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Start: Get the company ID
    let companyId: string = null;

    if (!this.authService || !this.authService.isLoggedIn())  // If the token is not defined, pass the request without token
    {
      console.log('Não está logado =>', req);
      return next.handle(req).pipe(tap(this.handleResponse, this.handleResponseError));
    }

    /** Token Refresh method*/
    if (this.authService.tokenExpired) {
      this.authService.logout();
    }

    if (companyId && req.url != environment.apiEndpoint + '/Company/MyCompanies') {
      const theReq = req.clone({ headers: req.headers.set("lugarh-company-id", companyId).set('Authorization', this.authService.getAuthorizationHeaderValue()) });
      return next.handle(theReq).pipe(tap(this.handleResponse, this.handleResponseError));
    }
    if (this.token) {     
      const theReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.token}`)
      });
      return next.handle(theReq).pipe(tap(this.handleResponse, this.handleResponseError));
    }
    else {
      const theReq = req.clone({ headers: req.headers.set('Authorization', this.authService.getAuthorizationHeaderValue()) });
      return next.handle(theReq).pipe(tap(this.handleResponse, this.handleResponseError));
    }

  }


  private handleResponse(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse) {
      // do stuff with response if you want
      //console.log('Resposta do servidor:', event);
    }
  }

  private handleResponseError(err: any): void {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 || err.status === 403) {
        AuthInterceptor._instance.redirect.show(err.status);
        AuthInterceptor._instance.redirect.closedModal.subscribe(() => {
          err.status == 401 ? AuthInterceptor._instance.router.navigate[('empresas')] : AuthInterceptor._instance._authService.logout();
        });
      }
    }
  }
}
