<!-- page__header -->
<section class="page__header is-primary">
  <div class="container__mobile">
    <!-- page__heading -->
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="page__heading">
      <h3 fxFlex="100" class="page__title">Gerenciador de Workspace</h3>
      <p fxFlex="100" class="page__subtitle">Edite as configurações do workspace.</p>
    </div>
  </div>
</section>

<!-- page__content -->
<section class="page__content">
  <div class="config__section">
    <div class="is-relative title__content">
      <div class="card__title">
        Configurações
        <!-- <i class="material-icons" [ngClass]="{'roll__icon' : isRefresh}"
                    style="margin-left: 16px; color: black; cursor:pointer">
                    refresh
                </i> -->
      </div>
    </div>
    <div class="content-cards">
      <div style="width: 40%" class="card form__content">
        <div class="card__title">
          <mat-icon (click)="showFormCompany()"> edit </mat-icon>
          Workspace
        </div>
        <form [formGroup]="workspaceForm" class="card__content">
          <h6>
            <strong>Id: </strong>
            <span>{{ workspace?.id }}</span>
          </h6>
          <!-- <h6>
                        <strong>Data de Criação: </strong>
                         <span>{{workspace.data| date:'dd/MM/yyyy - HH:mm'}}</span>
                    </h6> -->
          <span class="information__text">Informações editáveis</span>
          <br />
          <h6>
            <strong>Nome: </strong>
            <span *ngIf="!companyUpdating">{{ workspace?.name }}</span>
            <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
              <input type="text" matInput value="{{ workspace.name }}" formControlName="name" required />
              <mat-error>Digite o nome do workspace</mat-error>
            </mat-form-field>
          </h6>
          <h6 class="input__company">
            <strong>CNPJ: </strong>
            <span *ngIf="!companyUpdating"> {{ workspace?.cnpj }}</span>
            <mat-form-field *ngIf="companyUpdating" [floatLabel]="false">
              <input
                type="text"
                [dropSpecialCharacters]="false"
                mask="CPF_CNPJ"
                matInput
                value="{{ workspace.cnpj }}"
                (blur)="verifyCNPJ()"
                formControlName="cnpj"
                required />
              <mat-error *ngIf="workspaceForm.get('cnpj').errors?.cnpj && !workspaceForm.get('cnpj').errors?.required">
                Insira um CNPJ valido</mat-error
              >
            </mat-form-field>
          </h6>
          <div *ngIf="companyUpdating" fxLayoutAlign="end">
            <button
              mat-raised-button
              class="is-button__outline"
              color="accent"
              (click)="patchWorkspace()"
              [disabled]="!workspaceForm.valid">
              <span *ngIf="isLoading == false">Salvar</span>
              <span *ngIf="isLoading === true">Salvando...</span>
            </button>
          </div>
        </form>
      </div>
      <app-workspace-address style="width: 60%" [address]="workspace?.data.address"></app-workspace-address>
    </div>
  </div>
</section>
<section style="margin-top: 30px" class="page__content">
  <div class="config__section">
    <div class="content-export">
      <app-workspace-export style="width: 100%"></app-workspace-export>
    </div>
  </div>
</section>
<section style="margin-top: 30px" class="page__content">
  <div class="config__section">
    <div class="content-export">
      <workspace-cs style="width: 100%"></workspace-cs>
    </div>
  </div>
</section>
<section style="margin-top: 30px; margin-bottom: 30px" class="page__content">
  <div class="config__section">
    <div class="content-export">
      <app-users-workspace-company style="width: 100%"></app-users-workspace-company>
    </div>
  </div>
</section>
