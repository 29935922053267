<div class="toast" fxLayout="row" fxLayoutAlign=" strech center">
  <div class="toast__text" >
    <p [innerHtml]="data.msg"></p>
  </div>
  <!-- Sucess -->
  <div *ngIf="data.isSuccess" class=" toast__icon is-toast__icon__success">
    <i class="material-icons has-success is-status">check</i>
  </div>
  <!-- Warning -->
  <div *ngIf="data.isWarning" class="toast__icon is-toast__icon__warning">
    <i class="material-icons warning is-status">warning</i>
  </div>
  <!-- Error -->
  <div *ngIf="data.isError" class="toast__icon is-toast__icon__error">
    <i class="material-icons error is-status">close</i>
  </div>
</div>
<!-- <i class="material-icons has-error">error</i>
<i class="material-icons has-warning">warning</i> -->
