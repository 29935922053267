import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { debounceTime, switchMap, map, first } from 'rxjs/operators';
import { CompanyService } from 'src/app/shared/services/company.service';

@Injectable({ providedIn: 'root'})
export class CnpjAlreadyTakenValidatorService
{
    constructor(private readonly companyService: CompanyService)
    {}

    checkIfCnpjIsTaken()
    {
        return (control: AbstractControl) => 
        {
            return control
                  .valueChanges
                  .pipe(debounceTime(300))
                  .pipe(switchMap(cnpj => this.companyService.cnpjIsTaken(cnpj) ))
                  .pipe(map(taken => taken ? { cnpjTaken: true } : null ))
                  .pipe(first());
        }
    }
}