<section fxLayout="column">
  <div class="container">

    <div class="card__title">
      <div fxLayout="row" fxFill fxLayoutAlign="end center">
        <div fxFlex="90">
          <h3>
            <i class="material-icons">supervisor_account</i>
            <span>Colaboradores</span>
          </h3>
        </div>
        <div fxFlex="10" fxLayoutAlign="end center">
          <a [routerLink]="['','empresa', companyId]">
            Voltar
          </a>
        </div>
      </div>
    </div>

    <div class="card__menu">
      <mat-card>
        <span class="company__id">
          <span>ID</span>
          <span [innerHtml]="companyId"></span>
        </span>
        <span class="company__name">
          <i class="material-icons">business</i>
          <span [innerHtml]="company?.name"></span>
        </span>
        <mat-divider></mat-divider>
        <select (change)="onChangeFilters($event.target.value)">
          <option *ngFor="let f of filters" [value]="f.value">
            {{f.viewValue}}
          </option>
        </select>
        <div class="search__area">
          <mat-form-field floatLabel="always">
            <mat-label>Buscar</mat-label>
            <input matInput [(ngModel)]="query" #ctrl="ngModel"
              placeholder="Pesquise por nome, id, status, perfil ou data." autocomplete="off">
          </mat-form-field>
        </div>
        <div>
          <span *ngIf="checkedItems.length > 0">
            {{checkedItems.length}}
            <span *ngIf="checkedItems.length == 1; else moreThanOne">selecionado</span>
            <ng-template #moreThanOne>
              <span>selecionados</span>
            </ng-template>

            <button (click)="onRemoveClick()" mat-button mat-raised-button class="is-button__outline" color="danger">
              <i class="material-icons">delete_forever</i>
              Excluir
            </button>
          </span>
        </div>
      </mat-card>
    </div>

    <!-- início da tabela -->
    <div class="card__table">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (change)="onCheckChange($event)" [checked]="isChecked(element.id)" [value]="element.id">
              <span>{{element.id}}</span>
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="selfie">
          <th mat-header-cell *matHeaderCellDef> Selfie </th>
          <td mat-cell *matCellDef="let element">
            <img class="share__selfie" [src]="element.selfie" (error)="onSelfieError($event)" />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nome </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.statusLabel}} </td>
        </ng-container>

        <ng-container matColumnDef="profile">
          <th mat-header-cell *matHeaderCellDef> Perfil </th>
          <td mat-cell *matCellDef="let element"> {{element.profile}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> Criado em </th>
          <td mat-cell *matCellDef="let element"> {{element.createdOn | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [length]="total" [pageSize]="size" [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
    </div>
    <!-- fim da tabela -->

  </div>
</section>