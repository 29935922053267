<section class="content__holder" fxLayout="column wrap">
  <div class="card form__content" fxLayout="row wrap" fxLayoutAlign="center center">
      <div class="card__title" fxFlex="100">
          Layout de Exportação
      </div>
      <div *ngIf="!viewExportLayout" class="button_load" fxFlex="100" fxLayout="column wrap" fxLayoutAlign="center center">
          <button mat-raised-button class="is-button__outline" color="accent" (click)="openExportLayout()">
            <span>Visualizar exportações</span>
          </button>
      </div>
      <div *ngIf="viewExportLayout && isLoading" fxFlex="100" fxLayout="column wrap" fxLayoutAlign="center center">
          <app-loading></app-loading>
      </div>
      <div *ngIf="viewExportLayout && !isLoading" fxFlex="100" fxLayout="column wrap" fxLayoutAlign="center center">
          <mat-form-field fxFlexFill [floatLabel]="false">
            <mat-label>Selecione o layout</mat-label>
            <mat-select [(value)]="currentExportLayout">
              <mat-option *ngFor="let layout of allLayouts" [value]="layout.id">
                {{layout.description}}
              </mat-option>
            </mat-select>
            <mat-hint>Id: {{currentExportLayout}}</mat-hint>
          </mat-form-field>
          <button mat-raised-button class="is-button__outline" color="accent" (click)="saveCompanyLayout()">
            <span>Salvar</span>
          </button>
      </div>
  </div>
</section>
