<p-toast position="top-right"></p-toast>
<p-confirmDialog header="Confirmação" acceptLabel="Sim" rejectLabel="Não" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<!-- * Page Header: default -->
<section class="page__header is-primary">
  <!-- page__heading -->
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="page__heading">
    <h3 fxFlex="100" class="page__title">Gerenciar Dados de Contratação</h3>
    <h6 fxFlex="100" class="page__subtitle">Altere, edite, crie ou exclua campos</h6>
  </div>
</section>

<!-- * Page Content -->
<section class="page__content">
  <div class="card">
    <div class="header">
      <h5>Informações Adicionais - Dados de Contratação</h5>
      <div class="actions">
        <button *ngIf="isCompanyWithoutSchema"
          pButton
          class="p-button-info mx-1"
          (click)="createDefaultSchema()"
          [label]="createDefaultSchemaLabel"></button>
        <button
          pButton
          class="p-button-info mx-1"
          (click)="exportFullSchemaJson()"
          [disabled]="!_schemaFieldService.schemaFullConfig"
          [label]="exportLabel"></button>
        <button
          pButton
          class="p-button-info mx-1"
          (click)="openModalSelectWorkspace()"
          [disabled]="!_schemaFieldService.schemaFullConfig"
          [label]="viewFormLabel"></button>
        <button 
          pButton 
          class="ml-1" 
          (click)="openModalAddSchemaField()" 
          [label]="addLabel" 
          [disabled]="loading || !_schemaFieldService.schemaFullConfig"></button>
      </div>
    </div>
    <p-table
      dataKey="name"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      sortMode="single"
      groupRowsBy="name"
      rowGroupMode="subheader"
      responsiveLayout="scroll"
      [rows]="10"
      [value]="arrSchemaFields"
      [loading]="loading"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} entradas"
      (onRowReorder)="onRowReorder($event)">
      <ng-template pTemplate="header">
        <tr>
          <th>Nome do Campo</th>
          <th>Título</th>
          <th>Obrigatório</th>
          <th>Opçoes</th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-schema let-rowIndex="rowIndex" let-expanded="expanded">
        <tr [pReorderableRow]="rowIndex">
          <td colspan="5">
            <div class="td-content">
              <span class="pi pi-bars" pReorderableRowHandle></span>

              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="schema"
                class="p-button-text p-button-rounded p-button-plain mr-2"
                pTooltip="Expandir"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              <span class="font-bold ml-2">{{ schema.name }}</span>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-schema>
        <tr>
          <td>
            {{ schema.name }}
          </td>
          <td>
            <span>{{ schema.title ? schema.title : '--' }}</span>
          </td>
          <td>
            <span>{{ !!schema.required }}</span>
          </td>
          <td>
            <span>{{ schema.options ? schema.options : '--' }} </span>
          </td>
          <td>
            <span
              ><button
                (click)="openModalEditSchemaField(schema.name)"
                pButton
                pRipple
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary"></button
            ></span>
            <span
              ><button
                (click)="deleteField($event, schema.name)"
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"></button
            ></span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div fxLayoutAlign="end" class="my-2">
      <button (click)="saveSchema()" pButton pRipple class="p-button-warning" [label]="saveLabel"></button>
    </div>
  </div>
</section>
