import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContractConfigResumedView } from '../model/contract-config-view.model';
import { CreateContractConfigCmd } from '../model/create-contract-cmd.model';
import { ContractConfigFullView } from '../model/contract-config-full-view.model';
import { MappingField } from '../model/mapping-field.model';
import { UpdateContractConfigCmd } from '../model/update-contract-config-cmd.model';

@Injectable({
    providedIn: 'root'
})
export class ContractConfigService {

    constructor(private httpClient: HttpClient) { }

    getAll(): Promise<Array<ContractConfigResumedView>> {
        let url = `${environment.apiEndpoint}/ContractConfig`;
        return this.httpClient.get<Array<ContractConfigResumedView>>(url).toPromise();
    }

    getOne(id: string): Promise<ContractConfigFullView> {
        let url = `${environment.apiEndpoint}/ContractConfig/${id}`;
        return this.httpClient.get<ContractConfigFullView>(url).toPromise();
    }

    getAvailableFields(): Promise<Array<MappingField>> {
        let url = `${environment.apiEndpoint}/ContractConfig/MappingFields`;
        return this.httpClient.get<Array<MappingField>>(url).toPromise();
    }

    createContractConfig(file: File, cmd: CreateContractConfigCmd): Promise<void> {
        let url = `${environment.apiEndpoint}/ContractConfig`;
        let formData = new FormData();
        formData.append('formFiles', file);
        formData.append('companyId', cmd.companyId);
        formData.append('workspaceList', JSON.stringify(cmd.workspaceList));
        formData.append('name', cmd.name);
        formData.append('description', cmd.description);
        formData.append('fields', JSON.stringify(cmd.fields));
        return this.httpClient.post<void>(url, formData).toPromise();
    }

    updateContractConfig(cmd: UpdateContractConfigCmd): Promise<void> {
        let url = `${environment.apiEndpoint}/ContractConfig`;
        return this.httpClient.patch<void>(url, cmd).toPromise();
    }

    disableContractConfig(id: string): Promise<void> {
        let url = `${environment.apiEndpoint}/ContractConfig/${id}`;
        return this.httpClient.delete<void>(url).toPromise();
    }
}
