import { Component, Input, OnInit } from "@angular/core";
import { AttachExportConfigCmd } from "src/app/shared/model/attach-export-config-cmd.model";
import { Company } from "src/app/shared/model/company.model";
import { ShareExportLayoutView } from "src/app/shared/model/share-export-layout-view.model";
import { CompanyService, ToastService } from "src/app/shared/services";
import { ShareExportService } from "src/app/shared/services/share-export.service";


@Component({
    selector: 'app-export-layout',
    templateUrl: './export-layout.component.html',
    styleUrls: ['./export-layout.component.scss']
  })


export class ExportLayoutComponent implements OnInit {
    @Input('company')
    public company: Company;
    public currentExportLayout: string;
    public allLayouts: ShareExportLayoutView[];
    public isLoading: boolean = true;
    public viewExportLayout: boolean = false;
    constructor(private companyService: CompanyService,
        private shareExportService: ShareExportService,
        private readonly toastService: ToastService) {


    }


    ngOnInit(): void {}
   
    openExportLayout(): void {
        this.viewExportLayout = true;
        this.isLoading = true;
        this.companyService.getExportLayout(this.company.id)
        .then((result) => this.currentExportLayout = result)
        .then(() => {
            this.shareExportService.GetAllLayouts()
            .then((layouts) => {
                this.allLayouts = layouts;
                this.isLoading = false;
            })
        })
        .catch((err) => {
            console.log(err);
            this.isLoading = false;
        });
    }


    saveCompanyLayout(): void {
        this.isLoading = true;
        let cmd = new AttachExportConfigCmd();
        cmd.exportMappingConfigId = this.currentExportLayout;


        this.shareExportService.AttachExportConfig(cmd, this.company.id)
        .then(() => this.toastService.success("Layout alterado com sucesso"))
        .catch((err) => {
            this.toastService.error("Erro ao alterar layout");
            console.log(err);
        })
        .finally(() => this.isLoading = false);
    }
}
